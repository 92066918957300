import * as React from 'react';
import {
  HeaderSection,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  LeftSection,
  CenterSection,
  RightSection,
  VerticalDivider,
  ColorIcon,
} from '../style/emotionStyle';
import { useOktaAuth } from '@okta/okta-react';
import NavigationTabs from './NavigationTabs';
import { TabItem } from 'src/model/BackupJobModel';
import DashboardPage from './dashboard';
import DataSource from './BackupJob/CreateDataSource';
import DataDestination from './BackupJob/CreateDataDestination';
import SlaPage from './BackupJob/CreateSla';
import CreateBackupJob from './BackupJob/createbackupJob';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { BackupJobTable } from './BackupJob/BackupJobTable';
import { useFetchTenant } from '../components/Mutation/tenantMutation';
import CreateBackupLanding from './BackupJob/CreateBackupLanding';
import { BellOutlineIcon, MissingOutlineIcon, RoomMenuIcon, SearchIcon, UserIcon } from '@rubrik/aura-ui/icons';

function Header(props: { headerData: string }) {
  const { tab = 'datasource' } = useParams();
  const queryClient = useQueryClient();
  const [selectedStep, setSelectedStep] = React.useState(0);
  const { data: tenantData } = useFetchTenant();
  const nextStep = () => {
    const nextStepIndex = selectedStep + 1;
    setSelectedStep(nextStepIndex);
  };
  const prefixedUrl: string = `/finguard`;
  const handleSuccess = () => {
    console.log('Success callback triggered.');
  };

  const handleError = (error: any) => {
    console.error('Error callback triggered:', error);
  };

  const getBackupJobRoute = () => {
    const onboardingStatus = queryClient.getQueryData<string>(['tenantOnboardingStatus']) || tenantData?.onboardingStatus;
    if (onboardingStatus === 'ADDED_SLA') {
      return `${prefixedUrl}/backupjob/create`;
    } else if (onboardingStatus === 'ADDED_BK_JOB') {
      return `${prefixedUrl}/backupjobmonitoring/create`;
    } else {
      return `${prefixedUrl}/backupjob`;
    }
  };

  const isMonitoringAdded = 
    tenantData?.onboardingStatus === 'ADDED_MONITORING' ||
    queryClient.getQueryData<string>(['tenantOnboardingStatus']) === 'ADDED_MONITORING';

  const tabItems: Array<TabItem> = [
    {
      text: 'DASHBOARD',
      to: `${prefixedUrl}/dashboard`,
      content: (): React.ReactElement => {
        return <DashboardPage />;
      },
      disabled: tenantData?.onboardingStatus !== null ? false : true,
    },
    {
      text: 'DATA SOURCES',
      to: `${prefixedUrl}/datasource/create`,
      content: (): React.ReactElement => {
        return (
          <DataSource
            nextStep={nextStep}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        );
      },
      disabled: isMonitoringAdded,
    },
    {
      text: 'DATA DESTINATION',
      to: `${prefixedUrl}/datadestination/create`,
      content: (): React.ReactElement => {
        return (
          <DataDestination
            nextStep={nextStep}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        );
      },
      disabled: isMonitoringAdded,
    },
    {
      text: 'SLA',
      to: `${prefixedUrl}/datasla/create`,
      content: (): React.ReactElement => {
        return (
          <SlaPage
            nextStep={nextStep}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        );
      },
      disabled: isMonitoringAdded,
    },
    {
      text: 'BACKUP JOBS',
      to: getBackupJobRoute(), 
      content: (): React.ReactElement => {
        return tenantData?.onboardingStatus === 'ADDED_SLA' ? (
          <CreateBackupLanding currentTab={3} />
        ) : tenantData?.onboardingStatus === 'ADDED_BK_JOB' ? (
          <CreateBackupLanding currentTab={4} />
        ) : (
          <BackupJobTable />
        );
      },
    },
    // {
    //   text: 'RESTORE JOBS',
    //   to: `${prefixedUrl}/restorejob`,
    //   content: (): React.ReactElement => {
    //     return <RestoreJobTable/>;
    //   },
    // },
  ];

  const { authState } = useOktaAuth();
  const { data: currentTabIndex = 0 } = useQuery(['currentTab'], {
    initialData: 0,
  });

  const [currentTab, setCurrentTab] = React.useState(tabItems[currentTabIndex]);

  React.useEffect(() => {
    setCurrentTab(tabItems[currentTabIndex]);
  }, [currentTabIndex]);

  React.useEffect(() => {
    if (
      queryClient.getQueryData<string>(['tenantOnboardingStatus']) === 'ADDED_BK_JOB'
    ) {
      queryClient.setQueryData(['currentTab'], tabItems.indexOf(tabItems[4]));
    } else {
      queryClient.setQueryData(['currentTab'], tabItems.indexOf(currentTab));
    }
  }, [currentTab, queryClient, tabItems]);

  const onboardingStatus = queryClient.getQueryData<string>(['tenantOnboardingStatus']) ?? tenantData?.onboardingStatus;

  return (
    <header>
      <HeaderSection>
        {authState?.isAuthenticated ? (
          <>
            <LeftSection>
              <img alt="" src="/rubrik-logo.svg" />
              <VerticalDivider />
              <HeaderText>FinGuard</HeaderText>
            </LeftSection>
            {onboardingStatus || queryClient.getQueryData<boolean>(['newUserFlow']) === true ? (
              <CenterSection>
                <NavigationTabs
                  items={tabItems}
                  value={currentTab}
                  onChange={setCurrentTab}
                />
              </CenterSection>
            ) : <></>}
            {/* <RightSection>
              <ColorIcon><SearchIcon size={32} /></ColorIcon>
              <VerticalDivider />
              <ColorIcon><BellOutlineIcon size={32} /></ColorIcon>
              <ColorIcon><MissingOutlineIcon size={32} /></ColorIcon>
              <ColorIcon><RoomMenuIcon size={32} /></ColorIcon>
              <VerticalDivider />
              <ColorIcon><UserIcon size={32} /></ColorIcon>
            </RightSection> */}
          </>
        ) : (
          <></>
        )}
      </HeaderSection>
    </header>
  );
}

export default Header;