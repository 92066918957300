import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import {DataSourcePayload, UserModel} from '../../model/BackupJobModel';

export const useSaveFormDataSource = () => {
  const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;
  return useMutation(
    (formData: DataSourcePayload) => ApiServiceInstance.createTenantBackupSource({ tenantId: tenantId!, data: formData }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['formData']);
        queryClient.setQueryData(['tenantBackupSourceId'], data);
        queryClient.setQueryData(['tenantBackupSourceIdData'], data.id);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );};

  export const useUpdateDataSource = () => { // Renamed to useUpdateTenantData
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;

    return useMutation(
      ({ tenantBackupSourceId, formData }: { tenantBackupSourceId: string, formData: DataSourcePayload }) =>
        ApiServiceInstance.updateTenantBackupSource({
          tenantId: tenantId!,
          tenantBackupSourceId: tenantBackupSourceId,
          data: formData,
        }),
      {
        onSuccess: (data) => {
          queryClient.setQueryData(['tenantBackupSourceId'], data);
        },
        onError: (error) => {
          console.error('Error saving form data tenant', error);
        },
      }
    );
  };

export const useFetchFormDataSource = (tenantBackupSourceId: string) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['formData', tenantBackupSourceId],
    () => ApiServiceInstance.getTenantBackupSource({ tenantId: tenantId!, tenantBackupSourceId }),
    {
      enabled: !!tenantId && !!tenantBackupSourceId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};

export const useFetchAllBackupSource = (page?: number, limit?: number) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['BackupSourceAll', {tenantId}],
    () => ApiServiceInstance.getAllTenantBackupSource({ tenantId: tenantId!, params: { page, limit }  }),
    {
      enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 1500, // 30 sec
      cacheTime: 1500, // 30 sec
    }
  );
};

export const useClearFormDataSource = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      // Clear operation (if needed on the server-side)
      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(['formData'], null);
      },
      onError: (error) => {
        console.error('Error clearing form data source', error);
      },
    }
  );
};