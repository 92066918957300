import {
  ModalContextProvider,
  ResizeObserverProvider,
  ThemesEnum,
  AuraUIProvider as AuraUIProviderModule,
} from '@rubrik/aura-ui';
import * as React from 'react';

/**
 * Support for usage of Aura UI.
 * @param children React child nodes.
 */
export const AuraUIProvider: React.FC<{
  readonly children?: React.ReactNode;
}> = ({children}) => {
  return (
    <AuraUIProviderModule appTheme={ThemesEnum.DARK} isWipEnabled={false}>
      <ResizeObserverProvider>
        <ModalContextProvider>{children}</ModalContextProvider>
      </ResizeObserverProvider>
    </AuraUIProviderModule>
  );
};
