import styled from '@emotion/styled';
import { MenuItem, Select, Tab, Tabs } from '@mui/material';

export const ItemsData = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 0px;
  gap: 107px;
`;

export const EmailItemsData = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 0px;
  gap: 70px;
`;

export const EmailStatus = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  padding: 25px 0px 0px 0px;
  margin-left: -60px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ItemKey = styled.div`
  font-size: 20px;
  color: white;
  margin-right: 70px;
  display: inline-flex;
  display: flex;
  gap:24px;
`;


export const ItemKeyDatePicker = styled.div`
  font-size: 20px;
  color: white;
  margin-right: 70px;
  // opacity: 0.7;
  display: inline-flex;
  display: flex;
  gap:12px;
`;

export const InputBoxWidth = styled.div`
  width: 40px;
  min-height: 55px;
  align-items: center;
  // margin-right: 30px;
  // padding: 9px 8px 11px 16px;
  align-items: center;
  flex-shrink: 0;
`;

export const EmailInputBoxWidth = styled.div`
  width: 436px;
`;

export const DropDownWidth = styled.div`
  width: 120px;
  align-items: center;
  margin-right: 30px;
  height: 10px;
`;

export const DropDownWidthSnapshot = styled.div`
  width: 100px;
  align-items: center;
  margin: 0px 10px 0px 10px;
  height: 10px;
`;

// export const TitleStyle = styled.div`
//   display: inline-flex;
//   align-items: center;
//   margin: -7px 18px 0px 10px;
//   min-width: 80px;
// `;

export const TitleStyle = styled.div`
  display: flex;
  width: 42px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const TitleStyleSnapshot = styled.div`
  display: flex;
  width: 72px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const TitleStyleSnapshotTimeZone = styled.div`
  display: flex;
  width: 60px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const TitleStyleRetain = styled.div`
  display: flex;
  width: 62px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const TitleStyleIf = styled.div`
  display: flex;
  width: 52px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% *
`;

export const TitleStyleIfWeeks = styled.div`
  display: inline-flex;
  align-items: center;
  margin: -8px -10px 0px 50px;
  min-width: 80px;
  font-weight: bold;
`;

export const IncrementalTitleStyle = styled.div`
  display: inline-flex;
  justify-content: center;
  min-width: 200px;
  display: flex;
  width: 42px;
  height: 40px;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ItemDataStyle = styled.div`
  margin-top: 7px;
  align-items: center;
  width: 100%;
`;

export const DivTitleWidth = styled.div`
  min-width: 300px;
  width: 100%;
`;

export const DivLeft = styled.div`
  flex: 1;
`;

export const DivLeftFirst = styled.div`
  width: 224px;
`;

export const DivLeftMiddle = styled.div`
  width: 272px;
  margin-left: -75px;
`;

export const DivLeftLast = styled.div`
  width: 224px;
  padding-left: 100px;
`;

export const DivLeftFirstIf = styled.div`
  width: 402px;
`;

export const DivRight = styled.div`
  flex: 1;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.1;
`;

export const SectionSla = styled.div`
// padding: 10px 0px 10px 0px;
`;

export const minHeight = styled.div`
  min-height: 180px;
  width: 100%;
`;

export const SnapshotTimeline = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
`;

export const TitleSnapshot = styled.div`
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding-top: 20px;
  margin: 10px 40px 0px 25px;
  opacity: 0.9;
`;

export const ObjectSelectionTitle = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 8px;
`;

export const ObjectSelectionFilter = styled.div`
  color: white;
  font-size: 16px;
  margin: 8px;
`;

export const TitleData = styled.div`
  color: white;
  font-size: 15px;
  margin: 10px 10px 0px 10px;
  opacity: 0.9;
`;

export const Colon = styled.div`
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin: 10px 10px 10px 10px;
  opacity: 0.9;
`;

export const DateSapcing = styled.div`
  margin-left: 20px;
`;

export const InputBoxSnapshot = styled.div`
  width: 50px;
  min-height: 55px;
  align-items: center;
  margin-right: 0px 5px 0px 5px;
`;

export const Heading = styled.div`
  color: white;
  font-size: 28px;
  font-weight: 700;
  line-height: 44px;
`;

export const HeadingTable = styled.div`
  color: white;
  padding: 10px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;  
  padding: 22px 0px 24px 0px;
  // margin-bottom: 20px;
  // opacity: 0.9;
`;

export const TitleCreateJob = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;  
  padding: 0px 0px 10px 0px;
  // margin-bottom: 20px;
  // opacity: 0.9;
`;

export const TitleStep = styled.div`
  color: white;
  // font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  width: 216px;
  padding: 7px 0px 24px 45px;
  align-items: center;
  gap: 16px;
`;

export const ButtonStyle = styled.div`
  padding: 5px;
  margin-left: 10px;
  margin-top: 50px;
`;

export const DescriptiveData = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  opacity: 0.6;
  color: #ffffff;
`;

export const ButtonSource = styled.div`
  padding: 5px;
  margin-left: 300px;
`;

export const ButtonDestination = styled.div`
  padding: 5px;
  margin-left: 264px;
`;

export const MarginTop = styled.div`
  margin-top: 8px;
`;

export const WidthSection = styled.div`
  width: 30%;
`;

export const Timeline = styled.div`
  color: white;
  // display: flex;
  // flex-direction: row;
  // // padding: 20px;
  // margin-left: 15px;
  // text-align: center;
  display: flex;
  width: 272px;
  align-items: flex-start;
  gap: 24px;
`;

export const BoldItem = styled.div`
  margin-top: 7px;
  align-items: center;
  font-weight: bold;
`;

export const HeaderSection = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  margin: 0px 72px 0px 72px;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 10%; // Force the width to be 20%
`;

export const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 60%; // Force the width to be 60%
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; // Align content to the extreme right
  flex: 0 0 10%; // Force the width to be 20%
  margin-right: 120px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 26px; // Adjust height as needed
  background-color: #A3D2FF; // Adjust color as needed
  margin: 0 14px; // Adjust spacing as needed
  opacity: 0.16;
`;

export const MarginHeader = styled.header`
  margin-bottom: 20px;
`;

export const HeaderGridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
`;

export const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const GridContainerRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-right: 40px;
`;

export const GridContainer = styled.div`
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ComponentPage = styled.div`
  margin-bottom: 25px;
`;

export const LoginPageImage = styled.div`
  min-width: 30px;
  min-height: 30px;
  margin-bottom: 20px;
`;

export const HeaderText = styled.div`
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 22px;
  left: 120px;
  transform: translateX(-50%);
`;

export const IconLeftRubrik = styled.div`
  margin: 15px 0px 0px 15px;
`;

export const IconLeft = styled.div`
  margin-right: 15px;
`;

export const IconRight = styled.div`
  margin-left: 15px;
`;

export const CreateBackupJobRows = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
`;

export const ChartDivision = styled.div`
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`;

export const CreationText = styled.div`
  overflow-x: auto;
  max-width: 100%;
  // overflow: visible; /* Ensure text can overflow */
  white-space: nowrap; /* Prevent text wrapping */
  overflow-wrap: normal;  max-width: 1162px;
  font-size: 18px;
  color: white;
  margin: 10px 2px 40px 2px;
  opacity: 0.9;
  display: inline-flex;
  // display: flex;
`;

export const BackupJobText = styled.div`
  color: white;
  margin-right: 70px;
  opacity: 0.7;
  display: inline-flex;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const BackupJobTextValue = styled.div`
  flex-wrap: nowrap;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const BackupDataItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackupJobTextValueDataSource = styled.div`
  // flex-wrap: nowrap;
  color: white;
  word-break: break-word; /* Allow text to move to the next line */
  overflow: hidden; /* Prevent text overflow */
  text-overflow: ellipsis; /* Show ellipsis if text overflows */
  padding-right: 7px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const BackupDataItemsDataSource = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
`;

export const ChartNoData = styled.div`
  align-items: center;
  justify-content: center;
  padding: 190px 350px 150px 480px;
  color: white;
  font-size: 22px;
  font-weight: bold;
`;

export const DonutChartNoData = styled.div`
  align-items: center;
  justify-content: center;
  padding: 130px 0px 50px 140px;
  color: white;
  font-size: 22px;
  font-weight: bold;
`;

export const BackupDataMarginRow = styled.div`
  margin-bottom: 15px;
`;

export const ParentContainer = styled.div`
  position: relative;
`;

export const DropdownContainer = styled.div<{ width?: string; height?: string }>`
  position: relative;
  width: ${({ width }) => width || '200px'};
  user-select: none;
  overflow: visible;
`;

export const SelectedOption = styled.div<{ width?: string; height?: string; border?: string; color?: string; shadow?: string; fontSize?: string; disabled?: boolean }>`
  width: ${({ width }) => width || '435px'};
  height: ${({ height }) => height || '40px'};
  padding: 10px;
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  border-bottom: ${({ border }) => border || '1px solid #4dd2d2'};
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ color, disabled }) => (disabled ? '#dcf2ff4f' : color || '#307eac3d')};
  box-shadow: ${({ shadow }) => shadow || '0 4px 8px rgba(0, 0, 0, 0.1)'};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Dropdown = styled.div`
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Placeholder = styled.div<{ fontSize?: string; fontOpacity?: number }>`
  margin-left: 7px;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  opacity: ${({ fontOpacity }) => fontOpacity !== undefined ? fontOpacity : 0.7};
`;

export const Arrow = styled.div`
  margin-left: auto;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  color: white;
`;

export const ClearIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
  color: #ffffff;
  font-size: 1.2rem;
`;

export const OptionsList = styled.div<{ width?: string }>`
  z-index: 9999;
  position: absolute;
  width: ${({ width }) => width || '435px'};
  border: 0.5px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  background-color: rgba(0, 63, 100, 0.98);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

export const StyledOption = styled.div<{ width?: string }>`
  padding: 10px;
  cursor: pointer;
  background-color: rgba(0, 63, 100, 0.98);
  width: ${({ width }) => width || '435px'};
  &:hover {
    background-color: #055686b8;
  }
`;

export const DropdownLabel = styled.div`
  width: 188px;
  margin-top: 10px;
  color: white;
`;

export const FixedHeader = styled.div`
  position: fixed;
  z-index: 10;
  padding: 10px;
`;

// Create a scrollable container for the rest of the content
export const ScrollableContent = styled.div<{width?: string}>`
  max-height: calc(100vh - 60px); /* Adjust this according to the height of the FixedHeader */
  // width: 706px;
  width: ${({width}) => width || '706px'};
  overflow-y: auto;
  padding: 15px 45px 0px 8px; /* Space for scrollbar to avoid overlap with content */
  flex: 1; /* Ensure ScrollableContent stretches to available space */
  /* Customize the scrollbar width and appearance */
  &::-webkit-scrollbar {
    width: 2px;
  }
`;

export const BodyContentTemplate = styled.div`
  margin-top: 72px;
  padding: 20px;
  height: calc(100vh - 72px);
  overflow-y: auto;
  position: fixed;
  width: 100%;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

export const EmailMonitoringContainer = styled.div`
  margin: 20px -730px 0px 0px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const TabHeaders = styled.div`
  display: flex;
`;

export const TabHeader = styled.div<{ isActive: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? 'blue' : 'red')};
  color: ${(props) => (props.isActive ? 'white' : 'black')};
  border: ${(props) => (props.isActive ? 'none' : '1px solid gray')};
  border-bottom: ${(props) => (props.isActive ? 'none' : '1px solid black')};
  border-radius: ${(props) => (props.isActive ? '5px 5px 0 0' : '5px')};
  &:hover {
    background-color: white;
  }
`;

export const TabContent = styled.div`
  padding: 20px;
  border: 1px solid gray;
  border-radius: 0 5px 5px 5px;
`;

export const MarginTopOkta = styled.div`
  margin-top: 38px;
`;

export const ChartData = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  min-height: 250px;
`;

export const ChartDataText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const ChartDataSubText = styled.div`
  font-size: 12px;
`;

export const ChartDataTextContainer = styled.div`
  position: relative;
  top: -14%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
`;

export const LegendBox = styled.div`
  width: 225px;
  height: 35px;
  display: flex;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #055686b8;
    border-radius: 10px;
  }
  &:hover .icon {
    visibility: visible;
    opacity: 0.8;
  }
`;

export const Label = styled.div`
  margin: 5px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -15px;
  height:absolute;
  weight:200px;
  // margin-top: 5px;
`;

export const NoteContainer = styled.div`
  margin: 5px 0px 0px 70px;
  color: #FFFFFF;
  // opacity: 0.9;
  font-size: 14px;
  display: inline-block;
`;

export const LabelTextContainer = styled.div`
  margin: 5px 5px 5px 10px;
  display: flex;
  flex-direction: row;
  padding: 2px;
`;

export const IconContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  display: flex;
`;

export const LabelTextNumber = styled.div`
  font-size: 18px;
`;

export const LabelText = styled.div`
  margin: 4px 0px 0px 6px;
  font-size: 14px;
  opacity: 0.8;
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 8px;
`;

export const ColorIcon = styled.div`
  color: white;
  margin: 10px 5px 0px 5px;
`;

export const ToggleButton = styled.div`
  margin-top: 20px;
  // margin-left: -185px;
`;

export const TextEmail = styled.div`
  padding:25px 0px 0px 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const EmailTitle = styled.div`
  padding:25px 75px 0px 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const DashboardPageLayout = styled.div`
  margin-left: 55px;
  display: flex;
  flex-direction: row;
`;

export const LeftChartSection = styled.div`
  width: 30%;
`;

export const RightChartSection = styled.div`
  margin-left: 3%;
  // width: 75%;
`;
export const DashboardDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it spans the full height of the card */
`;

export const DashboardDetailSectionTop = styled.div`
  width:426px;
  height:96px;
  display: flex;
  padding: 14px 0px 24px 24px;
  align-items: flex-start;
  gap: 14px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid themes.tokens.colors.strokeStrong;
  background: var(--card-Header, linear-gradient(135deg, rgba(29, 161, 220, 0.08) 0%, rgba(47, 202, 255, 0.16) 100%));
`;

export const DashboardDetailSectionBottom = styled.div`
  display: flex;
  padding: 20px 10px 20px 20px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const DashboardDetailSectionBottomLeft = styled.div`
  flex: 1; /* Grow to fit the available space */
  display: flex;
  // align-items: center;
  // justify-content: center;
  flex-direction: row;
  // border-right: 0.01px solid #4dd2d2; /* Example border */
`;

export const DashboardDetailSectionBottomRight = styled.div`
  flex: 1; /* Grow to fit the available space */
  display: flex;
  // align-items: center;
  justify-content: center;
`;

export const DashboardDetailSectionTopRight = styled.div`
  padding: 17px;
`;

export const DashboardDetailSectionTopSubHeading = styled.div`
  align-self: stretch;
  color: white;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const DashboardDetailSectionTopHeading = styled.div`
  color: white;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

export const DashboardDetailSectionButtomRightText = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  padding: 15px;
`;

export const DashboardDetailSectionButtomRightTextRow = styled.div`
  color: var(--Text-textStrong, #FFF);
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
`;

export const DashboardDetailSectionButtomRightTextFirst = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DashboardDetailSectionButtomRightTextTitle = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bolder;
  padding-top: 10px;
  // margin-bottom: 20px;
  opacity: 0.9;
  margin: 0px 5px 20px 11px;
`;


export const DashboardDetailSectionButtomleftTextTitle = styled.div`
  color: white;
  font-size: 22px;
  // font-weight: bold;
  padding-top: 6px;
  // margin-bottom: 20px;
  opacity: 0.8;
  margin: 0px 5px 20px 11px;
`;
export const DashboardDetailSectionButtomRightTextSubTitle = styled.div`
  color: white;
  font-size: 17px;
  padding-top: 10px;
  margin-bottom: 20px;
  opacity: 0.7;
  align-items: center;
`;


export const DashboardDetailSectionButtomRightTextSubTitleComingSoon = styled.div`
  color: white;
  font-size: 17px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  // margin: 0px 0px 20px 40px;
  opacity: 0.7;
`;

export const VerticalDividerDashboard = styled.div`
  width: 1px;
  height: 46px; // Adjust height as needed
  background-color: #A3D2FF; // Adjust color as needed
  opacity: 0.24;
  display: flex;
  // padding: 4px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
 `;


export const ImageMargin= styled.div`
  margin: 10px 0px 0px 10px;
`;

export const Circle = styled.div`
  width: 10px; /* Adjust the size as needed */
  height: 10px;  /* Adjust the size as needed */
  background-color: green;
  border-radius: 50%;
  // display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 16px 0px 0px 10px;
`;

export const ButtonChart = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent,
  z-index: 1000;
  // color: #FFFFFF;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
`;

export const OverlayButton = styled.button`
  position: absolute;
  top: 15%;
  right: 5px;
  transform: translate(-50%, -50%);
  background: transparent;
  color: white;
  border: 4px solid rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000; // Ensure the button is on top
  // &:hover {
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
`;

export const OverlayButtonTable = styled.button`
  position: absolute;
  top: 5%;
  right: 1px;
  transform: translate(-50%, -50%);
  background: transparent;
  color: white;
  border: 3px solid white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000; // Ensure the button is on top
  // &:hover {
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
`;

export const TableHeading = styled.div`
  color: white;
  font-size: 30px;
`;

export const ContainerBackup = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 4px 3fr; /* Adjust as needed */
  gap: 0;
  height: 100vh;
  margin: 80px 0px 0px 94px;
`;

export const ContainerBackupTable = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 4px 3.7fr; /* Adjust as needed */
  gap: 0;
  height: 100vh;
`;

export const SidebarTable = styled.div`
    //  padding: 20px;
  margin: 40px 40px 40px 40px;
  // margin-top: 40px;
  grid-column: 1;
  align-items: center;
  color: white;
  width: 340px;
  height: 516px;
`;



export const TitleBackup = styled.div`
  color: white;
  font-size: 19px;
  font-weight: bold;
  padding-top: 20px;
  margin-bottom: 20px;
  opacity: 0.9;
  margin-left: 40px;
`;

export const VerticalLine = styled.div`
  // margin-top: 30px;
  width: 1px;
  height: 100%;
  background-color: white;
  opacity: 0.1;
  grid-column: 2;
  flex-shrink: 0;
`;

export const Sidebar = styled.div`
width: 335px;
height: 929px;
flex-shrink: 0;
padding-right: 80px;
`;

export const CheckBoxText = styled.div`
  font-size: 17px;
  opacity: 0.8;
  padding: 3px;
`;

export const AccordionText = styled.div`
  font-size: 18px;
  padding: 3px;
  margin-top: 35px;  
  width:85%;
`;

export const Content = styled.div`
  // padding: 20px;
  // grid-column: 3;
  flex: 1; /* Ensure Content stretches to available space */
  display: flex; /* Use flex to align FormBackup and ScrollableContent side by side */
  flex-direction: column;
  margin-left: 30px;
  grid-column: 3;
`;

export const FormBackup = styled.div`
  // padding: 20px;
  display: flex;
  flex-direction: row; /* Align ScrollableContent and form fields side by side */
  width: 100%;
`;

export const TitleBackupJobTable = styled.div`
  color: white;
  font-size: 19px;
  font-weight: bold;
  padding: 0px 0px 10px 20px;
  margin-bottom: 20px;
  opacity: 0.9;
  align-items: center;
  justify-content: center;
`;

export const ContainerStyle = styled.div`
  position: relative;
  width: 300px; /* Adjust as needed */
  height: 50px; /* Adjust as needed if necessary */
`;

export const InputStyle = styled.div`
  position: absolute;
  top: 0; /* Adjust to place */
  left: 0; /* Adjust to place */
  width: 100%; /* Adjust width if needed */
  height: 100%; /* Adjust height if needed */
  opacity: 0.5; /* Optional: to differentiate overlapped inputs */
`;

export const StyledTabs = styled(Tabs)`
  // border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: center; 
  .MuiTabs-indicator {
    display: none; // Hide the default blue indicator
  }
`;

export const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 18px;
  color: white;
  min-width: 100px;
  opacity:0.8;
  &.Mui-selected {
    color: white; 
    font-weight: bold;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      height: 4px;
      background-color: #4DD2D2;
      border-radius: 10px 10px 0px 0px;
    }
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center; // Center align the tabs horizontally within the wrapper
  width: 100%;
`;

export const InfoIcon = styled.div`
  padding: 10px 0px 0px 0px;
`;

export const InfoMessageEmail = styled.div`
  margin: 15px 0px 15px -12px;
`;

export const ComponentMoniter = styled.div`
  // padding: 5px;
  margin-left:-5px;
`;

export const TableStatusBox = styled.div<{color: string; width:string, background:string;}>`
  color: ${({color}) => color || 'white'};
  width:${({width}) => width || '110px'};
  height: 25px;
  display: flex;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  position: relative;
  border-radius: 15px;
  border: 1px solid ${({color}) => color || 'white'};
  background-color: ${({background}) => background || 'white'};
  .icon {
      visibility: visible;
      opacity: 0.8;
      background:white;
    }
`;

export const TableStatusBoxMonitoring = styled.div`
  color: #11d29d;
  padding: 2px 5px 0px 0px; 
`;

export const TableStatus= styled.div`
  color:white;
  padding:1px 1px 1px 3px;
`;

export const ErrorCreateJob = styled.div`
margin-left:-10px;
margin-bottom: 15px;
`;
