import {Tab, Tabs} from '@mui/material';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import { StyledTab, StyledTabs, TabsWrapper } from 'src/style/emotionStyle';
import {type TabItem} from '../model/BackupJobModel';

interface NavigationTabProps {
  items: Array<TabItem>;
  value: TabItem;
  onChange?: (item: TabItem) => void;
}

export default function NavigationTabs(
  props: NavigationTabProps,
): React.ReactElement {
  const {items, value, onChange} = props;
  const [currentTab, setCurrentTab] = React.useState(value?.to);
  const navigate = useNavigate();

  const handleTabChange = (item: TabItem): void => {
    setCurrentTab(item.to);
    navigate(item.to);
    if (onChange !== undefined) {
      onChange(item);
    }
  };

  React.useEffect(() => {
    if (value === undefined) return;
    setCurrentTab(value.to);
    navigate(value.to);
  }, [value]);

  return (
    <TabsWrapper>
    <StyledTabs value={currentTab} indicatorColor={undefined}>
      {items.map(
        (item: TabItem): React.ReactElement => (
          <StyledTab
            label={item.text}
            key={item.to}
            value={item.to}
            onClick={() => {
              if (!item.disabled) {
                handleTabChange(item);
              }            
            }}
            style={{ opacity: item.disabled ? 0.5 : 1 }} // Optional: Visual feedback for disabled state
          />
        ),
      )}
    </StyledTabs>
    </TabsWrapper>
  );
}