import {toRelativeUrl} from '@okta/okta-auth-js';
import {useOktaAuth} from '@okta/okta-react';
import {Button} from '@rubrik/aura-ui';
import {Container, GridContainer, GridContainerRow, Heading, MarginTopOkta} from 'src/style/emotionStyle';

function Login() {
  const {oktaAuth} = useOktaAuth();

  const handleLogin = () => {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin,
    );
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  };
  // const handleLogout = () => oktaAuth.signOut()

  return (
    <GridContainerRow>
      <GridContainer />
      <Container>
        <img alt="" height={'50px'} width={'50px'} src="/rubrik-logo.svg" />
        <MarginTopOkta />
        <Heading>Welcome to FinGuard</Heading>
        <MarginTopOkta />
        <Button variant="primary" size="large" onClick={handleLogin}>
          Login with SSO
        </Button>
      </Container>
      <GridContainer />
    </GridContainerRow>
  );
}

export default Login;
