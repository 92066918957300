// DataSource.tsx
import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { FormInputBox, validationUtils, Button, ValidationError } from '@rubrik/aura-ui';
import {
  ButtonSource,
  Title,
  DropdownLabel,
  DescriptiveData
} from '../../style/emotionStyle';
import DataDestination from 'src/components/BackupJob/CreateDataDestination';
import CustomDropdown from 'src/style/DropDown';
import CreateBackupJob from './CreateBackupLanding';
import { BackupSourceType, DataSourceModel, DataSourcePayload, OptionType, SourceAPITypeResponse, SourceTypeApi, TenantData, UserModel } from 'src/model/BackupJobModel';
import {
  useClearFormDataSource,
  useFetchFormDataSource,
  useSaveFormDataSource,
  useUpdateDataSource
} from '../Mutation/DataSourceMutation';
import { regexUrl } from '../../constant/BackupJob';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchAllBackupSourceType } from '../Mutation/BackupSourceTypeMutation';
import { useUpdateTenantData } from '../Mutation/tenantMutation';

const { regexValidatorFactory } = validationUtils;
const regexValidator = regexValidatorFactory(
  regexUrl,
  'Incorrect URL, please enter the correct url',
);

interface DataSourceProps {
  isUpdate?: boolean;
  nextStep: () => void;
  onSuccess: () => void;
  onError: (error: any) => void;
  savedData?: any;
}

const initialValues: DataSourceModel = {
  basicDataSource: '',
  basicName: '',
  basicEnvironment: '',
  ODBCURL: '',
  ODBCRoleId: '',
  ODBCServerDataSource: '',
  ODBCTokenKey: '',
  ODBCTokenSecret: '',
  soapUrl: '',
  soapRoleId: '',
  soapTokenKey: '',
  soapTokenSecret: '',
  accountId: '',
  accountCustomerKey: '',
  accountCustomerSecret: ''
};

const checkUrlValidity = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
};

const DataSource: React.FC<DataSourceProps> = ({ isUpdate, nextStep, onSuccess, onError, savedData }) => {
  const queryClient = useQueryClient();
  const isUpdateData = queryClient.getQueryData(['updateFormSource'])
  const tenantBackupSourceId = queryClient.getQueryData<string>(['tenantBackupSourceIdData']);
  const tenantDataStatus = queryClient.getQueryData<TenantData>(['tenantDataAll']);
  let dataSourceSelect: Array<OptionType>;
  let dataSourceSelectedType: Array<OptionType>;
  dataSourceSelectedType = [{value:'NetSuite2.com', label:'NetSuite2.com'}, {value:'NetSuite.com', label:'NetSuite.com'}]
  const { data: backupSourceType } = useFetchAllBackupSourceType();

  const transformOption = (data: any) => {
    return data.map((source: { id: any; sourceType: any; }) => ({
      value: source.id ? source.id : 'unknown', // Use a fallback value if 'name' is null
      label: source.sourceType ? source.sourceType : 'unknown'  // Similarly handle the 'label'
    }));
  };

  if (backupSourceType) {
    const dropdownData = backupSourceType?.backupSources.filter(x=>x.sourceType==="NETSUITE")
    dataSourceSelect = transformOption(dropdownData);
  } else {
    dataSourceSelect = [{value:'Netsuite', label:'Netsuite'}]
  }
  const [activeComponent, setActiveComponent] = useState<React.ReactNode | null>(null);
  const { mutate: saveFormData, isLoading } = useSaveFormDataSource();
  const { mutate: updateFormData } = useUpdateDataSource();
  const { mutate: updateTenantOnboardingStatus } = useUpdateTenantData();
  // const { mutate: saveFormDataSourceType } = useUpdateBackupSourceType();
  const { mutate: clearFormData } = useClearFormDataSource();
  const [formValues, setFormValues] = useState<DataSourceModel>(initialValues);
  const { data: savedFormData } = useFetchFormDataSource(tenantBackupSourceId || '');
  const [alreadySavedFormData, setAlreadySavedFormData] = React.useState<any>(queryClient.getQueryData(['tenantBackupSourceId']) as unknown as DataSourceModel);
  const [errorForm, setErrorForm] = React.useState(true);
  const [errorFormURL, setErrorFormURL] = React.useState(true);
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const [apiError, setApiError] = React.useState("");

  useEffect(() => {
    // if (savedData && Object.keys(savedData).length) {
    //   const formDataSourceSaved = MapValueForForm(savedData[savedData.length-1]);
    //   queryClient.setQueryData(['tenantBackupSourceId'], savedData[savedData.length-1]);
    //   setFormValues(formDataSourceSaved);
    // } else if (savedFormData && Object.keys(savedFormData).length) {
    //   const formDataSource = MapValueForForm(savedFormData);
    //   setFormValues(formDataSource);
    // } 
    if (alreadySavedFormData && alreadySavedFormData !== undefined) {
      setFormValues(MapValueForForm(alreadySavedFormData as unknown as any))
    } else {
      setFormValues(initialValues);
    }
  }, [savedData, savedFormData, initialValues, setFormValues]);

  const areAllValuesFilled = (formValues: DataSourceModel): boolean => {
    return Object.values(formValues).every(value => value.trim() !== '');
  };

  const handleValueChange = (key: keyof DataSourceModel, value: string): void => {
    const formValuesCopy = { ...formValues, [key]: value };
    const allFieldsFilled = Object.values(formValuesCopy).every(fieldValue => fieldValue.trim() !== '');
    const regex = /^[^\s/$.?#].[^\s]*\.netsuite\.com\/?$/;
    const testUrl = regex.test(formValuesCopy.ODBCURL) &&  regex.test(formValuesCopy.soapUrl)
    setFormValues(formValuesCopy);
    if (!allFieldsFilled || !testUrl) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }  
  };

  const handleDropdownChange = (value: string) => {
    handleValueChange('basicDataSource', value);
  };

  const handleClear = (): void => {
    queryClient.setQueryData(['updateFormSource'], false);
    queryClient.setQueryData(['tenantBackupSourceId'],initialValues)
    setAlreadySavedFormData(undefined)
    clearFormData();
    setFormValues(initialValues);
    setErrorForm(true);
  };

  const handleSuccess = () => {
    console.log("Success callback triggered.");
  };

  const handleError = (error: any) => {
    console.error("Error callback triggered:", error);
  };

  const handleSubmit = (values: DataSourceModel): void => {
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId || "";
    const payloadData = MapValueforPayload(values);
    if(alreadySavedFormData) {
      updateFormData({tenantBackupSourceId:alreadySavedFormData ? alreadySavedFormData.id : "" as unknown as any,formData:payloadData}, {
        onSuccess: (data: any) => {
          queryClient.setQueryData(['tenantBackupSourceId'], data);
          queryClient.setQueryData(['updateFormSource'], true);
          nextStep();
          onSuccess(); // Call the onSuccess callback prop
          if(queryClient.getQueryData(['tenantOnboardingStatus']) === 'ADDED_SLA'){
            queryClient.setQueryData(['currentTab'], 4);
          }
          setApiError("")
        },
        onError: (error: any) => {
          onError(error); // Call the onError callback prop
          setApiError(error.data.detail[0].msg.toString())
        }
      });
    } else {
    saveFormData(payloadData, {
      onSuccess: (data) => {
        queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_DS");
        updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_DS"})
        queryClient.setQueryData(['tenantBackupSourceId'], data);
        queryClient.setQueryData(['updateFormSource'], true);
        onSuccess(); // Call the onSuccess callback prop
        queryClient.setQueryData(['currentTab'], 2);
        setApiError("")
        nextStep();
      },
      onError: (error: any) => {
        onError(error); // Call the onError callback prop
        setApiError(error.data.detail[0].msg.toString())
      }
    });
  }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
        <div>
          <Title>Basic</Title>
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validate={async (values) => {
              const errors = areAllValuesFilled(values)
                ? {} as Partial<DataSourceModel>
                : { basicDataSource: '' } as Partial<DataSourceModel>;
              const urlFields = ['ODBCURL', 'soapUrl'];
              for (const field of urlFields) {
                if (
                  values[field as keyof DataSourceModel] &&
                  !regexUrl.test(values[field as keyof DataSourceModel] as unknown as any)
                ) {
                  errors[field as keyof DataSourceModel] =
                    'Incorrect URL, please enter the correct url ending with .netsuite.com';
                }
              }

              Object.keys(values).forEach((key) => {
                if (!values[key as keyof DataSourceModel]) {
                  errors[key as keyof DataSourceModel] = 'Required';
                }
              });

              if (!values.basicDataSource) {
                errors.basicDataSource = 'Required';
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue }) => {
              const handleButtonClick = async () => {
                handleSubmit(formValues);
                // if (isUpdate) {
                //   setActiveComponent(() => <CreateBackupJob />);
                // } else {
                //   setActiveComponent(() => <DataDestination nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>);
                // }
              };

              return (
                <Form>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        width: '625px',
                        color: 'rgba(255, 255, 255, 0.8)',
                        marginBottom: '15px'
                      }}
                    >
                      <DropdownLabel>Data Source *</DropdownLabel>
                      <CustomDropdown
                        options={dataSourceSelect}
                        width="440px"
                        height="40px"
                        labels="Select Data Source"
                        value={dataSourceSelect.find(
                          (option) =>
                            option.value === formValues.basicDataSource,
                        )}
                        onChange={(selectedOption) =>
                          handleValueChange(
                            'basicDataSource',
                            selectedOption ? selectedOption.value : '',
                          )
                        }
                      />
                    </div>
                    <ErrorMessage name="basicDataSource" component="div" />
                    <FormInputBox
                      name="basicName"
                      label="Given Name *"
                      placeholder="Given Name"
                      validators={[validationUtils.required]}
                      value={formValues.basicName}
                      onChangeValue={e => handleValueChange('basicName', e)}
                    />
                    <FormInputBox
                      name="basicEnvironment"
                      label="Environment *"
                      placeholder="Environment"
                      validators={[validationUtils.required]}
                      value={formValues.basicEnvironment}
                      onChangeValue={e => handleValueChange('basicEnvironment', e)}
                    />
                    <Title>Account Details</Title>
                    <FormInputBox
                      name="accountId"
                      label="Account ID *"
                      placeholder="Account ID *"
                      validators={[validationUtils.required]}
                      value={formValues.accountId}
                      onChangeValue={e => handleValueChange('accountId', e)}
                    />
                    <FormInputBox
                      name="accountCustomerKey"
                      label="Consumer Key *"
                      placeholder="Consumer Key *"
                      validators={[validationUtils.required]}
                      value={formValues.accountCustomerKey}
                      onChangeValue={e => handleValueChange('accountCustomerKey', e)}
                      type="password"
                    />
                    <FormInputBox
                      name="accountCustomerSecret"
                      label="Consumer Secret *"
                      placeholder="Consumer Secret *"
                      validators={[validationUtils.required]}
                      value={formValues.accountCustomerSecret}
                      onChangeValue={e => handleValueChange('accountCustomerSecret', e)}
                      type="password"
                    />
                    <Title>ODBC Account Details</Title>
                    <DescriptiveData>
                      ODBC Connection is used to backup your whole data in one shot, covering all the meta data as well
                    </DescriptiveData>
                    <FormInputBox
                      name="ODBCURL"
                      label="URL *"
                      placeholder="URL"
                      infoTooltip="URL allows only this format <accountId>.connect.api.netsuite.com"
                      validators={[validationUtils.required, regexValidator, validationUtils.url]}
                      value={formValues.ODBCURL}
                      onChangeValue={e => handleValueChange('ODBCURL', e)}
                    />
                    <FormInputBox
                      name="ODBCRoleId"
                      label="ODBC Role Id *"
                      placeholder="ODBC Role Id *"
                      validators={[validationUtils.required]}
                      value={formValues.ODBCRoleId}
                      onChangeValue={e => handleValueChange('ODBCRoleId', e)}
                    />
                    <div
                      style={{
                        display: 'flex',
                        width: '625px',
                        color: 'rgba(255, 255, 255, 0.8)',
                        marginBottom: '15px'
                      }}
                    >
                      <DropdownLabel>Server Data Source *</DropdownLabel>
                      <CustomDropdown
                        options={dataSourceSelectedType}
                        width="440px"
                        height="40px"
                        labels="Select Server Data Source"
                        value={dataSourceSelectedType.find(
                          (option) =>
                            option.value === formValues.ODBCServerDataSource,
                        )}
                        onChange={(selectedOption) =>
                          handleValueChange(
                            'ODBCServerDataSource',
                            selectedOption ? selectedOption.value : '',
                          )
                        }
                      />
                    </div>
                    <ErrorMessage name="ODBCServerDataSource" component="div" />
                    <FormInputBox
                      name="ODBCTokenKey"
                      label="Token Key *"
                      placeholder="Token Key *"
                      validators={[validationUtils.required]}
                      value={formValues.ODBCTokenKey}
                      onChangeValue={e => handleValueChange('ODBCTokenKey', e)}
                      type="password"
                    />
                    <FormInputBox
                      name="ODBCTokenSecret"
                      label="Token Secret *"
                      placeholder="Token Secret *"
                      validators={[validationUtils.required]}
                      value={formValues.ODBCTokenSecret}
                      onChangeValue={e => handleValueChange('ODBCTokenSecret', e)}
                      type="password"
                    />
                    <Title>SOAP Account Details</Title>
                    <DescriptiveData>
                      SOAP Connection is used to restore your data to any Netsuite environment
                    </DescriptiveData>
                    <FormInputBox
                      name="soapUrl"
                      label="SOAP Webservice URL *"
                      placeholder="SOAP Webservice URL *"
                      infoTooltip="URL allows only this format https://<accountId>.suitetalk.api.netsuite.com/services/"
                      validators={[validationUtils.required]}
                      value={formValues.soapUrl}
                      onChangeValue={e => handleValueChange('soapUrl', e)}
                    />
                    <FormInputBox
                      name="soapRoleId"
                      label="SOAP Role Id *"
                      placeholder="SOAP Role Id *"
                      validators={[validationUtils.required]}
                      value={formValues.soapRoleId}
                      onChangeValue={e => handleValueChange('soapRoleId', e)}
                    />
                    <FormInputBox
                      name="soapTokenKey"
                      label="Token ID *"
                      placeholder="Token ID *"
                      validators={[validationUtils.required]}
                      value={formValues.soapTokenKey}
                      onChangeValue={e => handleValueChange('soapTokenKey', e)}
                      type="password"
                    />
                    <FormInputBox
                      name="soapTokenSecret"
                      label="Token Secret *"
                      placeholder="Token Secret *"
                      validators={[validationUtils.required]}
                      value={formValues.soapTokenSecret}
                      onChangeValue={e => handleValueChange('soapTokenSecret', e)}
                      type="password"
                    />
                    { apiError ? (<ValidationError errorMessageTID={apiError} ></ValidationError>) : (<></>)}
                    <ButtonSource>
                      <Button
                        style={{
                          margin: '7px',
                          backgroundColor: 'transparent',
                          border: '2px solid white',
                          color: 'white',
                          opacity: 0.8
                        }}
                        variant="primary"
                        size="large"
                        onClick={handleClear}
                      >
                        Reset
                      </Button>
                      <Button
                        style={{ margin: '7px' }}
                        variant="primary"
                        size="large"
                        onClick={handleButtonClick}
                        //disabled={errorForm}
                        >
                        {alreadySavedFormData ? 'Update Source' : 'Create Source'}
                      </Button>
                    </ButtonSource>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
    </div>
  );
};

function MapValueforPayload(dataSourcePayload: DataSourceModel) {
  return {
    backupSourceTypeId: dataSourcePayload.basicDataSource,
    name: dataSourcePayload.basicName,
    environment: dataSourcePayload.basicEnvironment,
    accessDetails: {
      accountDetails: {
        accountId: dataSourcePayload.accountId,
        accountCustomerKey: dataSourcePayload.accountCustomerKey,
        accountCustomerSecret: dataSourcePayload.accountCustomerSecret,
      },
      odbcDetails: {
        ODBCURL: dataSourcePayload.ODBCURL,
        ODBCRoleId: dataSourcePayload.ODBCRoleId,
        ODBCServerDataSource: dataSourcePayload.ODBCServerDataSource ? dataSourcePayload.ODBCServerDataSource : '',
        ODBCTokenKey: dataSourcePayload.ODBCTokenKey,
        ODBCTokenSecret: dataSourcePayload.ODBCTokenSecret,
      },
      soapDetails: {
        soapUrl: dataSourcePayload.soapUrl,
        soapRoleId: dataSourcePayload.soapRoleId,
        soapTokenKey: dataSourcePayload.soapTokenKey,
        soapTokenSecret: dataSourcePayload.soapTokenSecret,
      },
    },
  };
}

function MapValueForForm(dataSourceForm: DataSourcePayload) {
  return {
    basicDataSource: dataSourceForm.backupSourceTypeId,
    basicName: dataSourceForm.name,
    basicEnvironment: dataSourceForm.environment,
    ODBCURL: dataSourceForm.accessDetails.odbcDetails.ODBCURL,
    ODBCRoleId: dataSourceForm.accessDetails.odbcDetails.ODBCRoleId,
    ODBCServerDataSource: dataSourceForm.accessDetails.odbcDetails.ODBCServerDataSource,
    ODBCTokenKey: dataSourceForm.accessDetails.odbcDetails.ODBCTokenKey,
    ODBCTokenSecret: dataSourceForm.accessDetails.odbcDetails.ODBCTokenSecret,
    soapUrl: dataSourceForm.accessDetails.soapDetails.soapUrl,
    soapRoleId: dataSourceForm.accessDetails.soapDetails.soapRoleId,
    soapTokenKey: dataSourceForm.accessDetails.soapDetails.soapTokenKey,
    soapTokenSecret: dataSourceForm.accessDetails.soapDetails.soapTokenSecret,
    accountId: dataSourceForm.accessDetails.accountDetails.accountId,
    accountCustomerKey: dataSourceForm.accessDetails.accountDetails.accountCustomerKey,
    accountCustomerSecret: dataSourceForm.accessDetails.accountDetails.accountCustomerSecret
  };
};

export default DataSource;
