import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import { DestinationApi, DestinationAPITypeRequest, UserModel } from 'src/model/BackupJobModel';
import ApiServiceInstance from 'src/service/service';

export const useSaveFormDataDestination = () => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useMutation(
    (formDataDestination: DestinationAPITypeRequest) => ApiServiceInstance.createTenantBackupDestination({ tenantId: tenantId!, data: formDataDestination as unknown as DestinationAPITypeRequest }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['formDataDestination']);
        queryClient.setQueryData(['tenantBackupDestinationId'], data);
        queryClient.setQueryData(['tenantBackupDestinationIdData'], data.id);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );};

  export const useUpdateDataDestination = () => { // Renamed to useUpdateTenantData
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;

    return useMutation(
      ({ tenantBackupDestinationaId, formData }: { tenantBackupDestinationaId: string, formData: DestinationApi }) =>
        ApiServiceInstance.updateTenantBackupDestination({
          tenantId: tenantId!,
          tenantBackupDestinationaId: tenantBackupDestinationaId,
          data: formData,
        }),
      {
        onSuccess: (data) => {
          queryClient.setQueryData(['tenantBackupDestinationId'], data);
        },
        onError: (error) => {
          console.error('Error saving form data tenant', error);
        },
      }
    );
  };

  export const useFetchFormDataDestination = (tenantBackupDestinationaId: string) => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
    return useQuery(['formDataDestination', tenantBackupDestinationaId], () => ApiServiceInstance.getTenantBackupDestination({tenantId: tenantId!, tenantBackupDestinationaId}), {
      enabled: !!tenantBackupDestinationaId, // Only fetch if tenantBackupSourceId is provided
      // staleTime: 600000, // cache for 10 minutes
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    });
  };

  export const useFetchAllBackupDestination = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['BackupDestinationAll', {tenantId}],
      () => ApiServiceInstance.getAllTenantBackupDestination({ tenantId: tenantId! }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 1500, // 10 minutes
        cacheTime: 1500, // 15 minutes
      }
    );
  };
  
  export const useClearFormDataDestination = () => {
    const queryClient = useQueryClient();
    return useMutation(
      () => {
        // Clear operation (if needed on the server-side)
        return Promise.resolve();
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(['formDataDestination'], null);
        },
        onError: (error) => {
          console.error('Error clearing form data source', error);
        },
      }
    );
  };