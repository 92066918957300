import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import {BackupSourceMonitoringApi, UserModel} from '../../model/BackupJobModel';

export const useSaveBackupJobMonitoring = () => {
  const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;
  
  return useMutation(
    (formData: BackupSourceMonitoringApi) => ApiServiceInstance.createBackupSourceMonitoring({ tenantId: tenantId!, data: formData }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['backupJobMonitoring']);
        queryClient.setQueryData(['tenantBackupJobMonitoringId'], data);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );};

export const useFetchBackupJob = (tenantBackupSourceId: string) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['backupDataMonitoring', tenantBackupSourceId],
    () => ApiServiceInstance.getTenantBackupSource({ tenantId: tenantId!, tenantBackupSourceId }),
    {
      enabled: !!tenantId && !!tenantBackupSourceId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};

export const useFetchAllBackupJobMonitoring = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['backupDataMonitoring', {tenantId}],
      () => ApiServiceInstance.getAllBackupSourceMonitoring({ tenantId: tenantId! }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
      }
    );
  };

export const useClearFormDataSource = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      // Clear operation (if needed on the server-side)
      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(['backupDataMonitoring'], null);
      },
      onError: (error) => {
        console.error('Error clearing form data source', error);
      },
    }
  );
};
