import * as React from 'react';
import { StepNavigation, StepNavigationStep } from '@rubrik/aura-ui';
import DataSource from 'src/components/BackupJob/CreateDataSource';
import DataDestination from 'src/components/BackupJob/CreateDataDestination';
import CreateBackupJob from 'src/components/BackupJob/createbackupJob';
import MonitoringJob from 'src/components/BackupJob/MonitoringJob';
import SlaPage from 'src/components/BackupJob/CreateSla';
import { ContainerBackup, Content, FormBackup, Heading, ScrollableContent, Sidebar, TitleStep, VerticalLine } from 'src/style/emotionStyle';
import { useFetchTenant } from '../Mutation/tenantMutation';
import { useFetchAllBackupSource } from '../Mutation/DataSourceMutation';
import { useFetchAllBackupJob } from '../Mutation/BackupJobMutation';
import { useFetchAllBackupDestination } from '../Mutation/DataDestinationMutation';
import { useFetchAllBackupSla } from '../Mutation/DataSlaMutation';
import { BackupJobTableResponse, DataSourceApi, DestinationAPITenantResponse, DestinationAPITypeResponse, SLAData } from 'src/model/BackupJobModel';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const steps: Array<StepNavigationStep> = [
  {
    id: 'Step 1',
    isCompleted: false,
    isCurrent: true,
    isDisabled: () => true,
    title: 'Data Source',
    subtitle: 'Details of Netsuite data, access mechanism and environment',
  },
  {
    id: 'Step 2',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => true,
    title: 'Data Destination',
    subtitle: 'Configure where your backup data would be stored',
  },
  {
    id: 'Step 3',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => true,
    title: 'SLA Domain',
    subtitle: 'Configure how frequently you want to backup your data',
  },
  {
    id: 'Step 4',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => true,
    title: 'Backup Job',
    subtitle: 'Combine all of the above to create backup job',
  },
  {
    id: 'Step 5',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => true,
    title: 'Start Monitoring',
    subtitle: 'Watch your backup job in action',
  },
];

interface CreateBackupLandingProps {
  currentTab?: number;
  onTabChange?: (tab: number) => void; // Add a prop for the callback
}

const CreateBackupLanding: React.FC<CreateBackupLandingProps> = ({
  currentTab,
  onTabChange, // Destructure the callback
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState<StepNavigationStep | undefined>(
    steps[0]
  );
  const { data: tenantData } = useFetchTenant();
  const { data: sourceData } = useFetchAllBackupSource();
  const { data: destinationData } = useFetchAllBackupDestination();
  const { data: slaData } = useFetchAllBackupSla();
  const { data: BackupJob} = useFetchAllBackupJob() as unknown as any;

  const [savedDataSource, setsavedDataSource] = React.useState<DataSourceApi>();
  const [savedDataDestination, setsavedDataDestination] = React.useState<DestinationAPITypeResponse>();
  const [savedDataSla, setsavedDataSla] = React.useState<SLAData>();

  if(queryClient.getQueryData(['tenantOnboardingStatus']) === undefined){
    queryClient.setQueryData(['tenantOnboardingStatus'], tenantData?.onboardingStatus);
  }
  React.useEffect(() => {
    queryClient.setQueryData(['updateFormSource'], false);
    queryClient.setQueryData(['updateFormSla'], false);
      if (tenantData && tenantData.onboardingStatus) {
        const alreadySavedDataSource = FindNotCreatedBackupJob('ADDED_DS', sourceData as unknown as any, destinationData as unknown as any, slaData as unknown as any, BackupJob) as unknown as any;
        const alreadySavedDataDestination = FindNotCreatedBackupJob('ADDED_DT', sourceData as unknown as any, destinationData as unknown as any, slaData as unknown as any, BackupJob) as unknown as any
        const alreadySavedDataSLA = FindNotCreatedBackupJob('ADDED_SLA', sourceData as unknown as any, destinationData as unknown as any, slaData as unknown as any, BackupJob) as unknown as any

      switch (tenantData.onboardingStatus) {
        case 'ADDED_DS':
          setSelectedStep(0);
          setCurrentStep(steps[0]);
          queryClient.setQueryData(['tenantBackupSourceId'], sourceData?.tenantBackupSources[0])
          queryClient.setQueryData(['currentTab'], 1);
          queryClient.setQueryData(['updateFormSource'], true);
          break;
        case 'ADDED_DT':
          setSelectedStep(1);
          setCurrentStep(steps[1]);
          queryClient.setQueryData(['tenantBackupSourceId'], sourceData?.tenantBackupSources[0])
          queryClient.setQueryData(['tenantBackupDestinationId'], destinationData?.tenantBackupDestinations[0])
          queryClient.setQueryData(['currentTab'], 2);
          break;
        case 'ADDED_SLA':
          setSelectedStep(2);
          setCurrentStep(steps[2]);
          queryClient.setQueryData(['tenantBackupSourceId'], sourceData?.tenantBackupSources[0])
          queryClient.setQueryData(['tenantBackupDestinationId'], destinationData?.tenantBackupDestinations[0])
          queryClient.setQueryData(['tenantBackupSlaId'], slaData?.slas[0])
          queryClient.setQueryData(['currentTab'], 3);
          queryClient.setQueryData(['updateFormSla'], true);
          break;
        case 'ADDED_BK_JOB':
          setSelectedStep(3);
          setCurrentStep(steps[3]);
          FindNotCreatedBackupJob('ADDED_BK_JOB', sourceData as unknown as any, destinationData as unknown as any, slaData as unknown as any, BackupJob)
          queryClient.setQueryData(['tenantBackupSourceId'], sourceData?.tenantBackupSources[0])
          queryClient.setQueryData(['tenantBackupDestinationId'], destinationData?.tenantBackupDestinations[0])
          queryClient.setQueryData(['tenantBackupSlaId'], slaData?.slas[0])
          break;
        case 'ADDED_MONITORING':
          queryClient.setQueryData(['tenantBackupSourceId'], "")
          queryClient.setQueryData(['tenantBackupDestinationId'], "")
          queryClient.setQueryData(['tenantBackupSlaId'], "")
          queryClient.setQueryData(['tenantBackupJobIdData'], "")
          break;
        default:
          break;
      }
    }
  }, [tenantData]);

  const nextStep = () => {
    const nextStepIndex = selectedStep + 1;
    if (nextStepIndex < steps.length) {
      setSelectedStep(nextStepIndex);
      setCurrentStep(steps[nextStepIndex]);
      if(queryClient.getQueryData(['tenantOnboardingStatus'])) {
        if (queryClient.getQueryData(['tenantOnboardingStatus']) && queryClient.getQueryData(['tenantOnboardingStatus']) !== 'ADDED_BK_JOB') {
          queryClient.setQueryData(['currentTab'], nextStepIndex + 1);
        } else if (queryClient.getQueryData(['tenantOnboardingStatus']) && queryClient.getQueryData(['tenantOnboardingStatus']) === 'ADDED_BK_JOB') {
          queryClient.setQueryData(['currentTab'], 4);
        }
      } else {
    if (tenantData && tenantData.onboardingStatus !== 'ADDED_BK_JOB') {
      queryClient.setQueryData(['currentTab'], nextStepIndex + 1);
    } else if (tenantData && tenantData.onboardingStatus === 'ADDED_BK_JOB') {
      queryClient.setQueryData(['currentTab'], 4);
    }
  }    
}
  };

  React.useEffect(() => {
    if (currentTab !== undefined) {
      setSelectedStep(currentTab);
      setCurrentStep(steps[currentTab]);
    }
  }, [currentTab]);

  const handleSuccess = () => {
    console.log("Success callback triggered.");
  };

  const handleError = (error: any) => {
    console.error("Error callback triggered:", error);
  };

  const renderFormComponent = () => {
    switch (selectedStep) {
      case 0:
        return <DataSource nextStep={nextStep} onSuccess={handleSuccess} onError={handleError} savedData={savedDataSource}/>;
      case 1:
        return <DataDestination nextStep={nextStep} onSuccess={handleSuccess} onError={handleError} savedData={savedDataDestination}/>;
      case 2:
        return <SlaPage nextStep={nextStep} onSuccess={handleSuccess} onError={handleError} savedData={savedDataSla}/>;
      case 3:
        return <CreateBackupJob nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>;
      case 4:
        return <MonitoringJob onSuccess={handleSuccess} onError={handleError}/>;
      default:
        return null;
    }
  };

  const renderHeading = () => {
    if (currentStep?.title === 'Start Monitoring') {
      return `5. Start Monitoring`;
    }
    return `${currentStep?.id.split(' ')[1]}. Add ${currentStep?.title}`;
  };

  return (
    <ContainerBackup>
      <Sidebar>
        <TitleStep>Secure Data Now</TitleStep>
        <StepNavigation
          isVertical={true}
          steps={steps.map(step => ({
            ...step,
            isCurrent: currentStep?.id === step.id
          }))}
          onStepClick={(step, stepIndex) => {
            setSelectedStep(stepIndex);
            setCurrentStep(step);
          }}
        />
      </Sidebar>
      <VerticalLine />
      <Content>
        <FormBackup>
        <div>
         <Heading>
         {renderHeading()}
        </Heading>
        <ScrollableContent width={renderHeading() === '5. Start Monitoring' ? '1162px' : '706px'}>
          {renderFormComponent()}
          </ScrollableContent>
          </div>
        </FormBackup>
      </Content>
    </ContainerBackup>
  );
};

function FindNotCreatedBackupJob(status: string, sourceData: DataSourceApi , destinationData: DestinationAPITenantResponse , slaData: SLAData , BackupJob: BackupJobTableResponse) {
  switch(status){
    case 'ADDED_DS':
      return sourceData?.tenantBackupSources.filter((caseItem) =>
        !BackupJob?.backupJobs.some(backupJob => backupJob.tenantBackupSourceId === caseItem.id)
      );
    case 'ADDED_DT':
      return destinationData?.tenantBackupDestinations.filter(caseItem =>
        !BackupJob?.backupJobs.some(backupJob => backupJob.tenantBackupDestinationId === caseItem.id)
      );
    case 'ADDED_SLA':
      return  slaData?.slas.filter(caseItem =>
        !BackupJob?.backupJobs.some(backupJob => backupJob.slaId === caseItem.id)
      );
    // case 'ADDED_BK_JOB':
    //   const { data: sourceata } = useFetchAllBackupSource();
    // case 'ADDED_MONITORING':
    //   const { data: monitoringData } = useFetchAllBackupJobMonitoring();
    default:
      return
  }   
}

export default CreateBackupLanding;