import * as React from 'react';
import { Button } from '@rubrik/aura-ui';
import CreateBackupLanding from 'src/components/BackupJob/CreateBackupLanding';
import {
  Container,
  GridContainer,
  GridContainerRow,
  Heading,
  Title,
} from 'src/style/emotionStyle';
import ApiServiceInstance from 'src/service/service';
import { useFetchAllBackupJob } from './Mutation/BackupJobMutation';
import DashboardPage from './dashboard';
import { useFetchTenant } from './Mutation/tenantMutation';
import { useQueryClient } from '@tanstack/react-query';

function NewUserFlowPage() {
  const queryClient = useQueryClient();
  const [isButtonClicked, setButtonClicked] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await ApiServiceInstance.getCurrentUser();
      const responseTenant = await ApiServiceInstance.getTenant({ tenantId: response.tenantId! });
      queryClient.setQueryData(['tenantOnboardingStatus'], responseTenant?.onboardingStatus);
    };

    fetchData();
  }, [queryClient]);

  const HandleClick = () => {
    setButtonClicked(true);
    queryClient.setQueryData(['newUserFlow'], true);
    queryClient.setQueryData(['currentTab'], 1);
  };

  const onboardingStatus = queryClient.getQueryData<string>(['tenantOnboardingStatus']);

  return (
    <div>
      {onboardingStatus !== null ? (
        <DashboardPage />
      ) : (
        <div>
          {!isButtonClicked ? (
            <GridContainerRow>
              <GridContainer />
              <Container>
                <img
                  alt=""
                  height={'300px'}
                  width={'300px'}
                  src="/onboarding.png"
                />
                <Heading>Welcome to FinGuard</Heading>
                <Title>Securing all your finance data in One Go at One place</Title>
                <Button variant="primary" size="large" onClick={HandleClick}>
                  Start Securing Now
                </Button>
              </Container>
              <GridContainer />
            </GridContainerRow>
          ) : (
            <CreateBackupLanding />
          )}
        </div>
      )}
    </div>
  );
}

export default NewUserFlowPage;