import {
  Accordion,
  Button,
  Card,
  Checkbox,
  FlexColumnJustifyCenter,
  FlexRowJustifyCenter,
  InputBox,
  Table,
  rem,
  ColumnDef,
  CellContext,
  PageLoader,
  SkeletonLoader
} from '@rubrik/aura-ui';
import styled from '@emotion/styled';
import * as React from 'react';
import {DisabledFillIcon, DownloadingIcon, FailedFillIcon, ProgressFillIcon, ScheduledFillIcon, SuccessFillIcon, WarningFillIcon} from '@rubrik/aura-ui/icons';
import {
  useFetchAllBackupJob,
} from '../Mutation/BackupJobMutation';
import { useFetchAllBackupSource } from '../Mutation/DataSourceMutation';
import { useFetchAllBackupSourceType } from '../Mutation/BackupSourceTypeMutation';
import { useFetchAllBackupJobInstance, useFetchAllBackupJobInstanceReport } from '../Mutation/BackupJobInstanceMutation';
import 'react-datepicker/dist/react-datepicker.css'; // Make sure this import is present
import {
  AccordionText,
  BackupJobTextValue,
  CheckBoxText,
  ContainerBackupTable,
  Content,
  CreateBackupJobRows,
  HeadingTable,
  SidebarTable,
  TableStatus,
  TableStatusBox,
  TitleBackupJobTable,
  VerticalLine,
} from 'src/style/emotionStyle';
import DatePicker from 'react-datepicker';
import { BackupJobInstanceApi } from 'src/model/BackupJobModel';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// Utility function to parse the date safely
const safeParseDate = (dateString: string) => {
  const parsedDate = Date.parse(dateString);
  return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
};

const DateInputBox: React.FC<{
  value: string;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataAvailable: boolean;
  placeholder: string;
}> = ({ value, onChangeValue, dataAvailable, placeholder }) => {
  const handleDateChange = (date: Date | null) => {
    onChangeValue({
      target: { value: date ? date.toISOString().split('T')[0] : '' },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <DatePicker
      selected={value ? safeParseDate(value) : null}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
      placeholderText={placeholder}
      disabled={!dataAvailable}
      // Ensure date picker styles aren't overridden
      customInput={
        <InputBox
          style={{ padding: '0px 5px 0px 5px', maxWidth: '120px' }}
          placeholder={placeholder}
        />
      }
    />
  );
};

// Utility function to generate a range of numbers
const range = (start: number, end: number): number[] => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

// Column Definition for the Table
type MockColumnData = {
  source: string;
  environment: string;
  destination:string;
  backupType: string;
  createdOn: string;
  updatedOn: string | null; // Allow null for updatedOn
  executeOn: string;
  status: string;
};

const StatusIcon: React.FC<{ status: string }> = ({ status }) => {
  switch (status) {
    case 'Scheduled':
      return <TableStatusBox color="#4DD2D2" width="110px" background="#4dd2d226"><ScheduledFillIcon/> <TableStatus>Scheduled</TableStatus></TableStatusBox>;
    case 'In Progress':
      return <TableStatusBox color="#2FCAFF" width="110px" background="#00beff38"><ProgressFillIcon/> <TableStatus>In Progress</TableStatus></TableStatusBox>;
    case 'Success':
      return <TableStatusBox color="#11d29d" width="95px" background="#11d29d3d"><SuccessFillIcon/> <TableStatus>Success</TableStatus></TableStatusBox>;
    case 'Failed':
      return <TableStatusBox color="#d24954" width="80px" background="#d2495412"><FailedFillIcon/> <TableStatus>Failed</TableStatus></TableStatusBox>;
    case 'Queued':
      return <TableStatusBox color="#FFB721" width="90px" background="#ffb7211f"><WarningFillIcon/> <TableStatus>Queued</TableStatus></TableStatusBox>;
    case 'Cancelled':
      return <TableStatusBox color="#FF9261" width="110px" background="#d2495412"><DisabledFillIcon/> <TableStatus>Cancelled</TableStatus></TableStatusBox>;
    default:
      return <TableStatusBox color="#4DD2D2" width="110px" background="#4dd2d226"><TableStatus>{status}</TableStatus></TableStatusBox>;
  }
};

const columns: ColumnDef<MockColumnData>[] = [
  {header: 'Source', accessorKey: 'source'},
  {header: 'Environment', accessorKey: 'environment'},
  {header: "Destination", accessorKey: 'destination'},
  {header: 'Backup Type', accessorKey: 'backupType'},
  {header: 'Created On', accessorKey: 'createdOn'},
  {header: 'Updated On', accessorKey: 'updatedOn'},
  {header: 'Execute On', accessorKey: 'executeOn'},
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ cell }: CellContext<MockColumnData, unknown>) => <StatusIcon status={cell.getValue() as string} />  // Use custom icon component
  }];

// Mapping from backend statuses to user-friendly statuses
const statusMapping: {[key: string]: string} = {
  SCHEDULED: 'Scheduled',
  IN_PROGRESS: 'In Progress',
  UPLOADING: 'In Progress',
  COMPLETED: 'Success',
  FAILED: 'Failed',
  QUEUED: 'Queued',
  CANCELLED: 'Cancelled'
};

// List of user-friendly statuses
const userFriendlyStatuses = [
  'Scheduled',
  'In Progress',
  'Success',
  'Failed',
  'Queued',
  'Cancelled'
];

// Transform API response to table data
const transformApiResponseToTableData = (
  apiResponse: BackupJobInstanceApi,
): MockColumnData[] => {
  const tableDataAll: MockColumnData[] = [];
  if (apiResponse && apiResponse.backupJobInstances.length) {
    apiResponse.backupJobInstances.map((job) => {
      // Format date and time and preserve the space separator
      const formatDate = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format;

      const formatTime = new Intl.DateTimeFormat('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'UTC'
      }).format;

      const createdOnDate = formatDate(new Date(job.createdOn));
      const createdOnTime = formatTime(new Date(job.createdOn));
      const createdOn = `${createdOnDate} ${createdOnTime}`;
      const updatedOn = job.updatedOn ? (() => {
        const updatedOnDate = formatDate(new Date(job.updatedOn));
        const updatedOnTime = formatTime(new Date(job.updatedOn));
        return `${updatedOnDate} ${updatedOnTime}`;
      })() : null;
      const executeOn = job.executeOn ? (() => {
        const executedOnDate = formatDate(new Date(job.executeOn));
        const executedOnTime = formatTime(new Date(job.executeOn));
        return `${executedOnDate} ${executedOnTime}`;
      })() : null;

        tableDataAll.push({
          source: job.tenantBackupSource.backupSource.sourceType,
          environment: job.tenantBackupSource.environment,
          destination: job.tenantBackupDestination.destination.destinationType,
          backupType: job.type,
          createdOn: createdOn,
          updatedOn: updatedOn,
          executeOn: executeOn as unknown as any,
          status: statusMapping[job.status] || job.status,
        });
      },
    );
  }
  return tableDataAll;
};

// Table wrapper styled component
const TableWrapper = styled.div({
  height: rem(800),
  overflow: 'hidden',
  resize: 'vertical',
});

// Checkbox keys for status
type CheckboxKey =
  | 'Scheduled'
  | 'In Progress'
  | 'Success'
  | 'Failed'
  | 'Queued'
  | 'Cancelled';
const initialCheckboxState: Record<CheckboxKey, boolean> = {
  Scheduled: false,
  'In Progress': false,
  Success: false,
  Failed: false,
  Queued: false,
  Cancelled:false,
};

// Checkbox keys for environment
type CheckboxKeyEnv = 'Netsuite - Dev - Sandbox' | 'Netsuite - Production';
const initialCheckboxStateEnv: Record<CheckboxKeyEnv, boolean> = {
  'Netsuite - Dev - Sandbox': false,
  'Netsuite - Production': false,
};

export function BackupJobTable() {
  // Pagination state
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(0);
  const pageSize = 50;  // Change this if you want a different page size

 // Fetch API data with pagination and filters
 const [checkboxes, setCheckboxes] = React.useState<Record<CheckboxKey, boolean>>(initialCheckboxState);
 const selectedStatuses = Object.keys(checkboxes).filter(key => checkboxes[key as CheckboxKey]);
 // const statusParam = selectedStatuses.length > 0 ? (selectedStatuses[0]=== 'Success' ? 'COMPLETED' : selectedStatuses[0].toUpperCase()) : undefined;
 const statusParam = selectedStatuses.length > 0 ? (selectedStatuses[0]=== 'Success' ? 'COMPLETED' : (selectedStatuses[0]=== 'In Progress' ? 'IN_PROGRESS' :selectedStatuses[0].toUpperCase())) : undefined;

  const { data: backupJobAll, refetch, isFetching } = useFetchAllBackupJobInstance(currentPage, pageSize, undefined , statusParam);

  const [tableData, setTableData] = React.useState<MockColumnData[]>([]);
  const [originalData, setOriginalData] = React.useState<MockColumnData[]>([]);
  const [dataAvailable, setDataAvailable] = React.useState(false);

  // State for filters
  const [checkboxesEnv, setCheckboxesEnv] = React.useState<
    Record<CheckboxKeyEnv, boolean>
  >(initialCheckboxStateEnv);
  const [fromDate, setFromDate] = React.useState<string>('');
  const [toDate, setToDate] = React.useState<string>('');
  const { refetch: fetchReport } = useFetchAllBackupJobInstanceReport('CSV');

  // Function to fetch backup job instances for the current page
  const fetchPageData = async (page: number) => {
    const response = await refetch();
    const transformedData = transformApiResponseToTableData(response.data as BackupJobInstanceApi);
    setOriginalData(transformedData); // Store the original data
    setTableData(transformedData);
    setDataAvailable(true);
  };

  // Fetch initial data
  React.useEffect(() => {
    if (backupJobAll) {
      fetchPageData(currentPage);
    }
  }, [currentPage, backupJobAll]);

  // Handle filter changes
  const handleCheckboxChange = (key: CheckboxKey, value: boolean) => {
    setCheckboxes((prevState) => ({
        ...initialCheckboxState,
        [key]: value,
    }));
};

  const handleCheckboxChangeEnv = (key: CheckboxKeyEnv, value: boolean) => {
    setCheckboxesEnv((prevState) => ({
        ...prevState,
        [key]: value,
    }));
  };

  const handleValueChange = (key: string, value: string) => {
    if (key === 'fromDate') {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  // Filter functionality
  const filterData = () => {
    let filteredData = [...originalData]; // Use the original data

    // Filter by status
    if (Object.values(checkboxes).some(value => value)) {
      filteredData = filteredData.filter(data =>
        userFriendlyStatuses.some(
          (status) => checkboxes[status as CheckboxKey] && data.status === status,
        ),
      );
    }

    // Filter by environment
    if (Object.values(initialCheckboxStateEnv).some(value => value)) {
      filteredData = filteredData.filter(data =>
        Object.keys(checkboxesEnv).some(
          key =>
            checkboxesEnv[key as CheckboxKeyEnv] && data.environment === key,
        ),
      );
    }

    // Filter by date range
    if (fromDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.createdOn) >= new Date(fromDate),
      );
    }
    if (toDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.createdOn) <= new Date(toDate),
      );
    }

    setTableData(filteredData);
  };

  // Trigger filter when any filter state changes
  React.useEffect(() => {
    filterData();
  }, [checkboxes, checkboxesEnv, fromDate, toDate]);

  const handlePageChange = (newPageIndex: number) => {
    setCurrentPage(newPageIndex);
  };

  // Panel for status checkboxes
  const panelStatus = (
    <FlexColumnJustifyCenter>
      {userFriendlyStatuses.map((key) => (
        <CheckBoxText key={key}>
          <Checkbox
            checkboxLabel={key}
            value={key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(key as CheckboxKey, e.target.checked)}            checked={checkboxes[key as CheckboxKey]}
            disabled={!dataAvailable}
          />
        </CheckBoxText>
      ))}
    </FlexColumnJustifyCenter>
  );

  // Panel for environment checkboxes
  const panelEnv = (
    <FlexColumnJustifyCenter>
      {Object.keys(initialCheckboxStateEnv).map((key) => (
        <CheckBoxText key={key}>
          <Checkbox
            checkboxLabel={key}
            value={key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChangeEnv(key as CheckboxKeyEnv, e.target.checked)}            checked={checkboxesEnv[key as CheckboxKeyEnv]}
            disabled={!dataAvailable}
          />
        </CheckBoxText>
      ))}
    </FlexColumnJustifyCenter>
  );

  // Panel for date range inputs
  const panelDate = (
    <FlexRowJustifyCenter>
      <DateInputBox 
        value={fromDate} 
        onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange('fromDate', e.target.value)} 
        dataAvailable={dataAvailable} 
        placeholder="From date"
      />
      :
      <DateInputBox 
        value={toDate} 
        onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange('toDate', e.target.value)} 
        dataAvailable={dataAvailable} 
        placeholder="To date"
      />
    </FlexRowJustifyCenter>
  );

  // Export Functionality
  const exportBackupJob = async () => {
    const { data: csvData } = await fetchReport();
    if (csvData) {
      const blob = new Blob([csvData as unknown as any], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'backup_jobs_report.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleCreateBackup = () => {
    queryClient.setQueryData(['currentTab'], 1);
    navigate(`/finguard/datasource/create`);
  }
  const queryClient = useQueryClient();
  const isMonitoringAdded = queryClient.getQueryData(['tenantOnboardingStatus']) === 'ADDED_MONITORING';

  return (
    <div>
      {
    <ContainerBackupTable>
      <SidebarTable>
        <TitleBackupJobTable>Filter Backup Jobs</TitleBackupJobTable>
        <AccordionText>
          <Accordion
            margin={10}
            padding={10}
            collapsedByDefault={false}
            title="By Status"
            panelDetails={panelStatus}
            dividerLocation="belowHeader"
            disabled={!dataAvailable}
          />
        </AccordionText>
        {/* <AccordionText>
          <Accordion
            margin={10}
            padding={10}
            collapsedByDefault={false}
            title="By Source Environment"
            panelDetails={panelEnv}
            dividerLocation="belowHeader"
            disabled={!dataAvailable}
          />
        </AccordionText>
        <AccordionText>
          <Accordion
            margin={10}
            padding={10}
            collapsedByDefault={false}
            title="By Date Range"
            panelDetails={panelDate}
            dividerLocation="belowHeader"
            disabled={!dataAvailable}
          />
        </AccordionText> */}
      </SidebarTable>
      <VerticalLine />
      <Content>
        <Card
          hasBorder={true}
          style={{
            position: 'relative',
            height: '95%',
            width: '95%',
            margin: '12px',
            padding: '14px',
            marginLeft: '30px',
          }}
        >
          <CreateBackupJobRows>
            <HeadingTable>Backup Jobs</HeadingTable>
            <Button
              style={{
                margin: '7px 7px 7px 730px',
                backgroundColor: 'transparent',
                border: '2px solid white',
                color: 'white',
                opacity: 0.8,
              }}
              endIcon={<DownloadingIcon />}
              variant="primary"
              size="large"
              onClick={exportBackupJob}
              disabled={tableData.length === 0}
            >
              Download CSV
            </Button>
            <Button
              style={{margin: '10px 0px 7px 10px'}}
              variant="primary"
              size="large"
               onClick={handleCreateBackup}
               disabled={isMonitoringAdded}
            >
              Execute Backup
            </Button>
          </CreateBackupJobRows>
          <TableWrapper>
            <Table
              columns={columns}
              data={tableData}
              pageSize={pageSize}
              canSelectRow={(item) => true}
              disablePagination={false}
              pageIndex={currentPage}
              setPageIndex={handlePageChange}
              totalRows={backupJobAll?.pagination.total || 0}
              loading={isFetching}
            />
          </TableWrapper>
        </Card>
      </Content>
    </ContainerBackupTable>
}
  </div>
  );
}