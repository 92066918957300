import {LoginCallback} from '@okta/okta-react';
import {Route, Routes} from 'react-router-dom';
import { BackupJobTable } from './BackupJob/BackupJobTable';
import CreateBackupLanding from './BackupJob/CreateBackupLanding';
import DashboardPage from './dashboard';
import LoginPage from './NewUserFlowPage';
import MonitoringJob from './BackupJob/MonitoringJob';
import RequiredAuth from './SecureRoute';
import NewUserFlowPage from './NewUserFlowPage';

const handleSuccess = () => {
  console.log("Success callback triggered.");
};

const handleError = (error: any) => {
  console.error("Error callback triggered:", error);
};

function routes() {
  return (
    <Routes>
      <Route path="/" element={<RequiredAuth />}>
        <Route path="" element={<NewUserFlowPage />} />
        <Route path="/finguard/dashboard" element={<DashboardPage />} />
        <Route path="/finguard/datasource/create" element={<CreateBackupLanding currentTab={0}/>} />
        <Route path="/finguard/datadestination/create" element={<CreateBackupLanding currentTab={1}/>} />
        <Route path="/finguard/datasla/create" element={<CreateBackupLanding currentTab={2}/>} />
        <Route path="/finguard/backupjob/create" element={<CreateBackupLanding currentTab={3}/>} />
        {/* <Route path="/finguard/backupjob/create" element={<CreateBackupLanding currentTab={4}/>} /> */}
        {/* <Route path="/finguard/backupjob/list" element={<BackupJobTable />} /> Updated route for listing backup jobs */}
        <Route path="/finguard/backupjobmonitoring/create" element={<CreateBackupLanding currentTab={4}/>}/>
        <Route path="/finguard/backupJob" element={<BackupJobTable />} />
      </Route>
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<div>loading...</div>} />}
      />
    </Routes>
  );
}

export default routes;