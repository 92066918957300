import {useQuery} from '@tanstack/react-query';
import { UserModel } from 'src/model/BackupJobModel';
import ApiServiceInstance from 'src/service/service';

export const useFetchFormDataUser = () => {
  return useQuery<UserModel>(
    ['formDataUser'],
    () => ApiServiceInstance.getCurrentUser(),
    {
      onError: (error) => {
        console.error('Error fetching user data', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
      retry: 1, // Retry once if the query fails
    }
  );
};
