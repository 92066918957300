import React from 'react';
import {
  Button,
  Form,
  FormFieldArray,
  FormInputBox,
  Switch,
  ValidationError,
  ValidationSuccess,
  validationUtils,
} from '@rubrik/aura-ui';
import styled from '@emotion/styled';
import {
  CreationText,
  EmailMonitoringContainer,
  Heading,
  ScrollableContent,
  ToggleButton,
  ItemsData,
  TextEmail,
  InfoMessageEmail,
  ComponentMoniter,
  TableStatusBoxMonitoring,
  EmailItemsData,
  EmailTitle,
  EmailInputBoxWidth,
  EmailStatus,
} from '../../style/emotionStyle';
import { useNavigate } from 'react-router-dom';
import { useSaveBackupJobMonitoring } from '../Mutation/BackupJobMonitoringMutation';
import { useQueryClient } from '@tanstack/react-query';
import { BackupJobApi, BackupSourceMonitoringApi, UserModel } from 'src/model/BackupJobModel';
import { SuccessFillIcon } from '@rubrik/aura-ui/icons';
import { useUpdateTenantData } from '../Mutation/tenantMutation';

const formFieldArrayName = 'mockFormFieldArray';

interface DataMonitorProps {
  onSuccess: () => void;
  onError: (error: any) => void;
 }

const MonitoringJob: React.FC<DataMonitorProps> = ({ onSuccess, onError }) => {
  const { mutate: saveFormData, isLoading } = useSaveBackupJobMonitoring();
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId || "";
  // const tenantBackupJobId = queryClient.getQueryData<BackupJobApi>(['tenantBackupJobId']);
  const tenantBackupJobId = queryClient.getQueryData<BackupJobApi>(['tenantBackupJobIdData']);
  const tenantBackupJobMonitoringId = queryClient.getQueryData<BackupJobApi>(['tenantBackupJobMonitoringId']);
  const navigate = useNavigate();
  const [emailFields, setEmailFields] = React.useState(['']);
  const [errorEmail, setErrorEmail] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState(true);
  const [successMsgEmail, setSuccessMsgEmail] = React.useState('');
  const [errorMsgEmail, setErrorMsgEmail] = React.useState('');
  const { mutate: updateTenantOnboardingStatus } = useUpdateTenantData();
  
  const handleChangeSwitch = (isCheckedData: boolean) => {
    setIsChecked(isCheckedData);
  };

  const handleEmailChange = (index: number, value:string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(value)) {
      if (!emailFields.includes(value)) {
      const newEmailFields = [...emailFields];
      newEmailFields[index] = value;
      setEmailFields(newEmailFields);
      }
      setErrorEmail(false)
    } else if (value==='' || !emailFields) {
      setErrorEmail(true)
    }
    else {
      setErrorEmail(true)
    }
  };

  // Handler function for form submit
  function handleSubmit(values:any) {
    const payloadData = {
      tenantBackupSourceId: tenantBackupJobId?.tenantBackupSourceId,
      emails: emailFields
    };
    saveFormData(payloadData as unknown as BackupSourceMonitoringApi, {
      onSuccess: (data) => {
        queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_MONITORING");
        updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_MONITORING"})
        queryClient.setQueryData(['tenantBackupJobMonitoringId'], data);
        onSuccess(); // Call the onSuccess callback prop
        setSuccessMsgEmail("Email(s) saved successfully")
        queryClient.setQueryData(['tenantBackupSourceIdData'], "");
      },
      onError: (error) => {
        onError(error); // Call the onError callback prop
        setErrorMsgEmail("Email(s) not saved successfully. Please try again")
      }
    });
  }

  const handleButtonClick = () => {
    // Set the current tab value to 0 (for dashboard)
    queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_MONITORING");
    updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_MONITORING"})
    queryClient.setQueryData(['currentTab'], 0);
    // Navigate to the dashboard
    navigate('/finguard/dashboard');
  };

  return (
    <div>
      <CreationText>
      <TableStatusBoxMonitoring> <SuccessFillIcon/> </TableStatusBoxMonitoring> Congratulations, you have successfully configured backup jobs on
        Netsuite data, now go check them out in Action on dashboard 
      </CreationText>
      <img
        alt=""
        height={'300px'}
        width={'300px'}
        src="/onboarding_complete.png"
      />
      <EmailItemsData>
        <label>
          <TextEmail>Emails Monitoring</TextEmail>
        </label>
        <ToggleButton>
          <Switch
            checked={isChecked}
            onChange={handleChangeSwitch}
          />
        </ToggleButton>
        <label>
          <EmailStatus>{isChecked ? "Enabled" : "Disabled"}</EmailStatus>
        </label>
      </EmailItemsData>
      <EmailItemsData>
        { isChecked ? 
        <label>
            <EmailTitle>Emails</EmailTitle>
        </label> : <></>
        }
      <EmailMonitoringContainer>
        <Form
          initialValues={{[formFieldArrayName]: ['']}}
          onSubmit={handleSubmit}
        >
          {/* Fragment to ensure a single child */}
          <React.Fragment>
            {isChecked?(
            <FormFieldArray
              name={formFieldArrayName}
              defaultNewItem=""
              minCount={1}
              maxCount={6}
              addButtonPosition="bottom"
              addButtonLabel="ADD EMAIL"
              canHaveDuplicates={false}
              isAddBtnDisabled={errorEmail}
              validateOnChange={true}
            >
              {({index}) => (
                <EmailInputBoxWidth key={index}>
                  <FormInputBox
                    name={`${formFieldArrayName}[${index}]`}
                    placeholder={'Email'}
                    validators={[
                      validationUtils.required,
                      validationUtils.email,
                    ]}
                    doNotRenderEmptyLabel={true}
                    inputWidth="standard"
                    onChangeValue={(e) => handleEmailChange(index, e)}
                  />
                </EmailInputBoxWidth>
              )}
            </FormFieldArray>):(<div></div>)}
            { isChecked?(
              <div>
              <InfoMessageEmail>
                  {successMsgEmail?
                  (<ValidationSuccess successMessageTID={successMsgEmail} />) : <></>
                }
              </InfoMessageEmail>
              <InfoMessageEmail>
                  {errorMsgEmail?
                  (<ValidationError errorMessageTID={errorMsgEmail} />) : <></>
                }
              </InfoMessageEmail>
              </div>):(<></>)
}
            <ComponentMoniter>
              { isChecked?(
              <Button
                style={{
                  margin: '7px',
                  backgroundColor: 'transparent',
                  border: '2px solid white',
                  color: 'white',
                  opacity: 0.8,
                }}
                className="component-moniter"
                variant="primary"
                size="large"
                type="submit"
                onClick={handleSubmit}
                disabled={errorEmail}
              >
                Save Emails
              </Button>
              ) : (<div/>)
}
              <Button
                style={{margin: '7px'}}
                className="component-moniter"
                variant="primary"
                size="large"
                onClick={handleButtonClick}
              >
                GO TO DASHBOARD
              </Button>
            </ComponentMoniter>
          </React.Fragment>
        </Form>
      </EmailMonitoringContainer>
      </EmailItemsData>
    </div>
  );
};

export default MonitoringJob;