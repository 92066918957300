import * as React from 'react';
import {
  Button,
  InfoIconWithHover,
  InputBox,
  ValidationError,
} from '@rubrik/aura-ui';
import {
  IncrementalTitleStyle,
  Timeline,
  ItemsData,
  ItemDataStyle,
  ItemKey,
  Title,
  Colon,
  ButtonStyle,
  InputBoxWidth,
  DivLeft,
  TitleStyle,
  minHeight,
  InputBoxSnapshot,
  HorizontalLine,
  SectionSla,
  DateSapcing,
  TitleStyleRetain,
  InfoIcon,
  TitleStyleIfWeeks,
  ItemKeyDatePicker,
  TitleStyleSnapshot,
  TitleStyleSnapshotTimeZone,
  DivLeftFirst,
  DivLeftMiddle,
  DivLeftLast,
  DivLeftFirstIf,
} from '../../style/emotionStyle';
import {SLA, SlaDataModel, UserModel} from '../../model/BackupJobModel';
import {MarginTop, TitleStyleIf} from 'src/style/emotionStyle';
import CreateBackupJob from './createbackupJob';
import CustomDropdown from 'src/style/DropDown';
import {
  dataSelectMonth,
  dataSelectTime,
  dataSourceSelect,
  TimeZoneData,
  WeeksSelect,
} from 'src/constant/BackupJob';
import {
  useClearFormDataSla,
  useFetchFormDataSla,
  useSaveFormDataSla,
  useUpdateDataSla,
} from '../Mutation/DataSlaMutation';
import {useQueryClient} from '@tanstack/react-query';
import { useUpdateTenantData } from '../Mutation/tenantMutation';

interface DataSLAProps {
  isUpdate?: boolean; // Optional prop to determine if we are updating or creating
  nextStep: () => void;
  onSuccess: () => void;
  onError: (error: any) => void;
  savedData?: any;
}

const initialValues: SLA = {
  id:'1',
  dailyFrequency: 1,
  dailyRetention: 1,
  dailyRetentionUnit: 'Day(s)',
  weeklyFrequency: 1,
  weeklyRetention: 1,
  weeklyFullBackupDay: '',
  weeklyRetentionUnit: 'Day(s)',
  monthlyFrequency: 1,
  monthlyRetention: 1,
  monthlyFullBackupDay: '',
  monthlyRetentionUnit: 'Day(s)',
  incrementalBackupFrequency: 1,
  incrementalBackupFrequencyUnit: 'Day(s)',
  incrementalBackupRetention: 1,
  incrementalBackupRetentionUnit: 'Day(s)',
  deltaBackupFrequency: 1,
  deltaBackupFrequencyUnit: 'Day(s)',
  deltaBackupRetention: 1,
  deltaBackupRetentionUnit: 'Day(s)',
  preferedSnapshotBeginHours: '7',
  preferedSnapshotBeginMin: '30',
  preferedSnapshotBeginUnit: 'AM',
  preferedSnapshotEndHours: '4',
  preferedSnapshotEndMin: '30',
  preferedSnapshotEndUnit: 'PM',
};

const SlaPage: React.FC<DataSLAProps> = ({
  isUpdate,
  nextStep,
  onSuccess,
  onError,
  savedData,
}) => {
  const [activeComponent, setActiveComponent] =
    React.useState<React.ReactNode | null>(null);
  const {mutate: saveFormDataSla, isLoading} = useSaveFormDataSla();
  const { mutate: updateFormData } = useUpdateDataSla();
  const [formValues, setFormValues] = React.useState<SLA>(initialValues);
  const queryClient = useQueryClient();
  const {mutate: clearFormDataSla} = useClearFormDataSla();
  const tenantBackupSlaId = queryClient.getQueryData<string>([
    'tenantBackupSlaIdData',
  ]);
  const alreadyTenantBackupSlaId = queryClient.getQueryData(['tenantBackupSlaId']) as unknown as SlaDataModel;
  const {data: savedFormData} = useFetchFormDataSla(tenantBackupSlaId || '');

  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const [apiError, setApiError] = React.useState('');
  const [timeError, setTimeError] = React.useState('');
  const isUpdateData = queryClient.getQueryData(['updateFormSla'])
  const { mutate: updateTenantOnboardingStatus } = useUpdateTenantData();

  React.useEffect(() => {
    // if (savedData && Object.keys(savedData).length) {
    //   queryClient.setQueryData(
    //     ['tenantBackupSlaId'],
    //     savedData[savedData.length - 1],
    //   );
    //   setFormValues(
    //     MapPayloadforForm(savedData[savedData.length - 1]) as unknown as any,
    //   ) as unknown as any;
    //   setIsButtonDisabled(!areAllFieldsFilled(formValues));
    // } else if (savedFormData && Object.keys(savedFormData).length) {
    //   setFormValues(
    //     MapPayloadforForm(savedFormData as unknown as any) as unknown as any,
    //   );
    //   setIsButtonDisabled(!areAllFieldsFilled(savedFormData));
    // } 
    if(alreadyTenantBackupSlaId) {
      setFormValues(
        MapPayloadforForm(alreadyTenantBackupSlaId as unknown as any) as unknown as any,
      );
    } else {
      setFormValues(initialValues);
      setIsButtonDisabled(!areAllFieldsFilled(formValues));
    }
  }, [savedData, savedFormData, initialValues, setFormValues]);

  const areAllFieldsFilled = (values: SLA): boolean => {
    // return Object.values(values).every((value) => value !== '');
    const dailyCondition = (values.dailyFrequency && Number(values.dailyFrequency)!==0) && (values.dailyRetention &&  Number(values.dailyRetention)!==0) && (values.dailyRetentionUnit && values.dailyRetentionUnit!=="")
    const weeklyCondition = values.weeklyFrequency && Number(values.weeklyFrequency)!==0 && values.weeklyFullBackupDay && values.weeklyFullBackupDay!=="" && values.weeklyRetention && Number(values.weeklyRetention)!==0 && values.weeklyRetentionUnit && values.weeklyRetentionUnit!==""
    const monthlyCondition = values.monthlyFrequency && Number(values.monthlyFrequency)!==0 && values.monthlyFullBackupDay && values.monthlyFullBackupDay!=="" && values.monthlyRetention && Number(values.monthlyRetention)!==0 && values.monthlyRetentionUnit && values.monthlyRetentionUnit!==""
    const startSnapshot = values.preferedSnapshotBeginHours && values.preferedSnapshotBeginHours!=="" && values.preferedSnapshotBeginMin  && values.preferedSnapshotBeginMin!=="" && values.preferedSnapshotBeginUnit && values.preferedSnapshotBeginUnit!=="";
    const endSnapshot = values.preferedSnapshotEndHours && values.preferedSnapshotEndHours!=="" && values.preferedSnapshotEndMin && values.preferedSnapshotEndMin!=="" && values.preferedSnapshotEndUnit && values.preferedSnapshotEndUnit!=="";
    if( (dailyCondition || weeklyCondition || monthlyCondition) && (startSnapshot && endSnapshot)){
      return true
    } else {
      return false
    }
  };

  const handleValueChangeDropdown = (key: keyof SLA, value: string): void => {
    const formValuesCopy = {...formValues, [key]: value};
    setFormValues(formValuesCopy);
    if (!isValidSnapshotWindow(formValuesCopy)) {
      setIsButtonDisabled(true);
      setTimeError(
        'The snapshot window should have a minimum difference of 3 hours between the start and end times.',
      );
    } else {
      setIsButtonDisabled(false);
      setTimeError('');
    }
    setIsButtonDisabled(!areAllFieldsFilled(formValuesCopy));
    if (!isValidSnapshotWindow(formValuesCopy)) {
      setIsButtonDisabled(true);
      setTimeError(
        'The snapshot window should have a minimum difference of 3 hours between the start and end times.',
      );
    } else {
      setIsButtonDisabled(false);
      setTimeError('');
    }
  };

  const handleValueChange = (key: keyof SLA, value: string): void => {
    // Regular expressions for validation
    const twoDigitRegex = /^\d{0,2}$/; // Matches up to 2 digits
    const unlimitedDigitsRegex = /^\d*$/; // Matches unlimited digits

    // Handling specific keys
    if (
      key === 'preferedSnapshotBeginHours' ||
      key === 'preferedSnapshotEndHours'
    ) {
      // Validate hours with two digit constraint
      if (
        twoDigitRegex.test(value) &&
        (value === '' ||
          (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 12))
      ) {
        const formValuesCopy = {...formValues, [key]: value};
        setFormValues(formValuesCopy);
        if (!isValidSnapshotWindow(formValuesCopy)) {
          setIsButtonDisabled(true);
          setTimeError(
            'The snapshot window should have a minimum difference of 3 hours between the start and end times.',
          );
        } else {
          setIsButtonDisabled(!areAllFieldsFilled(formValuesCopy));
          setTimeError('');
        }
      }
    } else if (
      key === 'preferedSnapshotBeginMin' ||
      key === 'preferedSnapshotEndMin'
    ) {
      // Validate minutes with two digit constraint
      if (
        twoDigitRegex.test(value) &&
        (value === '' ||
          (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59))
      ) {
        const formValuesCopy = {...formValues, [key]: value};
        setFormValues(formValuesCopy);
        if (!isValidSnapshotWindow(formValuesCopy)) {
          setIsButtonDisabled(true);
          setTimeError(
            'The snapshot window should have a minimum difference of 3 hours between the start and end times.',
          );
        } else {
          setIsButtonDisabled(!areAllFieldsFilled(formValuesCopy));
          setTimeError('');
        }
      }
    } else {
      // Validate other keys with unlimited digits
      if (unlimitedDigitsRegex.test(value)) {
        const formValuesCopy = {...formValues, [key]: value};
        setFormValues(formValuesCopy);
        setIsButtonDisabled(!areAllFieldsFilled(formValuesCopy));
      }
    }
  };

  function handleClick() {
    handleSubmit(formValues);
    // setActiveComponent(() => (
    //   <CreateBackupJob
    //     nextStep={nextStep}
    //     onSuccess={handleSuccess}
    //     onError={handleError}
    //   />
    // ));
  }

  const handleClear = () => {
    queryClient.setQueryData(['updateFormSla'], false);
    clearFormDataSla();
    setFormValues(initialValues);
    setIsButtonDisabled(!areAllFieldsFilled(initialValues));
  };

  const handleSuccess = () => {
    console.log('Success callback triggered.');
  };

  const handleError = (error: any) => {
    console.error('Error callback triggered:', error);
  };

  const calculateTimeDifference = (formValues: SLA) => {
    const {
      preferedSnapshotBeginHours,
      preferedSnapshotBeginMin,
      preferedSnapshotBeginUnit,
      preferedSnapshotEndHours,
      preferedSnapshotEndMin,
      preferedSnapshotEndUnit,
    } = formValues;
    if (
      preferedSnapshotBeginHours &&
      preferedSnapshotBeginMin &&
      preferedSnapshotBeginUnit &&
      preferedSnapshotEndHours &&
      preferedSnapshotEndMin &&
      preferedSnapshotEndUnit
    ) {
      const getMilitaryTime = (hours: string, minutes: string, period: string) => {
        let hoursNum = parseInt(hours, 10);
        if (period === 'PM' && hoursNum !== 12) hoursNum += 12;
        if (period === 'AM' && hoursNum === 12) hoursNum = 0;
        return hoursNum * 100 + parseInt(minutes, 10);
      };

      const beginDate = getMilitaryTime(formValues.preferedSnapshotBeginHours, formValues.preferedSnapshotBeginMin, formValues.preferedSnapshotBeginUnit);
      const endDate = getMilitaryTime(formValues.preferedSnapshotEndHours, formValues.preferedSnapshotEndMin, formValues.preferedSnapshotEndUnit);
      const diffMs = endDate - beginDate;
      return Math.abs(diffMs); // Difference in hours
    } else {
      return 300;
    }
  };

  const isValidSnapshotWindow = (values: SLA) => {
    const timeDifference = calculateTimeDifference(values);
    return timeDifference >= 3;
  };

  const handleSubmit = (values: SLA) => {
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId || "";
    if (!isValidSnapshotWindow(values)) {
      setTimeError(
        'The snapshot window should have a minimum difference of 3 hours between the start and end times.',
      );
      setIsButtonDisabled(true);
    } else {
      setTimeError('');
      const payloadData = MapValueforPayload(values);
      if(alreadyTenantBackupSlaId){
        updateFormData({slaId:alreadyTenantBackupSlaId ? alreadyTenantBackupSlaId.id : "" as unknown as any, formData:payloadData as unknown as any}, {
          onSuccess: (data: any) => {
            queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_SLA");
            updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_SLA"})
            queryClient.setQueryData(['tenantBackupSlaId'], data);
            queryClient.setQueryData(['updateFormSla'], true);
            nextStep();
            onSuccess(); // Call the onSuccess callback prop
            setApiError("")
          },
          onError: (error: any) => {
            onError(error); // Call the onError callback prop
            console.log(error.data.detail[0].msg)
            setApiError(error.data.detail[0].msg.toString())
          }
        });
      }
      else {
      saveFormDataSla(payloadData as unknown as SLA, {
        onSuccess: (data) => {
          queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_SLA");
          updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_SLA"})
          queryClient.setQueryData(['tenantBackupSlaId'], data);
          queryClient.setQueryData(['updateFormSla'], true);
          nextStep();
          onSuccess();
          setApiError('');
        },
        onError: (error: any) => {
          onError(error);
          setApiError(error.data.detail[0].msg.toString())
        },
      });
    }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return ( 
  //   activeComponent ? (
  //   <CreateBackupJob
  //     nextStep={nextStep}
  //     onSuccess={handleSuccess}
  //     onError={handleError}
  //   />
  // ) : (
    <div>
      <SectionSla>
        <Title>Frequency and Retention for Full Backup</Title>
        <ItemsData>
          <DivLeftFirst>
            <ItemKey>
              <TitleStyle>
                <div>Every</div>
              </TitleStyle>
              <InputBoxWidth>
                <InputBox
                  value={formValues.dailyFrequency}
                  inputRowCss={minHeight}
                  onChangeValue={(e) => handleValueChange('dailyFrequency', e)}
                />
              </InputBoxWidth>
              <TitleStyle>
                <div>Day(s)</div>
              </TitleStyle>
            </ItemKey>
          </DivLeftFirst>
          <DivLeftLast>
            <ItemKey>
              <TitleStyleRetain>retain for</TitleStyleRetain>
              <InputBoxWidth>
                <InputBox
                  value={formValues.dailyRetention}
                  onChangeValue={(e) => handleValueChange('dailyRetention', e)}
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) => option.value === formValues.dailyRetentionUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'dailyRetentionUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftLast>
        </ItemsData>

        <ItemsData>
          <DivLeftFirst>
            <ItemKey>
              <TitleStyle>
                <div>Every</div>
              </TitleStyle>
              <InputBoxWidth>
                <InputBox
                  value={formValues.weeklyFrequency}
                  inputRowCss={minHeight}
                  onChangeValue={(e) => handleValueChange('weeklyFrequency', e)}
                />
              </InputBoxWidth>
              <TitleStyle>Week(s)</TitleStyle>
              {formValues.weeklyFrequency ? (
                <Timeline>
                  <TitleStyleIf> on </TitleStyleIf>
                  <CustomDropdown
                    options={WeeksSelect}
                    width="123px"
                    height="40px"
                    labels="Select Unit"
                    value={WeeksSelect.find(
                      (option) =>
                        option.value === formValues.weeklyFullBackupDay,
                    )}
                    onChange={(selectedOption) =>
                      handleValueChangeDropdown(
                        'weeklyFullBackupDay',
                        selectedOption ? selectedOption.value : '',
                      )
                    }
                  />
                </Timeline>
              ) : (
                <></>
              )}
            </ItemKey>
          </DivLeftFirst>
          <DivLeftLast>
            <ItemKey>
              <TitleStyleRetain>retain for</TitleStyleRetain>
              <InputBoxWidth>
                <InputBox
                  value={formValues.weeklyRetention}
                  onChangeValue={(e) => handleValueChange('weeklyRetention', e)}
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) => option.value === formValues.weeklyRetentionUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'weeklyRetentionUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftLast>
        </ItemsData>

        <ItemsData>
          <DivLeftFirst>
            <ItemKey>
              <TitleStyle>
                <div>Every</div>
              </TitleStyle>
              <InputBoxWidth>
                <InputBox
                  value={formValues.monthlyFrequency}
                  inputRowCss={minHeight}
                  onChangeValue={(e) =>
                    handleValueChange('monthlyFrequency', e)
                  }
                />
              </InputBoxWidth>
              <TitleStyle>
                <div>Month(s)</div>
              </TitleStyle>
              {formValues.monthlyFrequency ? (
                <Timeline>
                  <TitleStyleIf> on </TitleStyleIf>
                  <CustomDropdown
                    options={dataSelectMonth}
                    width="123px"
                    height="40px"
                    labels="Select Unit"
                    value={dataSelectMonth.find(
                      (option) =>
                        option.value === formValues.monthlyFullBackupDay,
                    )}
                    onChange={(selectedOption) =>
                      handleValueChangeDropdown(
                        'monthlyFullBackupDay',
                        selectedOption ? selectedOption.value : '',
                      )
                    }
                  />
                </Timeline>
              ) : (
                <></>
              )}
            </ItemKey>
          </DivLeftFirst>
          <DivLeftLast>
            <ItemKey>
              <TitleStyleRetain>retain for</TitleStyleRetain>
              <InputBoxWidth>
                <InputBox
                  value={formValues.monthlyRetention}
                  onChangeValue={(e) =>
                    handleValueChange('monthlyRetention', e)
                  }
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) => option.value === formValues.monthlyRetentionUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'monthlyRetentionUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftLast>
        </ItemsData>
      </SectionSla>
      <HorizontalLine />
      <SectionSla>
        <Title>Incremental and Delta Backup</Title>
        <ItemsData>
          <DivLeftFirstIf>
            <ItemKey>
              <IncrementalTitleStyle>
                Take Incremental backup every
              </IncrementalTitleStyle>
              <InputBoxWidth>
                <InputBox
                  value={formValues.incrementalBackupFrequency}
                  onChangeValue={(e) =>
                    handleValueChange('incrementalBackupFrequency', e)
                  }
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) =>
                    option.value === formValues.incrementalBackupFrequencyUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'incrementalBackupFrequencyUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftFirstIf>
          <DivLeftMiddle>
            <ItemKey>
              <TitleStyleRetain>retain for</TitleStyleRetain>
              <InputBoxWidth>
                <InputBox
                  value={formValues.incrementalBackupRetention}
                  onChangeValue={(e) =>
                    handleValueChange('incrementalBackupRetention', e)
                  }
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) =>
                    option.value === formValues.incrementalBackupRetentionUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'incrementalBackupRetentionUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftMiddle>
        </ItemsData>
        <ItemsData>
          <DivLeftFirstIf>
            <ItemKey>
              <IncrementalTitleStyle>
                Take Delta backup every
              </IncrementalTitleStyle>
              <InputBoxWidth>
                <InputBox
                  value={formValues.deltaBackupFrequency}
                  onChangeValue={(e) =>
                    handleValueChange('deltaBackupFrequency', e)
                  }
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) =>
                    option.value === formValues.deltaBackupFrequencyUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'deltaBackupFrequencyUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftFirstIf>
          <DivLeftMiddle>
            <ItemKey>
              <TitleStyleRetain>retain for</TitleStyleRetain>
              <InputBoxWidth>
                <InputBox
                  value={formValues.deltaBackupRetention}
                  onChangeValue={(e) =>
                    handleValueChange('deltaBackupRetention', e)
                  }
                />
              </InputBoxWidth>
              <CustomDropdown
                options={dataSourceSelect}
                width="113px"
                height="40px"
                labels="Select Unit"
                value={dataSourceSelect.find(
                  (option) =>
                    option.value === formValues.deltaBackupRetentionUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'deltaBackupRetentionUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKey>
          </DivLeftMiddle>
        </ItemsData>
      </SectionSla>
      <HorizontalLine />
      <SectionSla>
        <Title>Snapshot Window</Title>
        <ItemsData>
          <DivLeft>
            <ItemKeyDatePicker>
              <TitleStyleSnapshot>
                <div>From</div>
              </TitleStyleSnapshot>
              <InputBoxSnapshot>
                <InputBox
                  placeholder={'hh'}
                  inputRowCss={minHeight}
                  value={formValues.preferedSnapshotBeginHours}
                  onChangeValue={(e) => {
                    handleValueChange('preferedSnapshotBeginHours', e);
                  }}
                />
              </InputBoxSnapshot>
              <Colon>:</Colon>
              <InputBoxSnapshot>
                <InputBox
                  placeholder={'mm'}
                  inputRowCss={minHeight}
                  value={formValues.preferedSnapshotBeginMin}
                  onChangeValue={(e) => {
                    handleValueChange('preferedSnapshotBeginMin', e);
                  }}
                />
              </InputBoxSnapshot>
              <Colon />
              <CustomDropdown
                options={dataSelectTime}
                width="65px"
                height="40px"
                labels=""
                value={dataSelectTime.find(
                  (option) =>
                    option.value === formValues.preferedSnapshotBeginUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'preferedSnapshotBeginUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
              <DateSapcing />
              <TitleStyle>
                <div>To</div>
              </TitleStyle>
              <InputBoxSnapshot>
                <InputBox
                  placeholder={'hh'}
                  inputRowCss={minHeight}
                  value={formValues.preferedSnapshotEndHours}
                  onChangeValue={(e) => {
                    handleValueChange('preferedSnapshotEndHours', e);
                  }}
                />
              </InputBoxSnapshot>
              <Colon>:</Colon>
              <InputBoxSnapshot>
                <InputBox
                  placeholder={'mm'}
                  inputRowCss={minHeight}
                  value={formValues.preferedSnapshotEndMin}
                  onChangeValue={(e) => {
                    handleValueChange('preferedSnapshotEndMin', e);
                  }}
                />
              </InputBoxSnapshot>
              <Colon />
              <CustomDropdown
                options={dataSelectTime}
                width="65px"
                height="40px"
                labels=""
                value={dataSelectTime.find(
                  (option) =>
                    option.value === formValues.preferedSnapshotEndUnit,
                )}
                onChange={(selectedOption) =>
                  handleValueChangeDropdown(
                    'preferedSnapshotEndUnit',
                    selectedOption ? selectedOption.value : '',
                  )
                }
              />
            </ItemKeyDatePicker>
          </DivLeft>
        </ItemsData>
        {timeError ? (
          <ValidationError errorMessageTID={timeError}></ValidationError>
        ) : (
          <></>
        )}
        <ItemsData>
          <DivLeft>
            <ItemKey>
              <TitleStyleSnapshotTimeZone>
                <div>Timezone</div>
              </TitleStyleSnapshotTimeZone>
              <CustomDropdown
                options={TimeZoneData}
                width="210px"
                height="40px"
                labels=""
                value={TimeZoneData[0]}
              />
              <InfoIcon>
                <InfoIconWithHover title="Please note that the specified time is in IST format" />
              </InfoIcon>
            </ItemKey>
          </DivLeft>
        </ItemsData>
      </SectionSla>
      {apiError ? (
        <ValidationError errorMessageTID={apiError}></ValidationError>
      ) : (
        <></>
      )}
      <ButtonStyle>
        <Button
          style={{
            margin: '7px',
            backgroundColor: 'transparent',
            border: '2px solid white',
            color: 'white',
            opacity: 0.8,
          }}
          variant="primary"
          size="large"
          onClick={handleClear}
        >
          Reset
        </Button>
        <Button
          style={{margin: '7px'}}
          variant="primary"
          size="large"
          onClick={handleClick}
          disabled={isButtonDisabled}
        >
          {alreadyTenantBackupSlaId ? 'Update SLA' : 'Create SLA'}
          {/* {isUpdate ? 'Update SLA' : 'Create SLA'} */}
        </Button>
      </ButtonStyle>
    </div>
  // );
  )
};

function MapValueforPayload(dataSlaPayload: SLA) {
  return {
    dailyFrequency: dataSlaPayload.dailyFrequency,
    dailyRetention: dataSlaPayload.dailyRetention,
    dailyRetentionUnit: dataSlaPayload.dailyRetentionUnit
      .replace(/[^A-Za-z]/g, '')
      .toUpperCase(),
    monthlyFrequency: dataSlaPayload.monthlyFrequency,
    monthlyRetention: dataSlaPayload.monthlyRetention,
    monthlyRetentionUnit: dataSlaPayload.monthlyRetentionUnit
      .replace(/[^A-Za-z]/g, '')
      .toUpperCase(),
    monthlyFullBackupDay: dataSlaPayload.monthlyFullBackupDay.toUpperCase(),
    weeklyFrequency: dataSlaPayload.weeklyFrequency,
    weeklyRetention: dataSlaPayload.weeklyRetention,
    weeklyRetentionUnit: dataSlaPayload.weeklyRetentionUnit
      .replace(/[^A-Za-z]/g, '')
      .toUpperCase(),
    weeklyFullBackupDay: dataSlaPayload.weeklyFullBackupDay.toUpperCase(),
    incrementalBackupFrequency: dataSlaPayload.incrementalBackupFrequency,
    incrementalBackupFrequencyUnit:
      dataSlaPayload.incrementalBackupFrequencyUnit
        .replace(/[^A-Za-z]/g, '')
        .toUpperCase(),
    incrementalBackupRetention: dataSlaPayload.incrementalBackupRetention,
    incrementalBackupRetentionUnit:
      dataSlaPayload.incrementalBackupRetentionUnit
        .replace(/[^A-Za-z]/g, '')
        .toUpperCase(),
    deltaBackupFrequency: dataSlaPayload.deltaBackupFrequency,
    deltaBackupFrequencyUnit: dataSlaPayload.deltaBackupFrequencyUnit
      .replace(/[^A-Za-z]/g, '')
      .toUpperCase(),
    deltaBackupRetention: dataSlaPayload.deltaBackupRetention,
    deltaBackupRetentionUnit: dataSlaPayload.deltaBackupRetentionUnit
      .replace(/[^A-Za-z]/g, '')
      .toUpperCase(),
    preferedSnapshotTimeRangeBegin:convertTimeRange(dataSlaPayload.preferedSnapshotBeginHours,dataSlaPayload.preferedSnapshotBeginMin,dataSlaPayload.preferedSnapshotBeginUnit),
    preferedSnapshotTimeRangeEnd:convertTimeRange(dataSlaPayload.preferedSnapshotEndHours, dataSlaPayload.preferedSnapshotEndMin,dataSlaPayload.preferedSnapshotEndUnit),
    status: 'ACTIVE',
    name: 'Primary SLA'
  };
}

const convertTimeRange = (preferedSnapshotHours: any, preferedSnapshotMin:string, preferedSnapshotUnit:string) => {
  if (preferedSnapshotHours === '12') {
    preferedSnapshotHours = '00';
  }
  if (preferedSnapshotUnit === 'PM') {
    preferedSnapshotHours = parseInt(preferedSnapshotHours, 10) + 12;
  } 
  return `${preferedSnapshotHours}:${preferedSnapshotMin}`
}

const convertTo12HourFormat = (time24: string): any => {
  const [hour, minute] = time24.split(':').map(Number);

  let period = 'AM';
  let hour12 = hour;

  if (hour >= 12) {
    period = 'PM';
    hour12 = hour > 12 ? hour - 12 : hour; 
  } 

  if (hour12 === 0) {
    hour12 = 12;
  }

  const minuteStr = minute.toString().padStart(2, '0');
  return {
    preferedSnapshotHours: hour12.toString(),
    preferedSnapshotMin: minuteStr,
    preferedSnapshotBeginUnit: period
  }
};

const dropdownMapping = (dayValue: string) => {
  return WeeksSelect.find((day) => day.value.toUpperCase() === dayValue)?.value;
};

const convertToDropDownValue = (str: string) => {
  if (!str) return '';
  const singularForm = str.slice(0, -1);
  const capitalizedSingularForm =
    singularForm.charAt(0).toUpperCase() + singularForm.slice(1).toLowerCase();
  return `${capitalizedSingularForm}(s)`;
};

function MapPayloadforForm(dataSaved: any) {
  const beginTime = convertTo12HourFormat(dataSaved.preferedSnapshotTimeRangeBegin)
  const endTime = convertTo12HourFormat(dataSaved.preferedSnapshotTimeRangeEnd)
  return {
    dailyFrequency: dataSaved.dailyFrequency,
    dailyRetention: dataSaved.dailyRetention,
    dailyRetentionUnit: convertToDropDownValue(dataSaved.dailyRetentionUnit),
    monthlyFrequency: dataSaved.monthlyFrequency,
    monthlyRetention: dataSaved.monthlyRetention,
    monthlyRetentionUnit: convertToDropDownValue(
      dataSaved.monthlyRetentionUnit,
    ),
    monthlyFullBackupDay:
      dataSaved.monthlyFullBackupDay.charAt(0).toUpperCase() +
      dataSaved.monthlyFullBackupDay.slice(1).toLowerCase(),
    weeklyFrequency: dataSaved.weeklyFrequency,
    weeklyRetention: dataSaved.weeklyRetention,
    weeklyRetentionUnit: convertToDropDownValue(dataSaved.weeklyRetentionUnit),
    weeklyFullBackupDay: dropdownMapping(dataSaved.weeklyFullBackupDay),
    incrementalBackupFrequency: dataSaved.incrementalBackupFrequency,
    incrementalBackupFrequencyUnit: convertToDropDownValue(
      dataSaved.incrementalBackupFrequencyUnit,
    ),
    incrementalBackupRetention: dataSaved.incrementalBackupRetention,
    incrementalBackupRetentionUnit: convertToDropDownValue(
      dataSaved.incrementalBackupRetentionUnit,
    ),
    deltaBackupFrequency: dataSaved.deltaBackupFrequency,
    deltaBackupFrequencyUnit: convertToDropDownValue(
      dataSaved.deltaBackupFrequencyUnit,
    ),
    deltaBackupRetention: dataSaved.deltaBackupRetention,
    deltaBackupRetentionUnit: convertToDropDownValue(
      dataSaved.deltaBackupRetentionUnit,
    ),
    preferedSnapshotBeginHours: beginTime.preferedSnapshotHours,
    preferedSnapshotBeginMin: beginTime.preferedSnapshotMin,
    preferedSnapshotBeginUnit: beginTime.preferedSnapshotBeginUnit,
    preferedSnapshotEndHours: endTime.preferedSnapshotHours,
    preferedSnapshotEndMin: endTime.preferedSnapshotMin,
    preferedSnapshotEndUnit: endTime.preferedSnapshotBeginUnit,
    status: 'ACTIVE',
  };
}

export default SlaPage;