import {Button, Card, ConfirmationBody, ConfirmationDialog, DeprecatedWizardDefaultFooter, IconBase, SeverityStatusEnum} from '@rubrik/aura-ui';
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  BackupDataItemsDataSource,
  ButtonChart,
  ButtonSource,
  ChartData,
  ChartNoData,
  Circle,
  Container,
  DashboardDetailSection,
  DashboardDetailSectionBottom,
  DashboardDetailSectionBottomLeft,
  DashboardDetailSectionBottomRight,
  DashboardDetailSectionButtomleftTextTitle,
  DashboardDetailSectionButtomRightTextFirst,
  DashboardDetailSectionButtomRightTextRow,
  DashboardDetailSectionButtomRightTextSubTitle,
  DashboardDetailSectionButtomRightTextSubTitleComingSoon,
  DashboardDetailSectionButtomRightTextTitle,
  DashboardDetailSectionTop,
  DashboardDetailSectionTopHeading,
  DashboardDetailSectionTopRight,
  DashboardDetailSectionTopSubHeading,
  DashboardPageLayout,
  DonutChartNoData,
  GridContainer,
  GridContainerRow,
  HeaderText,
  Heading,
  IconContainer,
  ImageMargin,
  ItemKey,
  Label,
  LabelsContainer,
  LabelText,
  LabelTextContainer,
  LabelTextNumber,
  LeftChartSection,
  LegendBox,
  NoteContainer,
  OverlayButton,
  RightChartSection,
  Title,
  TitleData,
  VerticalDividerDashboard,
} from 'src/style/emotionStyle';
import {
  InfoOutlineIcon,
  PinOutlineIcon,
  WhiteList2Icon,
} from '@rubrik/aura-ui/icons';
import  {BackupJobTable} from './BackupJob/BackupJobTable';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { BackupJobInstanceApi, BackupJobInstances, DashboardData, DashboardDataDaily, ObjectBreakup, UserModel } from 'src/model/BackupJobModel';
import ApiServiceInstance from 'src/service/service';
import { useFetchAllBackupJob } from './Mutation/BackupJobMutation';
import DataSource from './BackupJob/CreateDataSource';
import CreateBackupLanding from './BackupJob/CreateBackupLanding';
import { TID } from '@rubrik/aura-ui/generated/tid.constant.generated';
import { FormattedMessage } from 'react-intl';
import { useFetchTenant } from './Mutation/tenantMutation';
import NewUserFlowPage from './NewUserFlowPage';
import { useFetchAllBackupSource } from './Mutation/DataSourceMutation';
import { useFetchDashboardCumulative, useFetchDashboardDaily } from './Mutation/DashboardMutation';
import { useFetchAllBackupJobInstance } from './Mutation/BackupJobInstanceMutation';

type DonutChartProps = {
  tableData: DashboardData;
};

const formatNumber = (num: number): string => {
  if (num >= 1000000 || num <= -1000000) {
    return (num / 1000000).toFixed(1) + 'M';  // Format as M with one decimal place
  } else if (num >= 1000 || num <= -1000) {
    return (num / 1000).toFixed(1) + 'K';     // Format as K with one decimal place
  }
  return num.toString();
};

const LabelMapping = (label : string) => {
  if (label === 'salesInvoiced') {
    return 'Invoiced Sales Objects'
  } if (label === 'customer') {
    return 'Customer Objects'
  } if (label === 'subsidiary'){
    return 'Subsidiaries'
  } if (label === 'others'){
    return 'Others'
  } if (label === 'files'){
    return 'Files'
  }
  const labelUpdate = label
  .replace(/([A-Z])/g, ' $1')  // Insert a space before each capital letter
  .replace(/_/g, ' ')          // Replace underscores with spaces
  .replace(/^./, (char: any) => char.toUpperCase()) // Capitalize the first character

  return labelUpdate + "s"
}

const DonutChart: React.FC<DonutChartProps> = ({tableData}) => {
  let dataChart = Object.entries(tableData.objectBreakup).map(([key, value]) => ({
    name: key,
    y: value
  }));

  let subsidiaryData = dataChart.filter(category => category.name=== 'subsidiary')

  dataChart = dataChart.filter(category => category.name!== 'subsidiary')

  const totalData = Number(tableData.total) - Number(subsidiaryData[0].y)

  const colors = ['#A63C97', '#F7B493', '#61EEBB', '#0086C0', '#FFA5B2', '#4DD2D2', '#A580FF']; 

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: null,
      width: 190,
      height: 190,
    },
    title: {
      text: '',
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemStyle: {
        color: '#FFFFFF', // Set the legend text color to white
        fontSize: '16px',
      },
    },
    credits: {enabled: false},
    plotOptions: {
      pie: {
        innerSize: '90%', // This creates the donut effect
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Object',
        data: dataChart.map((item, index) => ({
          ...item,
          color: colors[index % colors.length], // Assign a color from the array
        }))     
      },
    ],
  };

  return (
  <div>
    <ChartData>
      <div
        style={{
          position: 'relative',
          // display: 'inline-block',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '40px',
        }}
      >
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div>
          <div
            style={{
              position: 'absolute',
              top: '39%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '20px',
            }}
          >
            {formatNumber(totalData)}
          </div>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '14px',
              opacity:0.7
            }}
          >
            Object in total
          </div>
        </div>
      </div>
      <LabelsContainer>
        {dataChart.map((point, index) => (
          point.y!==0 ? 
          <Label key={index}>
            <LegendBox>
              <LabelTextContainer>
                <LabelTextNumber>{formatNumber(point.y)} </LabelTextNumber>{' '}
                <LabelText>{LabelMapping(point.name)} </LabelText>
                {/* <IconContainer className="icon">
                  <PinOutlineIcon color="custom" customColor="#4dd2d2" />
                </IconContainer> */}
              </LabelTextContainer>
            </LegendBox>
          </Label> : <></>
        ))}
      </LabelsContainer>
    </ChartData>
    <div>
    <NoteContainer>
    Number of {LabelMapping(subsidiaryData[0].name)} backed up : <b>{formatNumber(subsidiaryData[0].y)} </b>
    </NoteContainer>
    </div>
    </div>
  );
};

type StackedChartProps = {
  dashboardDaily: DashboardDataDaily;
};

const StackedBarChart: React.FC<StackedChartProps> = ({dashboardDaily}) => {
  if (! (dashboardDaily && dashboardDaily.body.data)) return <div>Loading...</div>;

  // Extract dates and object categories from the dashboardDaily data
  const dates = Object.keys(dashboardDaily.body.data);
  let categories = Object.keys(dashboardDaily.body.data[dates[0]].objectBreakup);

  categories = categories.filter(category => category !== 'subsidiary');

  // Transform data into series format required by Highcharts
  let seriesData = categories.map(category => ({
    name: LabelMapping(category), // Capitalize category names
    data: dates.map(date => dashboardDaily.body.data[date].objectBreakup[category as keyof ObjectBreakup]),
  }));

  const options = {
    chart: {
      type: 'column',
      backgroundColor: null,
      width: 1100,
      height: 400,
    },
    title: {
      text: '',
      style: {
        color: '#FFFFFF', // Set the title color to white
      },
    },
    colors: ['#A63C97', '#F7B493', '#61EEBB', '#0086C0', '#FFA5B2', '#4DD2D2', '#A580FF'],
    xAxis: {
      categories: dates,
      gridLineWidth: 0, // Remove background grid lines
      labels: {
        style: {
          color: '#FFFFFF', // Set the x-axis labels color to white
          fontSize: '14px',
          opacity: 0.8,
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Object',
        style: {
          color: '#FFFFFF', // Set the x-axis labels color to white
          fontSize: '16px',
          opacity: 1,
        },
      },
      labels: {
        style: {
          color: '#FFFFFF', // Set the y-axis labels color to white
          fontSize: '14px',
          opacity: 0.8,
        },
      },
      gridLineWidth: 0.3, // Remove background grid lines
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemStyle: {
        color: '#FFFFFF', // Set the legend text color to white
        fontSize: '16px',
        opacity: 0.8,
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderWidth: 0, // Remove border from stacked bars
        pointPadding: 0.28, // Reduced padding between bars
        groupPadding: 0.28,
      },
    },
    credits: { enabled: false },
    series: seriesData,
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', marginTop: '40px' }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const ExportComponent = () => {
  return (
    <BackupJobTable/>
  );
};

type CurveChartProps = {
  onButtonClick: () => void;
  successData: any[];
  failureData: any[];
};

const getDailyCounts = (backupJobInstances:Array<BackupJobInstances>) => {
  const counts = Array(30).fill(0);
  const today = new Date();

  backupJobInstances.forEach((job) => {
    const jobDate = new Date(job.executeOn);
    const diffDays =
      Math.floor((today.getTime() - jobDate.getTime()) / (1000 * 60 * 60 * 24));
    if (diffDays >= 0 && diffDays < 30) {
      counts[29 - diffDays]++;
    }
  });
  return counts;
};

const CurveChart: React.FC<CurveChartProps> = ({ onButtonClick, successData, failureData }) => {
  const [activeComponent, setActiveComponent] =
    React.useState<React.ReactNode | null>(null);
    
  const handleButtonClick = () => {
    setActiveComponent(() => <BackupJobTable />);
  };
  const dataChart = [
    {name: 'Subsidiaries', y: 10},
    {name: 'Users', y: 20},
    {name: 'PO Orders', y: 15},
    {name: 'Subsidiaries', y: 20},
    {name: 'Accounts', y: 15},
    {name: 'Other', y: 20},
  ];
  const options = {
    chart: {
      type: 'areaspline',
      backgroundColor: null,
      width: 1100,
      height: 250,
    },
    title: {
      text: 'Backup job run for last 30 days',
      align: 'left',
      style: {
        color: '#FFFFFF', // Set the x-axis labels color to white
        fontSize: '20px',
        opacity: 0.7,
      },
    },
    colors: ['#4DD2D2', '#FF6975'],
    xAxis: {
      gridLineWidth: 0, // Remove background grid lines
      visible: false,
    },
    yAxis: {
      gridLineWidth: 0, // Remove background grid lines
      visible: false,
    },
    credits: {enabled: false},
    plotOptions: {
      areaspline: {
        stacking: 'normal',
        lineWidth: 5, // Increase line width
        marker: {
          enabled: false, // Disable data points (entry dots)
        },
      },
      area: {
        fillOpacity: 0.3, // Set the opacity for the filled area
      },
    },
    series: [
      {
        name: 'Successful Backup Job',
        data:  successData,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(77, 210, 210, 0.3)'],
            [1, 'rgba(77, 210, 210, 0)']
          ]
        },
      },
      {
        name: 'Failed Backup Job',
        data: failureData,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(255, 105, 117, 0.3)'],
            [1, 'rgba(255, 105, 117, 0)']
          ]
        },
      },
    ],
    legend: {
      align: 'right',
      verticalAlign: 'bottom',
      layout: 'vertical',
      itemStyle: {
        color: '#FFFFFF', // Set the legend text color to white
        fontSize: '16px', // Optionally set the font size
        opacity:0.8,
      },
    },
  };

  return (
    <ChartData>
      
      <OverlayButton onClick={onButtonClick}><b>ALL BACKUPS</b></OverlayButton>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          marginTop: '30px',
        }}
      >
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      {/* <LabelsContainer>
        {dataChart.map((point, index) => (
          <Label key={index}>
            <LegendBox>
              <LabelTextContainer>
                <LabelTextNumber>{point.y} </LabelTextNumber> <LabelText>{point.name}{' '}</LabelText>
                <IconContainer className="icon">
                  <PinOutlineIcon color='custom' customColor="#4dd2d2"/>
                </IconContainer>
              </LabelTextContainer>
            </LegendBox>
          </Label>
        ))}
      </LabelsContainer> */}
    </ChartData>
  );
};

const DashboardPage:React.FC = () => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const pageSize = 50;
  const { data: dashboardAll } = useFetchDashboardCumulative();
  const { data: dashboardDailyData } = useFetchDashboardDaily() as unknown as any;
  const queryClient = useQueryClient();
  queryClient.setQueryData(['updateFormSource'], false);
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;
  const { data: backupDataType } = useFetchAllBackupJob();
  const { data: tenantData } = useFetchTenant();
  const { data: sourceData } = useFetchAllBackupSource(0,2);
  const [activeComponent, setActiveComponent] =
  React.useState<React.ReactNode | null>(null);
  const { data: backupJobAllSuccess, isLoading: isLoadingBackupJobAllSuccess } = useFetchAllBackupJobInstance(currentPage, pageSize, undefined, "COMPLETED") as unknown as any;
  const { data: backupJobAllFailure, isLoading: isLoadingBackupJobAllFailure } = useFetchAllBackupJobInstance(currentPage, pageSize, undefined, "FAILED") as unknown as any;
  const [chartData, setChartData] = React.useState({success: [], failure: []});

  if(queryClient.getQueryData(['tenantOnboardingStatus']) === undefined){
    queryClient.setQueryData(['tenantOnboardingStatus'], tenantData?.onboardingStatus);
  }

  const updateCharts = React.useCallback(() => {
    const last30DaysDate = new Date();
    last30DaysDate.setDate(last30DaysDate.getDate() - 30);

    const filteredSuccessData = backupJobAllSuccess?.backupJobInstances?.filter((job: any) =>
      new Date(job.executeOn) >= last30DaysDate) || [];

    const filteredFailureData = backupJobAllFailure?.backupJobInstances?.filter((job: any) =>
      new Date(job.executeOn) >= last30DaysDate) || [];

    const successCounts = getDailyCounts(filteredSuccessData);
    const failureCounts = getDailyCounts(filteredFailureData);

    setChartData({ success: successCounts as unknown as any, failure: failureCounts as unknown as any });
  }, [backupJobAllSuccess, backupJobAllFailure]);

  React.useEffect(() => {
    if (!isLoadingBackupJobAllSuccess && !isLoadingBackupJobAllFailure) {
      updateCharts();
    }
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateCharts();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [isLoadingBackupJobAllSuccess, isLoadingBackupJobAllFailure, updateCharts]);
  
  const handleClick = () => {
    setActiveComponent(() => <CreateBackupLanding />
    );
  };

  const [showTemplate, setShowTemplate] = React.useState(false);

  const handleButtonClick = () => {
    setShowTemplate(true);
    queryClient.setQueryData(['currentTab'], 4);
  };

  if (showTemplate) {
    return <BackupJobTable
  />
  }
  // render() {
    return (
      <div> {
        (tenantData && tenantData.onboardingStatus===null) ? <NewUserFlowPage/> : 
        // <div>{
        //   (tenantData && tenantData.onboardingStatus === 'ADDED_MONITORING' || queryClient.getQueryData(['tenantOnboardingStatus']) === 'ADDED_MONITORING') ? 
      <DashboardPageLayout>
        <LeftChartSection>
          <Card
            hasBorder={true}
            style={{
              position: 'relative',
              height: '330px',
              width: '426px',
              margin: '80px 12px 12px 12px',
              padding: '14px',
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            {
              dashboardAll?.total !== 0 ? 
            <DonutChart tableData={dashboardAll ? dashboardAll : {objectBreakup:{"account": 0,"contact": 0,"customer": 0,"salesInvoiced": 0,"subsidiary": 0,"transaction": 0,"others": 0,"files": 0}, total: 0}}/>
            : <DonutChartNoData>No data Available</DonutChartNoData>}
          </Card>
          { sourceData?.tenantBackupSources.length ? 
          <Card
            hasBorder={true}
            style={{
              position: 'relative',
              height: '200px',
              width: '426px',
              margin: '80px 12px 12px 12px',
            }}
          >
            <DashboardDetailSection>
              <DashboardDetailSectionTop>
                <ImageMargin>
                <img
                  alt=""
                  src="/oracle_netsuite.svg"
                  width={64}
                  height={24}
                />
                </ImageMargin>
                <DashboardDetailSectionTopRight>
                  <DashboardDetailSectionTopSubHeading>{sourceData?.tenantBackupSources[0].environment} - Sandbox</DashboardDetailSectionTopSubHeading>
                  <DashboardDetailSectionTopHeading>
                    Rubrik Inc <InfoOutlineIcon />
                  </DashboardDetailSectionTopHeading>
                </DashboardDetailSectionTopRight>
              </DashboardDetailSectionTop>
              <DashboardDetailSectionBottom>
                <DashboardDetailSectionBottomLeft>
                  <DashboardDetailSectionButtomRightTextFirst>
                    <DashboardDetailSectionButtomRightTextRow>
                        <b>Backup Jobs</b> (Past 24 hrs)
                    </DashboardDetailSectionButtomRightTextRow>
                    {/* <DashboardDetailSectionButtomRightTextRow>
                      <Circle />
                      <DashboardDetailSectionButtomleftTextTitle>
                        4,300
                      </DashboardDetailSectionButtomleftTextTitle>
                      <DashboardDetailSectionButtomRightTextSubTitle>
                        (all) succeed
                      </DashboardDetailSectionButtomRightTextSubTitle>
                    </DashboardDetailSectionButtomRightTextRow> */}
                    <DashboardDetailSectionButtomRightTextRow>
                      <DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                        Coming Soon
                      </DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                    </DashboardDetailSectionButtomRightTextRow>
                  </DashboardDetailSectionButtomRightTextFirst>
                </DashboardDetailSectionBottomLeft>
                <VerticalDividerDashboard />
                <DashboardDetailSectionBottomLeft>
                  <DashboardDetailSectionButtomRightTextFirst>
                    <DashboardDetailSectionButtomRightTextRow>
                        <b>Last Object Backup</b>
                    </DashboardDetailSectionButtomRightTextRow>
                    {/* <DashboardDetailSectionButtomRightTextRow>
                      <Circle />
                      <DashboardDetailSectionButtomleftTextTitle>
                        4,300
                      </DashboardDetailSectionButtomleftTextTitle>
                      <DashboardDetailSectionButtomRightTextSubTitle>
                        (all) succeed
                      </DashboardDetailSectionButtomRightTextSubTitle>
                    </DashboardDetailSectionButtomRightTextRow> */}
                    <DashboardDetailSectionButtomRightTextRow>
                      <DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                        Coming Soon
                      </DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                    </DashboardDetailSectionButtomRightTextRow>
                  </DashboardDetailSectionButtomRightTextFirst>
                </DashboardDetailSectionBottomLeft>
              </DashboardDetailSectionBottom>
            </DashboardDetailSection>
          </Card> : <></>}
          { (sourceData?.tenantBackupSources.length && sourceData?.tenantBackupSources.length ===2) ? 
          <Card
          hasBorder={true}
          style={{
            position: 'relative',
            height: '200px',
            width: '426px',
            margin: '80px 12px 12px 12px',
          }}
        >
          <DashboardDetailSection>
            <DashboardDetailSectionTop>
              <ImageMargin>
              <img
                alt=""
                src="/oracle_netsuite.svg"
                width={64}
                height={24}
              />
              </ImageMargin>
              <DashboardDetailSectionTopRight>
                <DashboardDetailSectionTopSubHeading>{sourceData?.tenantBackupSources[1].environment} - Sandbox</DashboardDetailSectionTopSubHeading>
                <DashboardDetailSectionTopHeading>
                  Rubrik Inc <InfoOutlineIcon />
                </DashboardDetailSectionTopHeading>
              </DashboardDetailSectionTopRight>
            </DashboardDetailSectionTop>
            <DashboardDetailSectionBottom>
              <DashboardDetailSectionBottomLeft>
                <DashboardDetailSectionButtomRightTextFirst>
                  <DashboardDetailSectionButtomRightTextRow>
                      <b>Backup Jobs</b> (Past 24 hrs)
                  </DashboardDetailSectionButtomRightTextRow>
                  {/* <DashboardDetailSectionButtomRightTextRow>
                    <Circle />
                    <DashboardDetailSectionButtomleftTextTitle>
                      4,300
                    </DashboardDetailSectionButtomleftTextTitle>
                    <DashboardDetailSectionButtomRightTextSubTitle>
                      (all) succeed
                    </DashboardDetailSectionButtomRightTextSubTitle>
                  </DashboardDetailSectionButtomRightTextRow> */}
                  <DashboardDetailSectionButtomRightTextRow>
                    <DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                      Coming Soon
                    </DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                  </DashboardDetailSectionButtomRightTextRow>
                </DashboardDetailSectionButtomRightTextFirst>
              </DashboardDetailSectionBottomLeft>
              <VerticalDividerDashboard />
              <DashboardDetailSectionBottomLeft>
                <DashboardDetailSectionButtomRightTextFirst>
                  <DashboardDetailSectionButtomRightTextRow>
                      <b>Last Object Backup</b>
                  </DashboardDetailSectionButtomRightTextRow>
                  {/* <DashboardDetailSectionButtomRightTextRow>
                    <Circle />
                    <DashboardDetailSectionButtomleftTextTitle>
                      4,300
                    </DashboardDetailSectionButtomleftTextTitle>
                    <DashboardDetailSectionButtomRightTextSubTitle>
                      (all) succeed
                    </DashboardDetailSectionButtomRightTextSubTitle>
                  </DashboardDetailSectionButtomRightTextRow> */}
                  <DashboardDetailSectionButtomRightTextRow>
                    <DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                      Coming Soon
                    </DashboardDetailSectionButtomRightTextSubTitleComingSoon>
                  </DashboardDetailSectionButtomRightTextRow>
                </DashboardDetailSectionButtomRightTextFirst>
              </DashboardDetailSectionBottomLeft>
            </DashboardDetailSectionBottom>
          </DashboardDetailSection>
        </Card> : <></>
          }
        </LeftChartSection>
        <RightChartSection>
          <Card
            hasBorder={true}
            style={{
              position: 'relative',
              height: '300px',
              width: '1150px',
              padding: '0px 0px 0px 14px',
              margin: '80px 12px 12px 12px',
            }}
          >
            <div />
            {isLoadingBackupJobAllSuccess || isLoadingBackupJobAllFailure ? (
                <ChartNoData>No data Available</ChartNoData>
              ) : (
                <CurveChart successData={chartData.success} failureData={chartData.failure} onButtonClick={handleButtonClick} />
              )}
            {/* <CurveChart onButtonClick={handleButtonClick} successData={chartData.success} failureData={chartData.failure}/> */}
          </Card>
          <Card
            hasBorder={true}
            style={{
              position: 'relative',
              height: '450px',
              width: '1150px',
              margin: '80px 12px 12px 12px',
              padding: '14px',
            }}
          >
            {
              (dashboardDailyData && dashboardDailyData.body && dashboardDailyData.body.data && Object.keys(dashboardDailyData.body.data).length > 0) ? <StackedBarChart dashboardDaily={dashboardDailyData}/> : 
              <ChartNoData>No data Available</ChartNoData>
            }
          </Card>
        </RightChartSection>
      </DashboardPageLayout> 
      // : <CreateBackupLanding/>
      //   } </div>
      }
      </div>
    );
  // }
}

export default DashboardPage;