import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import {UserModel, DestinationApi, DestinationTypeApi} from '../../model/BackupJobModel';

export const useSaveBackupDestinationType = () => {
  const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<DestinationTypeApi>(['BackupDestinationType']);
  const tenantId = userData?.tenantId;
  
  return useMutation(
    (BackupDestinationType: DestinationApi) => ApiServiceInstance.createBackupDestination({ tenantId: tenantId!, data: BackupDestinationType }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['BackupDestinationType']);
        queryClient.setQueryData(['tenantBackupSourceId'], data.id);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );};

export const useFetchBackupDestinationType = (backupDestinationaId: string) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<DestinationTypeApi>(['BackupDestinationType']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['BackupDestinationType', backupDestinationaId],
    () => ApiServiceInstance.getBackupDestination({ tenantId: tenantId!, backupDestinationaId }),
    {
      enabled: !!tenantId && !!backupDestinationaId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};

export const useFetchAllBackupDestinationType = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['BackupDestinationType', {tenantId}],
      () => ApiServiceInstance.getAllBackupDestination({ tenantId: tenantId! }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
      }
    );
  };
