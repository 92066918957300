import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import { TenantApi, TenantData, UserModel} from '../../model/BackupJobModel';

export const useTenantData = () => {
  const queryClient = useQueryClient();
  
  return useMutation(
    (formData: TenantData) => ApiServiceInstance.createTenant({ data: formData }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['tenantData']);
        queryClient.setQueryData(['tenantData'], data);
        queryClient.setQueryData(['tenantId'], data.id);
      },
      onError: (error) => {
        console.error('Error saving form data tenant', error);
      },
    }
  );};

  export const useUpdateTenantData = () => { // Renamed to useUpdateTenantData
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useMutation(
      (formData: TenantData) => ApiServiceInstance.updateTenant({ tenantId: tenantId!, data: formData }),
      {
        onSuccess: (data) => {
          queryClient.setQueryData(['tenantStatus'], data.onboardingStatus);
        },
        onError: (error) => {
          console.error('Error saving form data tenant', error);
        },
      }
    );
  };

  export const useFetchTenant = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['formData'],
      () => ApiServiceInstance.getTenant({ tenantId: tenantId!}),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
      }
    );
  };

export const useFetchAllTenant = () => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['tenantData', {tenantId}],
    () => ApiServiceInstance.getAllTenants(),
    {
      enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};
