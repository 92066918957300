import {GlobalStyleBase, GlobalStyleReset} from '@rubrik/aura-ui';
import {AppProviders} from 'src/App.providers';
import {StyleBase} from 'src/Platform/Theme/StyleBase';
import Header from 'src/components/header';
import {ReactElement} from 'react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {Security} from '@okta/okta-react';
import {useNavigate} from 'react-router-dom';
import config from 'src/service/config';
import RoutesFunction from 'src/components/Routes';
import { BodyContentTemplate } from './style/emotionStyle';

const oktaAuth = new OktaAuth(config.oidc);

function App(): ReactElement {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AppProviders>
        <GlobalStyleReset />
        <GlobalStyleBase />
        <StyleBase />
        <Header headerData="" />
        <BodyContentTemplate>
        <RoutesFunction />
        </BodyContentTemplate>
        {/* <NewUserFlowPage /> */}
      </AppProviders>
    </Security>
  );
}

export default App;
