import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import {DataSourcePayload, BackupSourceType, UserModel, BackupSourceApi} from '../../model/BackupJobModel';

export const useSaveBackupSourceType = () => {
  const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<BackupSourceType>(['BackupSourceType']);
  const tenantId = userData?.tenantId;
  
  return useMutation(
    (BackupSourceType: DataSourcePayload) => ApiServiceInstance.createTenantBackupSource({ tenantId: tenantId!, data: BackupSourceType }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['BackupSourceType']);
        queryClient.setQueryData(['tenantBackupSourceTypeId'], data.id);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );};

  // export const useUpdateBackupSourceType = () => {
  //   const queryClient = useQueryClient();
  //     const userData = queryClient.getQueryData<BackupSourceApi>(['backupSourceType']);
  //   const tenantId = userData?.tenantId;
    
  //   return useMutation(
  //     (backupSourceType: BackupSourceApi) => ApiServiceInstance.updateTenantBackupSource({data: backupSourceType}),
  //     {
  //       onSuccess: (data) => {
  //         queryClient.invalidateQueries(['BackupSourceType']);
  //         queryClient.setQueryData(['tenantBackupSourceId'], data.id);
  //       },
  //       onError: (error) => {
  //         console.error('Error saving form data source', error);
  //       },
  //     }
  //   );};

export const useFetchBackupSourceType = (tenantBackupSourceId: string) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<BackupSourceType>(['BackupSourceType']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['BackupSourceType', tenantBackupSourceId],
    () => ApiServiceInstance.getTenantBackupSource({ tenantId: tenantId!, tenantBackupSourceId }),
    {
      enabled: !!tenantId && !!tenantBackupSourceId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};

export const useFetchAllBackupSourceType = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['BackupSourceType', {tenantId}],
      () => ApiServiceInstance.getAllSourceType({ tenantId: tenantId! }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
      }
    );
  };
