import * as React from 'react';
import {FormInputBox, Form, validationUtils, Button, OptionType, ValidationError} from '@rubrik/aura-ui';
import {
  Title,
  ButtonDestination,
} from '../../style/emotionStyle';
import SlaPage from './CreateSla';
import CreateBackupJob from './createbackupJob';
import {DestinationApi, DestinationAPITypeRequest, UserModel} from 'src/model/BackupJobModel';
import {
  useFetchFormDataDestination,
  useSaveFormDataDestination,
  useUpdateDataDestination,
} from '../Mutation/DataDestinationMutation';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchAllBackupDestinationType } from '../Mutation/BackupDestinationTypeMutation';
import { useUpdateTenantData } from '../Mutation/tenantMutation';

const initialValues: DestinationAPITypeRequest = {
accessDetails:{
  cloudProvider: 'AWS',
  storageService: 'S3',
  storageTier: 'S3-Standard',
  region: 'Mumbai(ap-south-1)',
}
};

interface DataDestinationProps {
  isUpdate?: boolean; // Optional prop to determine if we are updating or creating
  nextStep: () => void;
  onSuccess: () => void;
  onError: (error: any) => void;
  savedData?: any;
}

const DataDestination: React.FC<DataDestinationProps> = ({
  isUpdate,
  nextStep,
  onSuccess, onError
}) => {
  const queryClient = useQueryClient();
  const { data: backupDestinationType } = useFetchAllBackupDestinationType();

  const [activeComponent, setActiveComponent] =
    React.useState<React.ReactNode | null>(null);
  const {mutate: saveFormDataDestination, isLoading} =
    useSaveFormDataDestination(); // Use mutation
  const [formValues, setFormValues] =
    React.useState<DestinationApi>(initialValues);
    const tenantBackupDestinationId = queryClient.getQueryData<string>(['tenantBackupDestinationId']);
    const {data: savedFormData} = useFetchFormDataDestination(queryClient.getQueryData([tenantBackupDestinationId]) || '');
    const { mutate: updateFormData } = useUpdateDataDestination();
    const [isButtonDisabled, setIsButtonDisabled] =React.useState<boolean>(false); // State to manage the button disabled state
    const [apiError, setApiError] = React.useState("");
    const alreadySavedFormData = queryClient.getQueryData(['tenantBackupDestinationId']) as unknown as DestinationApi;
    const { mutate: updateTenantOnboardingStatus } = useUpdateTenantData();

  // Set initial form values
  React.useEffect(() => {
    if (savedFormData && Object.keys(savedFormData).length) {
      setFormValues(savedFormData);
      setIsButtonDisabled(!areAllFieldsFilled(savedFormData));
    } else {
      setFormValues(initialValues);
    }
  }, [savedFormData]);

  const areAllFieldsFilled = (values: DestinationApi): boolean => {
    return Object.values(values).every((value) => value !== '');
  };

  const handleValueChange = (
    key: keyof DestinationApi['accessDetails'],
    value: string,
  ): void => {
    const accessDetailsCopy = { ...formValues.accessDetails, [key]: value };
    const formValuesCopy = { ...formValues, accessDetails: accessDetailsCopy };
    setFormValues(formValuesCopy);
    setIsButtonDisabled(!areAllFieldsFilled(formValuesCopy));
  };

  function handleClick() {
    handleSubmit(formValues);
    if (isUpdate) {
      setActiveComponent(() => <CreateBackupJob nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>);
    } else {
      setActiveComponent(() => <SlaPage nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>);
    }
  }

  const handleClear = () => {
    setFormValues(initialValues);
  };

  const handleSuccess = () => {
    console.log("Success callback triggered.");
  };

  const handleError = (error: any) => {
    console.error("Error callback triggered:", error);
  };

  const handleSubmit = (values: DestinationApi) => {
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId || "";
    const payloadData = {
      backupDestinationTypeId: backupDestinationType ? backupDestinationType.backupDestinations[0].id: null ,
      accessDetails: values.accessDetails
    }
    if (alreadySavedFormData) {
      updateFormData({tenantBackupDestinationaId:alreadySavedFormData ? alreadySavedFormData.id : "" as unknown as any, formData:payloadData as unknown as any}, {
        onSuccess: (data: any) => {
          queryClient.setQueryData(['tenantBackupDestinationId'], data);
          nextStep();
          onSuccess(); // Call the onSuccess callback prop
          if(queryClient.getQueryData(['tenantOnboardingStatus']) === 'ADDED_SLA'){
            queryClient.setQueryData(['currentTab'], 4);
          }
          setApiError("")
        },
        onError: (error: any) => {
          onError(error); // Call the onError callback prop
          setApiError(error.data.detail[0].msg.toString())        
        }
      });
    } else {
    saveFormDataDestination(payloadData as unknown as DestinationAPITypeRequest, {
      onSuccess: () => {
        queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_DT");
        updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_DT"})
        nextStep(); 
        onSuccess();
        queryClient.setQueryData(['currentTab'], 3);
        setApiError("")
      },
      onError: (error: any) => {
        onError(error);
        setApiError(error.data.detail[0].msg.toString())      
      }
    });
  }
  };

  // Display loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
  //   activeComponent ? (
  //   isUpdate ? (
  //     <CreateBackupJob nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>
  //   ) : (
  //     <SlaPage nextStep={nextStep} onSuccess={handleSuccess} onError={handleError} />
  //   )
  // ) : 
  // (
    <div>
        <div>
          <Title>Basic</Title>
          <Form initialValues={initialValues} onSubmit={handleSubmit}>
            <div>
              <FormInputBox
                name="cloudProvider"
                label="Cloud Provider"
                placeholder="AWS"
                validators={[validationUtils.required]}
                value="AWS"
                onChangeValue={(e) => {
                  handleValueChange('cloudProvider', e);
                }}
                disabled={true}
              />
              <FormInputBox
                name="storageService"
                label="Storage Service"
                placeholder="S3"
                validators={[validationUtils.required]}
                value="S3"
                onChangeValue={(e) => {
                  handleValueChange('storageService', e);
                }}
                disabled={true}
              />
              <FormInputBox
                name="storageTier"
                label="Storage Class"
                placeholder="S3-Standard"
                validators={[validationUtils.required]}
                value="S3-Standard"
                onChangeValue={(e) => {
                  handleValueChange('storageTier', e);
                }}
                disabled={true}
              />
              <FormInputBox
                name="region"
                label="Region"
                placeholder="Mumbai (ap-south-1)"
                validators={[validationUtils.required]}
                value="Mumbai (ap-south-1)"
                onChangeValue={(e) => {
                  handleValueChange('region', e);
                }}
                disabled={true}
              />
            </div>
          </Form>
        </div>
        { apiError ? (<ValidationError errorMessageTID={apiError} ></ValidationError>) : (<></>)}
        <ButtonDestination>
          <Button
            style={{
              margin: '7px',
              backgroundColor: 'transparent',
              border: '2px solid white',
              color: 'white',
              opacity: 0.8,
            }}
            variant="primary"
            size="large"
            onClick={handleClear}
          >
            Reset
          </Button>
          <Button
            style={{margin: '7px'}}
            variant="primary"
            size="large"
            onClick={handleClick}
            disabled={isButtonDisabled}
          >
            {alreadySavedFormData ? 'Update Destination' : 'Create Destination'}
          </Button>
        </ButtonDestination>
    </div>
  // );
  )
};

export default DataDestination;