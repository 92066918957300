import {useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import { UserModel} from '../../model/BackupJobModel';

export const useFetchDashboardCumulative = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['dashboardCumulative', {tenantId}],
      () => ApiServiceInstance.dashboardCumulative({ tenantId: tenantId!}),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
        retry: 1, // Retry once if the query fails
      }
    );
  };

  export const useFetchDashboardDaily = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['dashboardDaily', {tenantId}],
      () => ApiServiceInstance.dashboardDaily({ tenantId: tenantId!}),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
        retry: 1, // Retry once if the query fails
      }
    );
  };
