import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import Login from './Login';
import ApiServiceInstance from 'src/service/service';
import { useFetchFormDataUser } from './Mutation/UserDataMutation';
import { useQueryClient } from '@tanstack/react-query';

function SecureRoute(): JSX.Element {
  const { authState } = useOktaAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (authState?.isAuthenticated === true) {
      ApiServiceInstance.setAuthToken(authState?.accessToken?.accessToken ?? '');
      queryClient.invalidateQueries(['formDataUser']);
    }
  }, [authState, queryClient]);

  const { data: userData, isLoading, isError } = useFetchFormDataUser();

  // Conditional rendering logic inside JSX, not around hook usage
  if (authState?.isAuthenticated === false) {
    return <Login />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching user data.</div>;
  }

  return <Outlet />;
}

export default SecureRoute;