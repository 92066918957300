import axios, {type AxiosError, type AxiosInstance} from 'axios';
import {
  BackupJobApi,
  BackupJobInstanceApi,
  BackupJobInstances,
  BackupJobTableResponse,
  BackupSourceApi,
  BackupSourceMonitoringApi,
  BackupSourceType,
  DashboardData,
  DashboardDataDaily,
  DataSourceApi,
  DataSourceModel,
  DataSourcePayload,
  DestinationApi,
  DestinationAPITenantResponse,
  DestinationAPITypeRequest,
  DestinationAPITypeResponse,
  DestinationTypeApi,
  RestoreJobInstanceApi,
  SLA,
  SLAData,
  SourceTypeApi,
  TenantApi,
  TenantData,
  UserApi,
  UserModel,
  type ErrorResponseData,
} from '../model/BackupJobModel';

class ApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create({
      // baseURL: process.env.REACT_APP_API_URL,
      // baseURL: 'https://apitest.rubrik.com/finguard/api/crud/',
      // baseURL: 'http://finguard-dev.rubrik.com/api/crud'
      // baseURL: 'http://finguard-dev.rubrik.com/finguard/api/crud/'
      // baseURL: 'http://127.0.0.1:8000/finguard/api/crud/',
      // timeout: 30000
      baseURL: 'https://finguard-uat.rubrik.com/api/crud'
    });
  }

  setAuthToken(token: string): void {
    let authorizationHeader;
    if (token !== null && token !== '') {
      authorizationHeader = `Bearer ${token}`;
    }

    // Set custom headers
    this.axiosInstance.defaults.headers.common = {
      ...this.axiosInstance.defaults.headers.common,
      Authorization: authorizationHeader,
    };
  }

  getAxisoInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  __getErrorResponse(error: AxiosError): {
    status: number | undefined;
    data: ErrorResponseData;
  } {
    return {
      status: error?.response?.status,
      data: error?.response?.data as ErrorResponseData,
    };
  }

  async __get<T>(url: string, params = {}): Promise<T> {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, {params})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(this.__getErrorResponse(err));
        });
    });
  }

  async __post<T>(
    url: string,
    data:
      | UserApi
      | DataSourceModel
      | DataSourcePayload
      | TenantApi
      | TenantData
      | SLA
      | DestinationApi
      | DestinationTypeApi
      | SourceTypeApi
      | BackupSourceMonitoringApi
      | BackupJobInstanceApi
      | RestoreJobInstanceApi
      | BackupSourceType
      | BackupSourceApi
      | DestinationAPITypeRequest
      | BackupJobApi
      | BackupJobInstances
  ): Promise<T> {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(this.__getErrorResponse(err));
        });
    });
  }

  async __put<T>(
    url: string,
    data:
      | UserApi
      | TenantApi
      | TenantData
      | SLA
      | DestinationApi
      | DestinationTypeApi
      | SourceTypeApi
      | BackupSourceMonitoringApi
      | BackupJobInstanceApi
      | RestoreJobInstanceApi
      | BackupSourceApi
      | BackupJobApi
      | BackupJobInstances
  ): Promise<T> {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(this.__getErrorResponse(err));
        });
    });
  }

  async __delete<T>(url: string, data: undefined = undefined): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.axiosInstance
        .delete(url, {data})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(this.__getErrorResponse(err));
        });
    });
  }

  // Current User fetch

  async getCurrentUser(): Promise<UserModel> {
    return await this.__get('/user/current-user/');
  }
  // user CRUD

  async createUsers({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: UserApi;
  }): Promise<UserApi> {
    return await this.__post(`/${tenantId}/user`, data);
  }

  async getAllUsers({
    tenantId,
    params,
  }: {
    tenantId: string;
    params: UserModel;
  }): Promise<Array<UserModel>> {
    return await this.__get(`/${tenantId}/user`, params);
  }

  async getUsers({
    tenantId,
    userID,
  }: {
    tenantId: string;
    userID: string;
  }): Promise<Array<UserModel>> {
    return await this.__get(`/${tenantId}/user/${userID}`);
  }

  async updateUser({
    tenantId,
    userId,
    data,
  }: {
    tenantId: string;
    userId: string;
    data: UserApi;
  }): Promise<UserApi> {
    return await this.__put(`/${tenantId}/user/${userId}`, data);
  }

  async deleteUser({
    tenantId,
    userId,
  }: {
    tenantId: string;
    userId: string;
  }): Promise<UserApi> {
    return await this.__delete<UserApi>(`/${tenantId}/user/${userId}`);
  }

  // tenant CRUD
  async createTenant({data}: {data: TenantData}): Promise<TenantData> {
    return await this.__post(`/tenant`, data);
  }

  async getAllTenants(): Promise<TenantApi> {
    return await this.__get(`/tenant`);
  }

  async getTenant({tenantId}: {tenantId: string}): Promise<TenantData> {
    return await this.__get(`/tenant/${tenantId}`);
  }

  async updateTenant({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: TenantData;
  }): Promise<TenantData> {
    return await this.__put(`/tenant/${tenantId}`, data);
  }

  async deleteTenant({tenantId}: {tenantId: string}): Promise<TenantApi> {
    return await this.__delete(`/tenant/${tenantId}`);
  }

  // SLA CRUD
  async createSLA({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: SLA;
  }): Promise<SLA> {
    return await this.__post(`/${tenantId}/sla`, data);
  }

  async getAllSLA({
    tenantId,
  }: {
    tenantId: string;
  }): Promise<SLAData> {
    return await this.__get(`/${tenantId}/sla`);
  }

  async getSLA({
    tenantId,
    tenantBackupSLAId,
  }: {
    tenantId: string;
    tenantBackupSLAId: string;
  }): Promise<SLA> {
    return await this.__get(`/${tenantId}/sla/${tenantBackupSLAId}`);
  }

  async updateSLA({
    tenantId,
    slaId,
    data,
  }: {
    tenantId: string;
    slaId: string;
    data: SLA;
  }): Promise<SLA> {
    return await this.__put(`/${tenantId}/sla/${slaId}`, data);
  }

  async deleteSLA({
    tenantId,
    slaId,
  }: {
    tenantId: string;
    slaId: string;
  }): Promise<SLA> {
    return await this.__delete(`/${tenantId}/sla/${slaId}`);
  }

  // Backup Destination Type CRUD
  async createBackupDestination({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: DestinationApi;
  }): Promise<DestinationApi> {
    return await this.__post(`/${tenantId}/backup/destination/type`, data);
  }

  async getAllBackupDestination({
    tenantId,
  }: {
    tenantId: string;
  }): Promise<DestinationAPITypeResponse> {
    return await this.__get(`/${tenantId}/backup/destination/type`);
  }

  async getBackupDestination({
    tenantId,
    backupDestinationaId,
  }: {
    tenantId: string;
    backupDestinationaId: string;
  }): Promise<DestinationAPITenantResponse> {
    return await this.__get(
      `/${tenantId}/backup/destination/type/${backupDestinationaId}`,
    );
  }

  async updateBackupDestination({
    tenantId,
    backupDestinationaId,
    data,
  }: {
    tenantId: string;
    backupDestinationaId: string;
    data: DestinationApi;
  }): Promise<DestinationApi> {
    return await this.__put(
      `/${tenantId}/backup/destination/type/${backupDestinationaId}`,
      data,
    );
  }

  async deleteBackupDestination({
    tenantId,
    backupDestinationaId,
  }: {
    tenantId: string;
    backupDestinationaId: string;
  }): Promise<DestinationApi> {
    return await this.__delete(
      `/${tenantId}/backup/destination/type/${backupDestinationaId}`,
    );
  }

  // Backup Destination CRUD
  async createTenantBackupDestination({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: DestinationAPITypeRequest;
  }): Promise<DestinationAPITypeRequest> {
    return await this.__post(`/${tenantId}/tenant/backup/destination`, data);
  }

  async getAllTenantBackupDestination({
    tenantId,
  }: {
    tenantId: string;
  }): Promise<DestinationAPITenantResponse> {
    return await this.__get(`/${tenantId}/tenant/backup/destination`);
  }

  async getTenantBackupDestination({
    tenantId,
    tenantBackupDestinationaId,
  }: {
    tenantId: string;
    tenantBackupDestinationaId: string;
  }): Promise<DestinationApi> {
    return await this.__get(
      `/${tenantId}/tenant/backup/destination/${tenantBackupDestinationaId}`,
    );
  }

  async updateTenantBackupDestination({
    tenantId,
    tenantBackupDestinationaId,
    data,
  }: {
    tenantId: string;
    tenantBackupDestinationaId: string;
    data: DestinationApi;
  }): Promise<DestinationApi> {
    return await this.__put(
      `/${tenantId}/tenant/backup/destination/${tenantBackupDestinationaId}`,
      data,
    );
  }

  async deleteTenantBackupDestination({
    tenantId,
    tenantBackupDestinationaId,
  }: {
    tenantId: string;
    tenantBackupDestinationaId: string;
  }): Promise<DestinationApi> {
    return await this.__delete(
      `/${tenantId}/tenant/backup/destination/${tenantBackupDestinationaId}`,
    );
  }

  // Backup Source Type CRUD
  async createSourceType({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: DestinationTypeApi;
  }): Promise<DestinationTypeApi> {
    return await this.__post(`/${tenantId}/backup/source/types`, data);
  }

  async getAllSourceType({
    tenantId
  }: {
    tenantId: string;
  }): Promise<SourceTypeApi> {
    return await this.__get(`/${tenantId}/backup/source/types`);
  }

  async getSourceType({
    tenantId,
    backupSourceTypeId,
  }: {
    tenantId: string;
    backupSourceTypeId: string;
  }): Promise<Array<DestinationTypeApi>> {
    return await this.__get(
      `/${tenantId}/backup/source/type/${backupSourceTypeId}`,
    );
  }

  async updateSourceType({
    tenantId,
    backupSourceTypeId,
    data,
  }: {
    tenantId: string;
    backupSourceTypeId: string;
    data: DestinationTypeApi;
  }): Promise<DestinationTypeApi> {
    return await this.__put(
      `/${tenantId}/backup/source/type/${backupSourceTypeId}`,
      data,
    );
  }

  async deleteSourceType({
    tenantId,
    backupSourceTypeId,
  }: {
    tenantId: string;
    backupSourceTypeId: string;
  }): Promise<DestinationTypeApi> {
    return await this.__delete(
      `/${tenantId}/backup/source/type/${backupSourceTypeId}`,
    );
  }

  // Backup Source CRUD
  async createTenantBackupSource({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: DataSourcePayload;
  }): Promise<DataSourcePayload> {
    return await this.__post(`/${tenantId}/tenant/backup/source`, data);
  }

  async getAllTenantBackupSource({
    tenantId,
    params
  }: {
    tenantId: string;
    params?:any;
  }): Promise<DataSourceApi> {
    return await this.__get(`/${tenantId}/tenant/backup/source`, params);
  }

  async getTenantBackupSource({
    tenantId,
    tenantBackupSourceId,
  }: {
    tenantId: string;
    tenantBackupSourceId: string;
  }): Promise<DataSourcePayload> {
    return await this.__get(
      `/${tenantId}/tenant/backup/source/${tenantBackupSourceId}`,
    );
  }

  // async updateTenantBackupSource({
  //   tenantId,
  //   tenantBackupSourceId,
  //   data,
  // }: {
  //   tenantId: string;
  //   tenantBackupSourceId: string;
  //   data: BackupSourceType;
  // }): Promise<BackupSourceType> {
  //   return await this.__put(
  //     `/${tenantId}/tenant/backup/source/${tenantBackupSourceId}`,
  //     data,
  //   );
  // }

  async updateTenantBackupSource({
    tenantId,
    tenantBackupSourceId,
    data,
  }: {
    tenantId: string;
    tenantBackupSourceId: string;
    data: BackupSourceApi;
  }): Promise<BackupSourceApi> {
    return await this.__put(
      `/${tenantId}/tenant/backup/source/${tenantBackupSourceId}`,
      data
    );
  }

  async deleteTenantBackupSource({
    tenantId,
    tenantBackupSourceId,
  }: {
    tenantId: string;
    tenantBackupSourceId: string;
  }): Promise<SourceTypeApi> {
    return await this.__delete(
      `/${tenantId}/tenant/backup/source/${tenantBackupSourceId}`,
    );
  }

 // Backup Source CRUD
 async createTenantBackupSourceType({
  tenantId,
  data,
}: {
  tenantId: string;
  data: DataSourcePayload;
}): Promise<DataSourcePayload> {
  return await this.__post(`/${tenantId}/tenant/backup/source/types`, data);
}

async getAllTenantBackupSourceType({
  tenantId,
}: {
  tenantId: string;
}): Promise<Array<SourceTypeApi>> {
  return await this.__get(`/${tenantId}/tenant/backup/source/types`);
}

async getTenantBackupSourceType({
  tenantId,
  tenantBackupSourceId,
}: {
  tenantId: string;
  tenantBackupSourceId: string;
}): Promise<DataSourcePayload> {
  return await this.__get(
    `/${tenantId}/tenant/backup/source/types/${tenantBackupSourceId}`,
  );
}

async updateTenantBackupSourceType({
  tenantId,
  tenantBackupSourceId,
  data,
}: {
  tenantId: string;
  tenantBackupSourceId: string;
  data: SourceTypeApi;
}): Promise<SourceTypeApi> {
  return await this.__put(
    `/${tenantId}/tenant/backup/source/types/${tenantBackupSourceId}`,
    data,
  );
}

async deleteTenantBackupSourceType({
  tenantId,
  tenantBackupSourceId,
}: {
  tenantId: string;
  tenantBackupSourceId: string;
}): Promise<SourceTypeApi> {
  return await this.__delete(
    `/${tenantId}/tenant/backup/source/types/${tenantBackupSourceId}`,
  );
}

  // Backup Source Monitoring CRUD
  async createBackupSourceMonitoring({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: BackupSourceMonitoringApi;
  }): Promise<BackupSourceMonitoringApi> {
    return await this.__post(`/${tenantId}/backup/source/monitoring`, data);
  }

  async getAllBackupSourceMonitoring({
    tenantId,
  }: {
    tenantId: string;
  }): Promise<Array<BackupSourceMonitoringApi>> {
    return await this.__get(`/${tenantId}/backup/source/monitoring`);
  }

  async getBackupSourceMonitoring({
    tenantId,
    tenantBackupMonitoringId,
  }: {
    tenantId: string;
    tenantBackupMonitoringId: string;
  }): Promise<Array<BackupSourceMonitoringApi>> {
    return await this.__get(
      `/${tenantId}/backup/source/monitoring/${tenantBackupMonitoringId}`,
    );
  }

  async updateBackupSourceMonitoring({
    tenantId,
    tenantBackupMonitoringId,
    data,
  }: {
    tenantId: string;
    tenantBackupMonitoringId: string;
    data: BackupSourceMonitoringApi;
  }): Promise<BackupSourceMonitoringApi> {
    return await this.__put(
      `/${tenantId}/backup/source/monitoring/${tenantBackupMonitoringId}`,
      data,
    );
  }

  async deleteBackupSourceMonitoring({
    tenantId,
    tenantBackupMonitoringId,
  }: {
    tenantId: string;
    tenantBackupMonitoringId: string;
  }): Promise<BackupSourceMonitoringApi> {
    return await this.__delete(
      `/${tenantId}/backup/source/monitoring/${tenantBackupMonitoringId}`,
    );
  }

  // Backup Job Instance CRUD
  async createBackupJobInstance({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: BackupJobInstances;
  }): Promise<BackupJobInstances> {
    return await this.__post(`/${tenantId}/backup/job/instance`, data);
  }

  async createBackupJobInstanceReport({
    tenantId,
    params
  }: {
    tenantId: string;
    params?: any;
  }): Promise<BackupJobInstances> {
    return await this.__get(`/${tenantId}/reports/backup/jobs`, params);
  }

  async getAllBackupJobInstance({
    tenantId,
    params
  }: {
    tenantId: string;
    params?: any;
  }): Promise<BackupJobInstanceApi> {
    return await this.__get(`/${tenantId}/backup/job/instance/`, params);
  }

  async getBackupJobInstance({
    tenantId,
    backupJobInstanceId
  }: {
    tenantId: string;
    backupJobInstanceId: string;
  }): Promise<BackupJobInstances> {
    return await this.__get(
      `/${tenantId}/backup/job/instance/${backupJobInstanceId}`,
    );
  }

  async updateBackupJobInstance({
    tenantId,
    backupJobInstanceId,
    data,
  }: {
    tenantId: string;
    backupJobInstanceId: string;
    data: BackupJobInstances;
  }): Promise<BackupJobInstances> {
    return await this.__put(
      `/${tenantId}/backup/job/instance/${backupJobInstanceId}`,
      data,
    );
  }

  async deleteBackupJobInstance({
    tenantId,
    backupJobInstanceId,
  }: {
    tenantId: string;
    backupJobInstanceId: string;
  }): Promise<BackupJobInstances> {
    return await this.__delete<BackupJobInstances>(
      `/${tenantId}/backup/job/instance/${backupJobInstanceId}`,
    );
  }

    // Backup Job CRUD
    async createBackupJob({
      tenantId,
      data,
    }: {
      tenantId: string;
      data: BackupJobApi;
    }): Promise<BackupJobApi> {
      return await this.__post(`/${tenantId}/backup/job`, data);
    }
  
    async getAllBackupJob({
      tenantId,
    }: {
      tenantId: string;
    }): Promise<BackupJobTableResponse> {
      return await this.__get(`/${tenantId}/backup/job`);
    }
  
    async getBackupJob({
      tenantId,
      backupJobId,
    }: {
      tenantId: string;
      backupJobId: string;
    }): Promise<BackupJobApi> {
      return await this.__get(
        `/${tenantId}/backup/job/${backupJobId}`,
      );
    }
  
    async updateBackupJob({
      tenantId,
      backupJobId,
      data,
    }: {
      tenantId: string;
      backupJobId: string;
      data: BackupJobApi;
    }): Promise<BackupJobApi> {
      return await this.__put(
        `/${tenantId}/backup/job/${backupJobId}`,
        data,
      );
    }
  
    async deleteBackupJob({
      tenantId,
      backupJobId,
    }: {
      tenantId: string;
      backupJobId: string;
    }): Promise<BackupJobApi> {
      return await this.__delete<BackupJobApi>(
        `/${tenantId}/backup/job/${backupJobId}`,
      );
    }
  
    async dashboardCumulative({
      tenantId,
    }: {
      tenantId: string;
    }): Promise<DashboardData> {
      return await this.__get(
        `/${tenantId}/dashboard/stats/cumulative`,
      );
    }

    async dashboardDaily({
      tenantId,
    }: {
      tenantId: string;
    }): Promise<DashboardDataDaily> {
      return await this.__get(
        `/${tenantId}/dashboard/stats/daily`,
      );
    }
  
  // Restore Job Instance CRUD
  async createRestoreJobInstance({
    tenantId,
    data,
  }: {
    tenantId: string;
    data: RestoreJobInstanceApi;
  }): Promise<RestoreJobInstanceApi> {
    return await this.__post(`/${tenantId}/restore/job/instance`, data);
  }

  async getAllRestoreJobInstance({
    tenantId,
    params,
  }: {
    tenantId: string;
    params: RestoreJobInstanceApi;
  }): Promise<Array<RestoreJobInstanceApi>> {
    return await this.__get(`/${tenantId}/restore/job/instance`, params);
  }

  async getRestoreJobInstance({
    tenantId,
    backupJobInstanceId,
  }: {
    tenantId: string;
    backupJobInstanceId: string;
  }): Promise<Array<RestoreJobInstanceApi>> {
    return await this.__get(
      `/${tenantId}/restore/job/instance/${backupJobInstanceId}`,
    );
  }

  async updateRestoreJobInstance({
    tenantId,
    backupJobInstanceId,
    data,
  }: {
    tenantId: string;
    backupJobInstanceId: string;
    data: RestoreJobInstanceApi;
  }): Promise<RestoreJobInstanceApi> {
    return await this.__put(
      `/${tenantId}/restore/job/instance/${backupJobInstanceId}`,
      data,
    );
  }

  async deleteRestoreJobInstance({
    tenantId,
    backupJobInstanceId,
  }: {
    tenantId: string;
    backupJobInstanceId: string;
  }): Promise<RestoreJobInstanceApi> {
    return await this.__delete(
      `/${tenantId}/restore/job/instance/${backupJobInstanceId}`,
    );
  }
}
const ApiServiceInstance = new ApiService();
export default ApiServiceInstance;