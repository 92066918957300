import { StepNavigation, StepNavigationStep } from '@rubrik/aura-ui';

export const DATA_SOURCE_CONST = {
  BASIC: [
    {
      title: 'Data Source *',
      infoText: '',
      isPassword: '',
    },
    {
      title: 'Given Name *',
      infoText: '',
      isPassword: '',
    },
    {
      title: 'Environment *',
      infoText: '',
      isPassword: '',
    },
  ],
  ACCOUNT_DETAILS: [
    {
      title: 'Account ID *',
      infoText: '',
      isPassword: '',
      value: '',
    },
    {
      title: 'Consumer Key *',
      infoText: '',
      isPassword: 'password',
      value: '',
    },
    {
      title: 'Consumer Secret *',
      infoText: '',
      isPassword: 'password',
      value: '',
    },
  ],
  ODBC_ACCESS_DETAILS: [
    {
      title: 'URL *',
      infoText: 'https://111.app.abc.com',
      isPassword: 'false',
      value: '',
    },
    {
      title: 'ODBC Role Id *',
      infoText: '',
      isPassword: 'false',
      value: '',
    },
    {
      title: 'Server Data Source *',
      infoText: '',
      isPassword: 'false',
      value: '',
    },
    {
      title: 'Token Key *',
      infoText: '',
      isPassword: 'password',
      value: '',
    },
    {
      title: 'Token Secret *',
      infoText: '',
      isPassword: 'password',
      value: '',
    },
  ],
  SOAP_ACCESS_DETAILS: [
    {
      title: 'SOAP Webservice URL *',
      infoText: 'https://111.app.abc.com',
      isPassword: '',
      value: '',
    },
    {
      title: 'Role ID *',
      infoText: '',
      isPassword: '',
      value: '',
    },
    {
      title: 'Token ID *',
      infoText: '',
      isPassword: 'password',
      value: '',
    },
    {
      title: 'Token Secret *',
      infoText: '',
      isPassword: 'password',
      value: '',
    },
  ],
};

export const DATA_DESTINATION_CONST = {
  BASIC: ['Cloud Provider', 'Storage Service', 'Storage Tier', 'Region'],
};

export const regexUrl =
  // /^\b((?:(?:https?|ftp):\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(?:(?:https?|ftp):\/\/)?(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)*\b/;
  /^\b((?:(?:https?|ftp):\/\/)?(?:[a-zA-Z0-9-]+\.)+netsuite\.com)(?::\d{1,5})?(?:\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)*\b/;

export const dataSourceSelect = [
  {
    value: 'Day(s)',
    label: 'Day(s)',
  },
  {
    value: 'Week(s)',
    label: 'Week(s)',
  },
  {
    value: 'Month(s)',
    label: 'Month(s)',
  },
];

export const WeeksSelect = [
  {
    value: 'Mon',
    label: 'Monday',
  },
  {
    value: 'Tue',
    label: 'Tuesday',
  },
  {
    value: 'Wed',
    label: 'Wednesday',
  },
  {
    value: 'Thu',
    label: 'Thrusday',
  },
  {
    key: 5,
    value: 'Fri',
    label: 'Friday',
  },
  {
    value: 'Sat',
    label: 'Saturday',
  },
  {
    value: 'Sun',
    label: 'Sunday',
  },
];

export const dataSelectMonth = [
  {
    value: 'First',
    label: 'First',
  },
  {
    value: 'Last',
    label: 'Last',
  },
  {
    value: 'Fifteenth',
    label: 'Fifteenth',
  },
];

export const dataSelectTime = [
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'PM',
    label: 'PM',
  },
];

export const TimeZoneData = [
  {
    value: 'UTC',
    label: 'UTC',
  }
];

export const MockTableData = [
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Incremental',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'In Progress',
  },
  {
    source: 'Netsuite',
    environment: 'Dev',
    backupType: 'Full',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Success',
  },
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Full',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Success',
  },
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Incremental',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Failed',
  },
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Incremental',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Queued',
  },
  {
    source: 'Netsuite',
    environment: 'Dev',
    backupType: 'Incremental',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Success',
  },
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Incremental',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Success',
  },
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Full',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Queued',
  },{
    source: 'Netsuite',
    environment: 'Dev',
    backupType: 'Full',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Queued',
  },
  {
    source: 'Netsuite',
    environment: 'Production',
    backupType: 'Incremental',
    createdOn: `5:46, 16th April 2024`,
    updatedOn: `5:46, 16th April 2024`,
    status: 'Failed',
  }
]

export function dropdownPapping(value: string) {
  if(value==='MON') {
    return 'Monday'
  } else if(value==='TUE') {
    return 'Tuesday'
  } if(value==='WED') {
    return 'Wednesday'
  }if(value==='Thu') {
    return 'Thursday'
  }if(value==='FRI') {
    return 'Friday'
  }if(value==='SAT') {
    return 'Saturday'
  }if(value==='SUN') {
    return 'Sunday'
  }if(value==='DAYS') {
    return 'Day(s)'
  }if(value==='WEEKS') {
    return 'Week(s)'
  }if(value==='MONTHS') {
    return 'Month(s)'
  }if(value==='FIRST') {
    return 'First'
  }if(value==='LAST') {
    return 'Last'
  }if(value==='Fifteenth') {
    return 'Fifteenth'
  }
}


export const steps: Array<StepNavigationStep> = [
  {
    id: 'Step 1',
    isCompleted: false,
    isCurrent: true,
    isDisabled: () => false,
    title: 'Data Source',
    subtitle: 'Details of Netsuite data, access mechanism and environment',
  },
  {
    id: 'Step 2',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => false,
    title: 'Data Destination',
    subtitle: 'Configure where your backup data would be stored',
  },
  {
    id: 'Step 3',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => false,
    title: 'SLA Domain',
    subtitle: 'Configure how frequently you want to backup your data',
  },
  {
    id: 'Step 4',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => false,
    title: 'Backup Job',
    subtitle: 'Combine all of the above to create backup job',
  },
  {
    id: 'Step 5',
    isCompleted: false,
    isCurrent: false,
    isDisabled: () => false,
    title: 'Start Monitoring',
    subtitle: 'Watch your backup job in action',
  },
];
