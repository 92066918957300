import * as React from 'react';

interface Provider {
  Component: React.FC<{readonly children: React.ReactNode}>;
  props?: {};
}

/**
 * Nests/stacks an array of providers around children.
 *
 * @param providers Array of providers to stack. First item will be the
 *                  top-level provider.
 * @param children React child nodes.
 */
export const stackProviders = (
  providers: Array<Provider> = [],
  children: React.ReactNode | undefined = undefined,
) =>
  providers
    .reverse()
    .reduce(
      (rendered, Provider) => (
        <Provider.Component {...Provider?.props}>{rendered}</Provider.Component>
      ),
      children,
    );
