import {useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import { UserModel} from '../../model/BackupJobModel';

export const useFetchAllBackupJobInstance = (page: number, limit: number, tenantBackupDestinationId?: string, status?: string) => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['backupDataInstance', {tenantId, page, limit, status}],
      () => ApiServiceInstance.getAllBackupJobInstance({ tenantId: tenantId!, params: { page, limit, tenantBackupDestinationId, status } }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
        retry: 1, // Retry once if the query fails
      }
    );
  };

  export const useFetchAllBackupJobInstanceReport = (reportFormat:string) => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['backupDataInstanceReport', {tenantId, reportFormat}],
      () => ApiServiceInstance.createBackupJobInstanceReport({ tenantId: tenantId!, params: {reportFormat } }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 0, // 10 minutes
        cacheTime: 0, // 15 minutes
        retry: 1, // Retry once if the query fails
      }
    );
  };
