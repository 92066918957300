import * as React from 'react';
import {IntlProvider as ReactIntlProvider} from 'react-intl';
import {APP_MESSAGES} from 'src/Platform/Intl/Intl.constants';

interface Props {
  /**
   * If true, output actual translations. If false, output TIDs instead.
   * Typically only used in testing.
   */
  readonly useProductionMessages?: boolean;
  readonly children?: React.ReactNode;
}

/**
 * Support for react-intl. Can be configured to either show
 * production-translated messages, or simply output TIDs.
 * @param children React child nodes.
 * @param useProductionMessages If true, translate TIDs. If false, output TIDs.
 */
export const IntlProvider: React.FC<Props> = ({
  children,
  useProductionMessages = true,
}) => (
  <ReactIntlProvider
    locale="en-US"
    textComponent="span"
    defaultLocale="en-US"
    messages={useProductionMessages ? APP_MESSAGES : {}}
  >
    {children}
  </ReactIntlProvider>
);
