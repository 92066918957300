import {ModalContextProvider, ResizeObserverProvider} from '@rubrik/aura-ui';
import * as React from 'react';
import {IntlProvider} from 'src/Platform/Intl/IntlProvider';
import {AuraUIProvider} from 'src/Platform/Theme/AuraUIProvider';
import {EmotionThemeProvider} from 'src/Platform/Theme/EmotionThemeProvider';
import {MaterialThemeProvider} from 'src/Platform/Theme/MaterialThemeProvider';
import {stackProviders} from 'src/Platform/Utilities/providerUtils';

/**
 * All required providers for the application. They will be nested in the order
 * of the array, where the first item is the outermost provider, and the last
 * item is the innermost.
 *
 * @param children React child nodes.
 */
export const AppProviders: React.FC<{
  readonly children?: React.ReactNode;
}> = ({children}) => (
  <>
    {stackProviders(
      [
        {Component: EmotionThemeProvider},
        {Component: MaterialThemeProvider},
        {Component: ResizeObserverProvider},
        {Component: IntlProvider},
        {Component: AuraUIProvider},
        /**
         * ModalContextProvider must be the last provider in the stack.
         * As Once we open the modal provider after ModalContextProvider
         * is get remove from the DOM so we need to make sure that
         * ModalContextProvider is the last provider in the stack.
         */
        {Component: ModalContextProvider},
      ],
      children,
    )}
  </>
);
