import * as React from 'react';
import {Card, Button, IconButton, ValidationError} from '@rubrik/aura-ui';
import {EditIcon} from '@rubrik/aura-ui/icons';
import DataSource from 'src/components/BackupJob/CreateDataSource';
import DataDestination from 'src/components/BackupJob/CreateDataDestination';
import SlaPage from 'src/components/BackupJob/CreateSla';
import {
  BackupJobText,
  Title,
  ButtonSource,
  CreateBackupJobRows,
  BackupJobTextValue,
  BackupDataItems,
  BackupDataMarginRow,
  ErrorCreateJob,
  BackupJobTextValueDataSource,
  BackupDataItemsDataSource,
  TitleCreateJob,
} from '../../style/emotionStyle';
import MonitoringJob from 'src/components/BackupJob/MonitoringJob';
import { useQueryClient } from '@tanstack/react-query';
import { BackupJobApi, DataSourcePayload, DestinationAPITypeRequest, SLA, UserModel } from 'src/model/BackupJobModel';
import { dropdownPapping } from 'src/constant/BackupJob';
import { useSaveBackupJob, useUpdateBackupJob } from '../Mutation/BackupJobMutation';
import { useFetchAllBackupSource } from '../Mutation/DataSourceMutation';
import { useFetchAllBackupDestination } from '../Mutation/DataDestinationMutation';
import { useFetchAllBackupSla } from '../Mutation/DataSlaMutation';
import { useUpdateTenantData } from '../Mutation/tenantMutation';

interface DataSLAProps {
  nextStep: () => void;
  onSuccess: () => void;
  onError: (error: any) => void;
}

const CreateBackupJob: React.FC<DataSLAProps> = ({nextStep, onSuccess, onError}) => {
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [activeComponent, setActiveComponent] =
    React.useState<React.ReactNode | null>(null);
  const queryClient = useQueryClient();
  const { data: sourceData } = useFetchAllBackupSource();
  const { data: destinationData } = useFetchAllBackupDestination();
  const { data: slaData } = useFetchAllBackupSla();
  const alreadySavedFormData = queryClient.getQueryData(['tenantBackupJobIdData']) as unknown as BackupJobApi;
  queryClient.setQueryData(['tenantBackupSourceId'], sourceData?.tenantBackupSources[0])
  queryClient.setQueryData(['tenantBackupDestinationId'], destinationData?.tenantBackupDestinations[0])
  queryClient.setQueryData(['tenantBackupSlaId'], slaData?.slas[0])
  const tenantBackupSourceId = queryClient.getQueryData<DataSourcePayload>(['tenantBackupSourceId']);
  const tenantBackupDestinationId = queryClient.getQueryData<DestinationAPITypeRequest>(['tenantBackupDestinationId']);
  const tenantBackupSlaId = queryClient.getQueryData<SLA>(['tenantBackupSlaId']);
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const userId = userData?.id;
  const [apiError, setApiError] = React.useState("");
  const { mutate: updateTenantOnboardingStatus } = useUpdateTenantData();
  const { mutate: saveFormData, isLoading } = useSaveBackupJob();
  const { mutate: updateFormData } = useUpdateBackupJob();

  function handleClick() {
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId || "";
    const payloadData = {
      tenantBackupDestinationId: tenantBackupDestinationId?.id,
      tenantBackupSourceId: tenantBackupSourceId?.id,
      status: true,
      createdBy: userId,
      slaId: tenantBackupSlaId?.id
    }
    if(alreadySavedFormData) {
      updateFormData({backupJobId:alreadySavedFormData ? alreadySavedFormData.id : "" as unknown as any, formData:payloadData as unknown as any}, {
        onSuccess: (data: any) => {
          updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_BK_JOB"})
          queryClient.setQueryData(['tenantBackupJobId'], data.id);
          queryClient.setQueryData(['tenantBackupJobIdData'], data)
          nextStep();
          onSuccess(); // Call the onSuccess callback pro
          queryClient.setQueryData(['currentTab'], 4);
          setApiError("")
        },
        onError: (error: any) => {
          onError(error); // Call the onError callback prop
          setApiError(error.data.detail[0].msg.toString())        
        }
      });
    } else {
    saveFormData(payloadData as unknown as BackupJobApi, {
      onSuccess: (data) => {
        queryClient.setQueryData(['tenantOnboardingStatus'], "ADDED_BK_JOB");
        updateTenantOnboardingStatus({"tenantName": tenantId, "status": true, "onboardingStatus": "ADDED_BK_JOB"})
        queryClient.setQueryData(['tenantBackupJobIdData'], data);
        // setSelectedStep((prevStep) => prevStep + 1);
        // setActiveComponent(() => <MonitoringJob  onSuccess={handleSuccess} onError={handleError}/>);
        onSuccess(); // Call the onSuccess callback prop
        setApiError("")
        queryClient.setQueryData(['tenantBackupSourceId'], "");
        queryClient.setQueryData(['tenantBackupDestinationId'], "");
        queryClient.setQueryData(['tenantBackupSlaId'], "");
        // queryClient.setQueryData(['tenantBackupSourceIdData'], "");
        queryClient.setQueryData(['tenantBackupDestinationIdData'], "");
        queryClient.setQueryData(['tenantBackupSlaIdData'], "");
        queryClient.setQueryData(['currentTab'], 4);
        nextStep();
      },
      onError: (error:any) => {
        onError(error); // Call the onError callback prop
        setApiError(error.data.detail[0].msg.toString())      
      }
    });
  }
  }

  const disable:boolean = tenantBackupSourceId && tenantBackupDestinationId && tenantBackupSlaId ? false : true;

  function callComponent(component: React.ReactNode) {
    setActiveComponent(() => component);
    let stepIndex = 0;
    if (component && typeof component === 'object' && 'type' in component) {
      if (component.type === DataSource) {
        stepIndex = 1;
        queryClient.setQueryData(['updateFormSource'], true);
      } else if (component.type === DataDestination) {
        stepIndex = 2;
      } else if (component.type === SlaPage) {
        stepIndex = 3;
        queryClient.setQueryData(['updateFormSla'], true);
      } else if (component.type === CreateBackupJob) {
        stepIndex = 4;
      }
      setSelectedStep(stepIndex);
      queryClient.setQueryData(['currentTab'], stepIndex);
    }
  }

  const handleSuccess = () => {
    console.log("Success callback triggered.");
  };

  const handleError = (error: any) => {
    console.error("Error callback triggered:", error);
  };

  const renderFormComponent = () => {
    switch (selectedStep) {
      case 0:
        return <DataSource nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>;
      case 1:
        return <DataDestination nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>;
      case 2:
        return <SlaPage nextStep={nextStep} onSuccess={handleSuccess} onError={handleError}/>;
      default:
        return null;
    }
  };

  return (
    // <div>
    //   {activeComponent ? (
    //     selectedStep ? (
    //       renderFormComponent()
    //     ) : (
    //       activeComponent
    //     )
    //   ) : (
        <div>
            <div>
              <Card
                hasBorder={true}
                style={{
                  position: 'relative',
                  height: 'relative',
                  width: '616px',
                  margin: '12px 12px 34px 12px',
                  padding: '22px',
                }}
              >
                <TitleCreateJob>
                  Data Source
                  <IconButton
                    css={{marginBottom: '5px'}}
                    Icon={EditIcon}
                    onClick={() => {
                      callComponent(
                        <DataSource nextStep={nextStep} isUpdate={true} onSuccess={handleSuccess} onError={handleError} />,
                      );
                    }}
                  />
                </TitleCreateJob>
                { tenantBackupSourceId ? (<></>) : (<ErrorCreateJob><ValidationError errorMessageTID="Please create Data Source" ></ValidationError></ErrorCreateJob>)}
                {/* {dataDetails[indexData].inputData.map((itemData, index) => ( */}
                <CreateBackupJobRows>
                  <BackupDataItemsDataSource>
                    <BackupJobText>Application</BackupJobText>
                    <BackupJobTextValueDataSource>
                      {tenantBackupSourceId ? tenantBackupSourceId?.name : ""}
                    </BackupJobTextValueDataSource>
                  </BackupDataItemsDataSource>
                  <BackupDataItemsDataSource>
                    <BackupJobText>Environment</BackupJobText>
                    <BackupJobTextValueDataSource>
                      {tenantBackupSourceId ? tenantBackupSourceId?.environment : ""}
                    </BackupJobTextValueDataSource>
                  </BackupDataItemsDataSource>
                  <BackupDataItemsDataSource>
                    <BackupJobText>Application URL</BackupJobText>
                    <BackupJobTextValueDataSource>
                      {tenantBackupSourceId?.accessDetails ? tenantBackupSourceId?.accessDetails.odbcDetails.ODBCURL : ""}
                    </BackupJobTextValueDataSource>
                  </BackupDataItemsDataSource>
                </CreateBackupJobRows>
              </Card>
              <Card
                hasBorder={true}
                style={{
                  position: 'relative',
                  height: 'relative',
                  width: '616px',
                  margin: '12px 12px 34px 12px',
                  padding: '22px',
                }}
              >
                <TitleCreateJob>
                  Data Destination
                  {/* <IconButton
                    css={{marginBottom: '10px'}}
                    Icon={EditIcon}
                    onClick={() => {
                      callComponent(
                        <DataDestination nextStep={nextStep} isUpdate={true}  onSuccess={handleSuccess} onError={handleError}/>,
                      );
                    }}
                  /> */}
                </TitleCreateJob>
                {/* {dataDetails[indexData].inputData.map((itemData, index) => ( */}
                { tenantBackupDestinationId ? <div></div> : (<ErrorCreateJob><ValidationError errorMessageTID="Please create Data Destination" ></ValidationError></ErrorCreateJob>)}
                <CreateBackupJobRows>
                  <BackupDataItems>
                    <BackupJobText>Cloud</BackupJobText>
                    <BackupJobTextValue>
                      {tenantBackupDestinationId?.accessDetails ? tenantBackupDestinationId?.accessDetails.cloudProvider : ""}
                    </BackupJobTextValue>
                  </BackupDataItems>
                  <BackupDataItems>
                    <BackupJobText>Storage</BackupJobText>
                    <BackupJobTextValue>
                      {tenantBackupDestinationId?.accessDetails ? tenantBackupDestinationId?.accessDetails.storageService : ""}
                    </BackupJobTextValue>
                  </BackupDataItems>
                  <BackupDataItems>
                    <BackupJobText>Storage Class</BackupJobText>
                    <BackupJobTextValue>
                      {tenantBackupDestinationId?.accessDetails ? tenantBackupDestinationId?.accessDetails.storageTier : ""}
                    </BackupJobTextValue>
                  </BackupDataItems>
                  <BackupDataItems>
                    <BackupJobText>Region</BackupJobText>
                    <BackupJobTextValue>
                      {tenantBackupDestinationId?.accessDetails ? tenantBackupDestinationId?.accessDetails.region: ""}
                    </BackupJobTextValue>
                  </BackupDataItems>
                </CreateBackupJobRows>
              </Card>
              <Card
                hasBorder={true}
                style={{
                  position: 'relative',
                  height: 'relative',
                  width: '616px',
                  margin: '12px 12px 34px 12px',
                  padding: '22px',
                }}
              >
                <TitleCreateJob>
                  SLA Domain
                  <IconButton
                    css={{marginBottom: '5px'}}
                    Icon={EditIcon}
                    onClick={() => {
                      callComponent(
                        <SlaPage nextStep={nextStep} isUpdate={true} onSuccess={handleSuccess} onError={handleError} />,
                      );
                    }}
                  />
                </TitleCreateJob>
                {/* {dataDetails[indexData].inputData.map((itemData, index) => ( */}
                { tenantBackupSlaId ? <div></div> : (<ErrorCreateJob><ValidationError errorMessageTID="Please create SLA Domain" ></ValidationError></ErrorCreateJob>)}
                <div>
                  <BackupDataItems>
                    <BackupJobText>Daily Backup</BackupJobText>
                    <BackupJobTextValue>
                      Full backup every {tenantBackupSlaId?.dailyFrequency} Day(s) and will be retained for {tenantBackupSlaId?.dailyRetention} {dropdownPapping(tenantBackupSlaId?.dailyRetentionUnit as unknown as string)}
                    </BackupJobTextValue>
                    <BackupDataMarginRow />
                  </BackupDataItems>
                </div>
                <div>
                  <BackupDataItems>
                    <BackupJobText>Weekly Backup</BackupJobText>
                    <BackupJobTextValue>
                      Full backup every {tenantBackupSlaId?.weeklyFrequency} Week(s) on {dropdownPapping(tenantBackupSlaId?.weeklyFullBackupDay as unknown as string)} and will be retained for {' '}
                      {tenantBackupSlaId?.weeklyRetention} {dropdownPapping(tenantBackupSlaId?.weeklyRetentionUnit as unknown as string)}
                    </BackupJobTextValue>
                    <BackupDataMarginRow />
                  </BackupDataItems>
                </div>
                <div>
                  <BackupDataItems>
                    <BackupJobText>Monthly Backup</BackupJobText>
                    <BackupJobTextValue>
                      Full backup every {tenantBackupSlaId?.monthlyFrequency} Month(s) on {dropdownPapping(tenantBackupSlaId?.monthlyFullBackupDay  as unknown as string)} and will be retained for {' '}
                      {tenantBackupSlaId?.monthlyRetention} {dropdownPapping(tenantBackupSlaId?.monthlyRetentionUnit as unknown as string)} 
                    </BackupJobTextValue>
                    <BackupDataMarginRow />
                  </BackupDataItems>
                </div>
                <div>
                  <BackupDataItems>
                    <BackupJobText>Incremental Backup</BackupJobText>
                    <BackupJobTextValue>
                    Incremental backup every {tenantBackupSlaId?.incrementalBackupFrequency} {dropdownPapping(tenantBackupSlaId?.incrementalBackupFrequencyUnit as unknown as string)} and will be retained for {tenantBackupSlaId?.incrementalBackupRetention} {dropdownPapping(tenantBackupSlaId?.incrementalBackupRetentionUnit  as unknown as string)}
                    </BackupJobTextValue>
                    <BackupDataMarginRow />
                  </BackupDataItems>
                </div>
                <div>
                  <BackupDataItems>
                    <BackupJobText>Delta Backup</BackupJobText>
                    <BackupJobTextValue>
                      Delta backup every {tenantBackupSlaId?.deltaBackupFrequency} {dropdownPapping(tenantBackupSlaId?.deltaBackupFrequencyUnit as unknown as string)} and will be retained for {tenantBackupSlaId?.deltaBackupRetention} {dropdownPapping(tenantBackupSlaId?.deltaBackupRetentionUnit  as unknown as string)}
                    </BackupJobTextValue>
                    <BackupDataMarginRow />
                  </BackupDataItems>
                </div>
              </Card>
            </div>
            { apiError ? (<ValidationError errorMessageTID={apiError} ></ValidationError>) : (<></>)}
            <ButtonSource>
              <Button
                style={{marginLeft: '108px'}}
                variant="primary"
                size="large"
                disabled={ disable }
                onClick={handleClick}
              >
                {alreadySavedFormData ? 'Update Backup Job' : 'Create Backup Job'}
              </Button>
            </ButtonSource>
        </div>
  //     )}
  //   </div>
  );
};

export default CreateBackupJob;