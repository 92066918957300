import {ThemeProvider} from '@mui/material/styles';
import {baseMuiTheme} from '@rubrik/aura-ui';

/**
 * Support for usage of Material theming.
 * @param children React child nodes.
 */
export const MaterialThemeProvider: React.FC<{
  readonly children?: React.ReactNode;
}> = ({children}) => (
  <ThemeProvider theme={baseMuiTheme}>{children}</ThemeProvider>
);
