// CustomDropdown.tsx

import * as React from 'react';
import { OptionType } from 'src/model/BackupJobModel';
import {
  Arrow,
  Dropdown,
  DropdownContainer,
  OptionsList,
  Placeholder,
  SelectedOption,
  StyledOption,
  ErrorMessage,
  ClearIcon,
  ParentContainer,
} from '../style/emotionStyle';

interface CustomDropdownProps {
  options: Array<OptionType>;
  width?: string;
  height?: string;
  labels?: string;
  value?: OptionType;
  onChange?: (selectedOption: OptionType | null) => void; // Allow null to represent clearing the value
  error?: string;
  disabled?: boolean; // Add a disabled prop
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  width,
  height,
  labels,
  value,
  onChange,
  error,
  disabled, // Destructure disabled prop
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: OptionType) => {
    if (!disabled && onChange) {
      onChange(option);
    }
    setIsOpen(false);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!disabled && onChange) {
      onChange(null);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} width={width}>
      <SelectedOption onClick={toggleDropdown} width={width} height={height}>
        <Dropdown>
          <Placeholder>
            {value ? value.label : labels || 'Select an option'}
          </Placeholder>
          {/* {value && <ClearIcon onClick={handleClear}>&times;</ClearIcon>} */}
          <Arrow />
        </Dropdown>
      </SelectedOption>
      {isOpen && !disabled && (
        <OptionsList width={width}>
          {options.map((option) => (
            <StyledOption
              width={width}
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </StyledOption>
          ))}
        </OptionsList>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </DropdownContainer>
  );
};

export default CustomDropdown;