import {css, Global} from '@emotion/react';
import {EMOTION_THEME_DARK} from '@rubrik/aura-ui';
import * as React from 'react';

/**
 * Provides global base styles.
 */
export const StyleBase: React.FC = () => {
  return (
    <Global
      styles={css`
        body {
          background: ${EMOTION_THEME_DARK.background};
        }
      `}
    />
  );
};
