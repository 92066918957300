import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import {BackupJobApi, UserModel} from '../../model/BackupJobModel';

export const useSaveBackupJob = () => {
  const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;
  
  return useMutation(
    (formData: BackupJobApi) => ApiServiceInstance.createBackupJob({ tenantId: tenantId!, data: formData }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['formData']);
        queryClient.setQueryData(['tenantBackupJobId'], data.id);
        queryClient.setQueryData(['tenantBackupJobIdData'], data);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );};

  export const useUpdateBackupJob= () => { // Renamed to useUpdateTenantData
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;

    return useMutation(
      ({ backupJobId, formData }: { backupJobId: string, formData: BackupJobApi }) =>
        ApiServiceInstance.updateBackupJob({
          tenantId: tenantId!,
          backupJobId: backupJobId,
          data: formData,
        }),
      {
        onSuccess: (data) => {
          queryClient.setQueryData(['tenantBackupJobIdData'], data);
        },
        onError: (error) => {
          console.error('Error saving form data tenant', error);
        },
      }
    );
  };

export const useFetchBackupJob = (backupJobId: string) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['backupData', backupJobId],
    () => ApiServiceInstance.getBackupJob({ tenantId: tenantId!, backupJobId }),
    {
      enabled: !!tenantId && !!backupJobId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};

export const useFetchAllBackupJob = () => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
    const tenantId = userData?.tenantId;
  
    return useQuery(
      ['backupData', {tenantId}],
      () => ApiServiceInstance.getAllBackupJob({ tenantId: tenantId! }),
      {
        enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
        onError: (error) => {
          console.error('Error fetching form data source', error);
        },
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
      }
    );
  };

export const useClearFormDataSource = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      // Clear operation (if needed on the server-side)
      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(['backupData'], null);
      },
      onError: (error) => {
        console.error('Error clearing form data source', error);
      },
    }
  );
};