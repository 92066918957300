import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ApiServiceInstance from 'src/service/service';
import { SLA, UserModel } from '../../model/BackupJobModel';

export const useSaveFormDataSla = () => {
  const queryClient = useQueryClient();
  
  // Fetch user data within the hook
    const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useMutation(
    (formDataSla: SLA) => ApiServiceInstance.createSLA({ tenantId: tenantId!, data: formDataSla }),
    {
      onSuccess: (data) => {
        // Invalidate queries and set new state
        queryClient.invalidateQueries(['formDataSla']);
        queryClient.setQueryData(['tenantBackupSlaId'], data);
        queryClient.setQueryData(['tenantBackupSlaIdData'], data.id);
      },
      onError: (error) => {
        console.error('Error saving form data source', error);
      },
    }
  );
};

export const useUpdateDataSla = () => { // Renamed to useUpdateTenantData
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useMutation(
    ({ slaId, formData }: { slaId: string, formData: SLA }) =>
      ApiServiceInstance.updateSLA({
        tenantId: tenantId!,
        slaId: slaId,
        data: formData,
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['tenantBackupSourceId'], data);
      },
      onError: (error) => {
        console.error('Error saving form data tenant', error);
      },
    }
  );
};

export const useFetchFormDataSla = (tenantBackupSLAId: string) => {
  // Fetch user data within the hook
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['formDataSla', tenantBackupSLAId],
    () => ApiServiceInstance.getSLA({ tenantId: tenantId!, tenantBackupSLAId }),
    {
      enabled: !!tenantId && !!tenantBackupSLAId,
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
    }
  );
};

export const useFetchAllBackupSla = () => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<UserModel>(['formDataUser']);
  const tenantId = userData?.tenantId;

  return useQuery(
    ['BackupSlaAll', {tenantId}],
    () => ApiServiceInstance.getAllSLA({ tenantId: tenantId! }),
    {
      enabled: !!tenantId, // Only fetch if both tenantId and tenantBackupSourceId are provided
      onError: (error) => {
        console.error('Error fetching form data source', error);
      },
      staleTime: 1500, // 30 sec
      cacheTime: 1500, // 30 sec
    }
  );
};

export const useClearFormDataSla = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      // Clear operation (if needed on the server-side)
      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(['formDataSla'], null);
      },
      onError: (error) => {
        console.error('Error clearing form data source', error);
      },
    }
  );
};